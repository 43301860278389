import Subscription from "./components/Subscription";
import { userContext } from './contexts/userContext';
import { useContext } from 'react';
import React, {useState, useEffect, useRef } from 'react';
import serverURL from './config/serverConfig';
import Spinner from "./components/Spinner";

export default function Settings (){

    const user = useContext(userContext);
    const userName = user[0];
    const userID = user[1]
    const [timezone, setTimezone] = useState();

    const [daylightButton, setDaylightButton] = useState();

    const [loadedSettings, setLoadedSettings] = useState();
    const [tagList, setTagList] = useState();
    const [loadedTags, setLoadedTags] = useState(false);
    const [editing, setEditing] = useState(false)
    const [resetting, setResetting] = useState(false)


    useEffect(()=>{
        // fetch("https://sniper-server.onrender.com/json/trades.json")
        //fetch("http://localhost:8000/json/trades.json")
        fetch(serverURL + "users/" + userID + "/settings.json")
          .then(response => response.json())
          .then((jsonData) => {
            // jsonData is parsed json object received from url
            //trades = jsonData;
            setLoadedSettings(jsonData)
            if (jsonData.daylightSavings==undefined){
            jsonData.daylightSavings=false
            }
            setDaylightButton(jsonData.daylightSavings)
            // console.log(trades.length + " fetched")
          })
          .catch((error) => {
            // handle your errors here
            setLoadedSettings({
                "timezone": -5,
                "daylightSavings": false,
            })
            setDaylightButton(false)
            console.error("No settings file found - " + error)
          })
    },[])

    useEffect(()=>{
        //// fetch trade tags
  
          fetch(serverURL + "users/" + userID + "/tagList.json")
          .then(response => response.json())
          .then((jsonData) => {
            //console.log(jsonData)
            setTagList(jsonData);
            setLoadedTags(true)
          })
          .catch((error) => {
            console.error("No custom tag list found - " + error)
            setTagList(require('./config/tagList.json'))
            setLoadedTags(true)
          })
        },[])
          

    let currentTimezone = ""
    let cacheSettings = {}
    let currentDaylight  = ""
    if (loadedSettings==undefined){
        //set initial setting values
        // cacheSettings.timezone = -5
        // cacheSettings = {
        //     "timezone": -5
        // }
        // currentTimezone = getTimezoneStr(cacheSettings.timezone);

    } else {
        cacheSettings = { ...loadedSettings };
        currentTimezone = getTimezoneStr(loadedSettings.timezone);
        currentDaylight = loadedSettings.daylightSavings;
    }


    const changeTime = (e) => {
        cacheSettings.timezone = Number(e.target.value)
        //console.log(typeof(cacheSettings.timezone))
    }

    const daylightToggle = () => {
        //setDaylightButton(!cacheSettings.daylightSavings)
        cacheSettings.daylightSavings = (cacheSettings.daylightSavings == true)? false : true;
        console.log(cacheSettings.daylightSavings)
        // cacheSettings.daylightSavings = !cacheSettings.daylightSavings
        //setDaylightButton(!daylightButton)
        // console.log(daylightButton)

    }

    //console.log(loadedSettings)

    const update = () => {
        console.log(cacheSettings)
        setLoadedSettings(cacheSettings)
        saveSettings();
    }

    const saveSettings = async (e) => {
        // e.preventDefault();
        setEditing(false);
        console.log(cacheSettings)
        currentTimezone = getTimezoneStr(cacheSettings.timezone);
        fetch(serverURL+"settings", {
            // mode: 'cors',
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                },
            body: JSON.stringify({
                content: cacheSettings,
                name: userID
            }),
            //body: jsonTradeList,
            //body: "",
        })
        .then((response) => response.json())
        .then((data) => {
            //console.log(data);
            //trades = jsonTradeList;
        });
    }

    //console.log(cacheSettings)
    //console.log(loadedTags)

    const resetAccount = (async) => {

        setResetting(false)

        fetch(serverURL+"positionData", {
            // mode: 'cors',
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                },
            body: JSON.stringify({
                name: userID,
                content: []
            }),
            //body: allDays,
        })
        .then((response) => response.json())
        // .then((response) => response)
        .then((data) => {
            //console.log(data);
        });


        fetch(serverURL+"allData", {
            // mode: 'cors',
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                },
            body: JSON.stringify({
                name: userID,
                content: []
            }),
            //body: allDays,
        })
        .then((response) => response.json())
        // .then((response) => response)
        .then((data) => {
            //console.log(data);
        });

        fetch(serverURL+"tradeData", {
            // mode: 'cors',
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                },
            body: JSON.stringify({
                content: [],
                name: userID
            }),
            //body: jsonTradeList,
            //body: "",
        })
        .then((response) => response.json())
        // .then((response) => response)
        .then((data) => {
            //console.log(data);
            //trades = jsonTradeList;
        });

        fetch(serverURL+"tradeTags", {
            // mode: 'cors',
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                },
            body: JSON.stringify({
                content: [],
                name: userID
            }),
            //body: jsonTradeList,
            //body: "",
        })
        .then((response) => response.json())
        // .then((response) => response)
        .then((data) => {
            //console.log(data);
            //trades = jsonTradeList;
        });

        fetch(serverURL+"journalSingle", {
            // mode: 'cors',
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                },
            body: JSON.stringify({
                content: [],
                name: userID
            }),
            //body: jsonTradeList,
            //body: "",
        })
        .then((response) => response.json())
        // .then((response) => response)
        .then((data) => {
            //console.log(data);
            //trades = jsonTradeList;
        });

        fetch(serverURL+"journalDaily", {
            // mode: 'cors',
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                },
            body: JSON.stringify({
                content: [],
                name: userID
            }),
            //body: jsonTradeList,
            //body: "",
        })
        .then((response) => response.json())
        // .then((response) => response)
        .then((data) => {
            //console.log(data);
            //trades = jsonTradeList;
        });
    }


   return (
        <div id="settingsPage">
            <h3 id="settingsUserName">{userName}</h3>
            <h5>User Settings</h5>
            {
                (loadedSettings==undefined)?
                    <Spinner />
                    :
                    (
                        (editing==false) ?
                            <ol id="settingsList">
                                <li><span>Charting Timezone: </span>{getTimezoneStr(loadedSettings.timezone)}</li>
                                <li><span>Daylight Savings: </span>{loadedSettings.daylightSavings==true? "On": "Off"}</li>
                                <li><button onClick={()=>{setEditing(true)}} className="btn btn-sm btn-primary">Edit</button></li>
                            </ol>
                        :
                            <ol id="settingsForm">
                                <li id="timezoneSelect"><span>Charting Timezone: </span>
                                    <select name="timezone" onChange={changeTime} defaultValue={cacheSettings.timezone}>
                                        <option value={-11}>{getTimezoneStr(-11)}</option>
                                        <option value={-10}>{getTimezoneStr(-10)}</option>
                                        <option value={-9}>{getTimezoneStr(-9)}</option>
                                        <option value={-8}>{getTimezoneStr(-8)}</option>
                                        <option value={-7}>{getTimezoneStr(-7)}</option>
                                        <option value={-6}>{getTimezoneStr(-6)}</option>
                                        <option value={-5}>{getTimezoneStr(-5)}</option>
                                        <option value={-4}>{getTimezoneStr(-4)}</option>
                                        <option value={-3}>{getTimezoneStr(-3)}</option>
                                        <option value={-2}>{getTimezoneStr(-2)}</option>
                                        <option value={-1}>{getTimezoneStr(-1)}</option>
                                        <option value={0}>{getTimezoneStr(0)}</option>
                                        <option value={1}>{getTimezoneStr(1)}</option>
                                        <option value={2}>{getTimezoneStr(2)}</option>
                                        <option value={3}>{getTimezoneStr(3)}</option>
                                        <option value={4}>{getTimezoneStr(4)}</option>
                                        <option value={5}>{getTimezoneStr(5)}</option>
                                        <option value={6}>{getTimezoneStr(6)}</option>
                                        <option value={7}>{getTimezoneStr(7)}</option>
                                        <option value={8}>{getTimezoneStr(8)}</option>
                                        <option value={9}>{getTimezoneStr(9)}</option>
                                        <option value={10}>{getTimezoneStr(10)}</option>
                                        <option value={11}>{getTimezoneStr(11)}</option>
                                        <option value={12}>{getTimezoneStr(12)}</option>
                                        <option value={13}>{getTimezoneStr(13)}</option>
                                    </select>
                                </li>
                                <li className="form-check form-switch" id="daylightToggle">
                                    {/* <span>Daylight Savings: </span> &nbsp;{cacheSettings.daylightSavings==true? "On": "Off"} &nbsp; */}
                                    <span>Daylight Savings: &nbsp;</span>
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={daylightButton} onChange={daylightToggle}/>
                                </li>
                                <li>
                                    <button onClick={()=>{setEditing(false)}} className="btn btn-sm btn-dark">Cancel</button>
                                    <button onClick={update} className="btn btn-sm btn-primary">Save Changes</button>
                                </li>
                            </ol>
                    )
            }
            <h5>Subscription</h5>
            <Subscription user={user}/>
            {
                (loadedTags==false)?
                    <Spinner />
                :
                    <TagList tagList={tagList} userID={userID}/>
            }
            <div id="dataReset">
                <h5>Reset Trade Data</h5>
                {
                    (resetting==false) ?
                        <button className="btn btn-sm btn-danger settingsButton" onClick={()=>{setResetting(true)}}>RESET</button>
                    :
                        <>
                            <p>WARNING! This will erase all your trade data, tags, and notes. This action cannot be undone.</p>
                            <p>Click "Cancel" to go back or "RESET" to proceed with resetting your data.</p>
                            <button className="btn btn-sm btn-primary settingsButton" onClick={()=>{setResetting(false)}}>Cancel</button>
                            <button className="btn btn-sm btn-danger settingsButton" onClick={resetAccount}>RESET</button>
                        </>
                }
            </div>
        </div>
   )
}

function getTimezoneStr (zone) {
    switch(zone){
        case -11:
            return "(GMT-11:00) Midway Island, Samoa"
            break;
        case -10:
            return "(GMT-10:00) Hawaii"
            break;
        case -9:
            return "(GMT-09:00) Alaska"
            break;
        case -8:
            return "(GMT-08:00) Pacific Time (US and Canada)"
            break;
        case -7:
            return "(GMT-07:00) Mountain Time (US and Canada)"
            break;
        case -6:
            return "(GMT-06:00) Central Time (US and Canada)"
            break;
        case -5:
            return "(GMT-05:00) Eastern Time (US and Canada)"
            break;
        case -4:
            return "(GMT-04:00) Atlantic Time (Canada)"
            break;
        case -3:
            return "(GMT-03:00) Buenos Aires, Georgetown"
            break;
        case -2:
            return "(GMT-02:00) Mid-Atlantic"
            break;
        case -1:
            return "(GMT-01:00) Cape Verde Islands"
            break;
        case 0:
            return "(GMT) Casablanca, Dublin, Edinburgh, Lisbon, London"
            break;
        case 1:
            return "(GMT+01:00) Amsterdam, Berlin, Rome, Stockholm, Vienna"
            break;
        case 2:
            return "(GMT+02:00) Athens, Bucharest, Istanbul, Cairo"
            break;
        case 3:
            return "(GMT+03:00) Moscow, St. Petersburg, Volgograd"
            break;
        case 4:
            return "(GMT+04:00) Abu Dhabi, Muscat"
            break;
        case 5:
            return "(GMT+05:00) Islamabad, Karachi, Tashkent"
            break;
        case 6:
            return "(GMT+06:00) Astana, Dhaka"
            break;
        case 7:
            return "(GMT+07:00) Bangkok, Hanoi, Jakarta"
            break;
        case 8:
            return "(GMT+08:00) Perth, Beijing, Kuala Lumpur, Singapore"
            break;
        case 9:
            return "(GMT+09:00) Osaka, Seoul, Sapporo, Tokyo"
            break;
        case 10:
            return "(GMT+10:00) Brisbane, Guam, Sydney, Melbourne"
            break;
        case 11:
            return "(GMT+11:00) Magadan, Solomon Islands, New Caledonia"
            break;
        case 12:
            return "(GMT+12:00) Auckland, Fiji Islands, Marshall Islands"
            break;
        case 13:
            return "(GMT+13:00) Nuku'alofa"
            break;
        default:
            return "Eastern"
    }
    }

function TagList (props) {
    //let tagList = props.tagList
    const [tagList, setTagList] = useState(props.tagList);
    const [editing, setEditing] = useState(false)
    const userID = props.userID
    let tagTable = tagList.map((data, i) => <li key={i}><span>&#9751;</span>{data}</li>)
    let tagForm =   tagList.map((data, i) => (
                    <li key={i}>
                        <input 
                            name="tag"
                            id="tagForm"
                            value={data}
                            onChange={(e)=>
                                {
                                    let tagListArray = [...tagList]
                                    tagListArray[i]=e.target.value
                                    //console.log(tagList[i])
                                    // setTagList({...tagList, [i]: e.target.value})
                                    setTagList(tagListArray)
                                }
                            } 
                            // value={tradeTags[ref]}
                        />
                            {/* <option value=""></option>
                            {tagFormOptions}
                        </input> */}
                    </li>)
    )

    

    const saveTags = ()=>{
        setEditing(false)
        console.log(tagList)

        fetch(serverURL+"tagList", {
            // mode: 'cors',
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                },
            body: JSON.stringify({
                content: tagList,
                name: userID
            }),
        })
        .then((response) => response.json())
        .then((data) => {
        });
    }

    return(
        <div id="tagSettings">
            <h5>Tag List</h5>
            <ul>
                {
                    (editing==false)?
                        tagTable
                    :
                        tagForm
                }
            </ul>
            <div id="tagSettingsButtons">
                {/* <button disabled={editing} onClick={()=>{setEditing(true)}}>&#x270E;</button>
                <button disabled={!editing} onClick={saveTags}>&#10003;</button> */}
                {
                    (editing==false)?
                    <button onClick={()=>{setEditing(true)}} className="btn btn-sm btn-primary">Edit</button>
                    :
                    <>
                        <button onClick={()=>{setEditing(false)}} className="btn btn-sm btn-dark">Cancel</button>
                        <button onClick={saveTags} className="btn btn-sm btn-primary">Save Changes</button>
                    </>
                }
            </div>
        </div>
    )
}