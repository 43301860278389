import { NavLink } from "react-router-dom";
import { useState } from "react";
import { TOS } from "./TOS";
import { About } from "./About";
import FooterLinks from "./FooterLinks";

export default function Footer (props) {

    const [tosVisibility, setTosVisibility] = useState("floating closed");
    const [aboutVisibility, setAboutVisibility] = useState("floating closed");
    

    const popupCloseHandler = (e) => {
      setTosVisibility("floating closed");
    };

    return (
        <footer className="">
        <div id="footerCoName">
          &copy;2023 Sniper Log
        </div>
        {/* <div id="footerLinks" className="col">
            <span>
                <p className="footerLink" onClick={()=>{
                    setAboutVisibility("floating")
                    setTosVisibility("floating closed")
                }}> About</p>
                <div className={aboutVisibility} ><span className="popClose" onClick={()=>{setAboutVisibility("floating closed")}}>x</span> {About} </div>
            </span>
            <span>
                <p className="footerLink" onClick={()=>{
                    setTosVisibility("floating")
                    setAboutVisibility("floating closed")
                }}> Terms of Service</p>
                <div className={tosVisibility} ><span className="popClose" onClick={popupCloseHandler}>x</span> {TOS} </div>
            </span>
        </div> */}
        <FooterLinks />
      </footer>
    )
}