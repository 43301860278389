/* Accepts filtering values in props and returns filtered trades from a single day */

import { useEffect } from 'react';
import { useState, useContext } from 'react';
import TradeEntry from './TradeEntry';
import { userContext } from '../contexts/userContext';
import serverURL from '../config/serverConfig';
import Spinner from './Spinner';
import { CSVLink } from 'react-csv';

export default function TradeList(props){
    //console.log(props.tags)

    const userData = useContext(userContext);
    const user = userData[1];
    const plan = userData[4];
    //console.log(user)

    const tradeList = [];
    
    const tagOptions = props.tagOptions
    const [tradeTags, setTradeTags] = useState(props.tags)
    const [editing, setEditing] = useState(false)
    const [saveable, setSaveable] = useState(false)

    const saveTags = () => {
        //console.log(tradeTags)
        setEditing(false)
        setSaveable(false)
        fetch(serverURL+"tradeTags", {
            // mode: 'cors',
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                },
            body: JSON.stringify({
                name: user,
                content: tradeTags
            }),
        })
        .then((response) => response.json())
        // .then((response) => response)
        .then((data) => {
            //console.log(data);
        });
    }

    let exportCSV = []
    for (let i = 0; i < props.trades.length; i++) {
        //let tag = "5";
        if (props.trades[i].DATE == props.date){
            let ref = props.trades[i].REF
            let tag = (tradeTags.ref == undefined) ? "" : tradeTags.ref;
            //console.log(tag)
            // let tagForm =   <form>
            //                     <input 
            //                         type="text"
            //                         onChange={(e)=>
            //                             {
            //                                 console.log(tradeTags[ref])
            //                                 setTradeTags({...tradeTags, [ref]: e.target.value})
            //                             }
            //                         } 
            //                         value={tradeTags[ref]}
            //                     />
            //                 </form>
            let tagFormOptions = []
            //console.log(tradeTags[ref])
            if (tagOptions!=undefined){
            tagOptions.forEach((element) => tagFormOptions.push(
                <option value={element} key={element} >{element} </option>
            ))
            }
            let tagForm =   <form>
                                <select 
                                    name="tag"
                                    id="tagForm"
                                    onChange={(e)=>
                                        {
                                            //console.log(tradeTags[ref])
                                            setTradeTags({...tradeTags, [ref]: e.target.value})
                                        }
                                    } 
                                    defaultValue={tradeTags[ref]}
                                >
                                    <option value=""></option>
                                    {tagFormOptions}
                                </select>
                            </form>

            // if (editing == true){
            //     tag = <form><textarea onChange={(e)=>{setTradeTags({...tradeTags, ref: e})}}>{tag}</textarea></form>
            // } else {
            //     tag = ""
            // }
            // for (let x=0; x<tradeTags; x++){
            //     if (tradeTags[i].ref==props.trades[i].REF){
            //         if (editing == true){
            //             tag = <div>{tag}</div>
            //         } else {
            //             tag = tradeTags[i].tag
            //         }
            //     }
            // }
            tradeList.push(
                <TradeEntry trade={props.trades[i]} tag={(editing==false)?tradeTags[ref]:tagForm} key={i} editing={editing}/>
            )
            

            if (props.trades[i].QTY < 0){
                var direction = "Long"
                var entry = props.trades[i].entry;
                var exit = props.trades[i].exit;
            } else {
                var direction = "Short"
                var entry = props.trades[i].exit;
                var exit = props.trades[i].entry;
            }
            let duration = msToTime(props.trades[i].duration);
            let fees = props.trades[i].totalFees
            let comms = props.trades[i].totalComms
            exportCSV.push(
                {
                    DATE: props.trades[i].DATE,
                    TIME: props.trades[i].TIME,
                    DIRECTION: direction,
                    SYMBOL: props.trades[i].SYMBOL,
                    SHARES: props.trades[i].totalSize,
                    ENTRY: entry,
                    EXIT: exit,
                    DURATION: duration,
                    PnL: props.trades[i].PnL,
                    Fees: fees,
                    Commissions: comms
                }
            )
        } else {}
    }

    return (
        <>
        <div id="tradesArea">
            <div className="" id="tradesListHeader">
                <section>TRADES - {props.date}</section>
                {/* <button className='btn btm-sm btn-primary' onClick={()=>{setEditing(!editing)}}>edit tags</button> */}
                {/* <p>Edit Tags</p> */}
                {/* <label className="switch"> 
                    <input 
                        type="checkbox" 
                        checked={editing} 
                        onChange={()=>{
                            setEditing(!editing)
                            setSaveable(true)
                       }}
                    />
                    <span className="slider round"></span>
                </label>
                <button className='btn btm-sm btn-primary' disabled={!saveable} onClick={saveTags}>Save Tags</button> */}
                <button disabled={editing||(user=="110194700664376100000")} onClick={()=>{setEditing(true)}}>&#x270E;</button>
                <button disabled={!editing} onClick={saveTags}>&#10003;</button>
            </div>
            {/* <div className="tradeEntryHeader row"> */}
            
            <div className="tradeEntryHeader tradeAreaRow">
                <div id="tradeTime2">TIME</div>
                <div id="tradeTime2">L\S</div>
                <div id="tradeSymbol2">SYMBOL</div>
                <div id="tradeShares">SHARES</div>
                <div id="tradeEE">
                    <div className="entryExit">
                        <div className="col-5">ENTRY</div>
                        <div className="col-2">&#8594;</div>
                        <div className="col-5">EXIT</div>
                    </div>
                </div>
                <div className="duration">DURATION</div>
                <div id="tradePnL2">PROFIT / LOSS</div>
                <div className='fncList'>Fees / Comms</div>
                <div id="tagHead">TAG</div>
            </div>
            <div id="tradesListInner">
            {tradeList}
            </div>
            {(plan=="professional") ?
                <div id="export">
                <CSVLink
                    filename={props.date+"-trades-sniperlog.csv"}
                    data={exportCSV}
                    className="btn btn-secondary btn-sm button"
                    target="_blank"
                >
                    <span>EXPORT</span><>⤓</>
                </CSVLink> 
                </div>
                :
                <></>
            }
        </div>
        </>
    );

}


function msToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    hrs = hrs.toString().padStart(2, '0')
    mins = mins.toString().padStart(2, '0')
    secs = secs.toString().padStart(2, '0')
  
    return hrs + ':' + mins + ':' + secs;
  }