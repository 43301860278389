import React from "react";
import { Bar } from "react-chartjs-2";

function BarChartDaily (props){

    const plugin ={
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart, args, options) => {
        const {ctx} = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = options.color || '#0d1d2b';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    }
    };

    return (
      <div className="chart-container">
        <Bar
          data={props.chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            parsing: true,
            stepped: true,
            elements: {
              point:{
                radius: 1.5,
              },
              line:{
                borderWidth: 0
              }
            },
          plugins: {
            title: {
              display: true,
            },
            legend: {
              display: true
            },
          },
          scales: {
            x:{
              backgroundColor: "#0d1d2b",
              ticks: {
                color: "#eeeeee"
              },
              grid: {
                color: "#17324a"
              },
            },
            y: {
                  backgroundColor: "#0d1d2b",
                  ticks: {
                          // Include a dollar sign in the ticks
                    callback: function(value, index, ticks) {
                        return '$' + value;
                    },
                    color: "#eeeeee",
                  },
                  grid: {
                    color: "#17324a"
                  }
                }

          },
          plugins: [plugin],
        }}

        />
      </div>
    )
}

export { BarChartDaily };