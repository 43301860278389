import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom'

import IntradayChart from './components/IntradayChart';
import IntradayStats from './components/IntradayStats';
import TradeList from './components/TradeList';
import { TotalsHeader, IntradayTotals, TotalDayPnL } from './components/IntradayTotals';
import { userContext } from './contexts/userContext';
import serverURL from './config/serverConfig';
import Spinner from './components/Spinner';
import Welcome from './components/Welcome';
import JournalEntry from './components/JournalEntry';

var trades = []
var allDays = []
var tradeTags = [];
var tagOptions = [];

function Intraday(props) {

  //console.log(serverURL)
  const location = useLocation()
  const userData = useContext(userContext);
  const user = userData[1];
  const plan = userData[4];

  const initDate = ""; 
  const [visDate, setVisDate] = useState(initDate); //initial date is last trade date if date input is blank
  const [noTrades, setNoTrades] = useState();
  const [loadingT, setLoadingT] = useState(true)
  const [loadingA, setLoadingA] = useState(true)
  const [tagsLoaded, setTagsLoaded] = useState(false)

  useEffect(()=>{
      //// fetch trade tags
      fetch(serverURL + "json/" + user + "/tradeTags.json")
        .then(response => response.json())
        .then((jsonData) => {
          //console.log(jsonData)
          tradeTags = jsonData;
        })
        .catch((error) => {
          console.error("No tag file found - " + error)
        })

        fetch(serverURL + "users/" + user + "/tagList.json")
        .then(response => response.json())
        .then((jsonData) => {
          //console.log(jsonData)
          tagOptions = jsonData;
        })
        .catch((error) => {
          console.error("No custom tag list found - " + error)
          tagOptions = require('./config/tagList.json')
        })

    fetch(serverURL + "json/" + user + "/trades.json")
      .then(response => response.json())
      .then((jsonData) => {
        //console.log(jsonData)
        trades = jsonData;

        //set max date
        let maxIndex = 0;
        let maxVal = 0;
        for (let i = 1; i < trades.length; i++){
          let dateVal = Date.parse(trades[i].DATE);
          if (dateVal > maxVal){
            maxVal =dateVal;
            maxIndex = i;
          }
        }
        const initDate = (location.state==null) ? trades[maxIndex].DATE : location.state.date;  //trades.length-1
        setVisDate(initDate)
        setLoadingT(false)
      })
      .catch((error) => {
        console.error(error)
        setNoTrades(true)
        setLoadingT(false)
      })

    fetch(serverURL + "json/" + user + "/allDays.json")
      .then(response => response.json())
      .then((jsonData) => {
        //console.log(jsonData)
        allDays = jsonData;
        setLoadingA(false)
      })
      .catch((error) => {
        console.error("No trade data found - " + error)
        setNoTrades(true)
        setLoadingA(false)
      })


    },[])

  // format date for display
  const dateInputRef = useRef(null);

  let visDateForm = "";
  if (visDate!=""){

    let visDateParse = visDate.split("/");
    if (visDateParse[0].length==1){
      visDateParse[0] = `0${visDateParse[0]}`
    }else {}
    if (visDateParse[1].length==1){
      visDateParse[1] = `0${visDateParse[1]}`
    }else{}
    visDateForm = `20${visDateParse[2]}-${visDateParse[0]}-${visDateParse[1]}`
    // console.log(visDateForm);
  }

  // Date form handling
  const handleChange = (e) => {
    let inputDate = e.target.value;
    let readDateParse = inputDate.split("-");
    let viewYear = `${readDateParse[0].charAt(2)}${readDateParse[0].charAt(3)}`
    if (readDateParse[1].charAt(0)=="0"){
      var viewMonth = `${readDateParse[1].charAt(1)}`;
    } else {
      var viewMonth = `${readDateParse[1].charAt(0)}${readDateParse[0].charAt(1)}`
    }
    if (readDateParse[2].charAt(0)=="0"){
      var viewDay = `${readDateParse[2].charAt(1)}`;
    } else {
      var viewDay = `${readDateParse[2].charAt(0)}${readDateParse[2].charAt(1)}`
    }
    setVisDate(`${viewMonth}/${viewDay}/${viewYear}`);
  };
  
  const clickBack = () => {
    let currDate = new Date(visDate);
    let newDate = new Date(currDate-1);
    let newDateStr = newDate.toLocaleDateString("en-Us",{month:"numeric",day:"numeric",year:"2-digit"});
    setVisDate(newDateStr);
  }
  
  const clickForward = () => {
    let currDate = new Date(visDate);
    let nextDate = currDate.setDate(currDate.getDate() + 1)
    let newDate = new Date(nextDate);
    // console.log(newDate)
    let newDateStr = newDate.toLocaleDateString("en-Us",{month:"numeric",day:"numeric",year:"2-digit"});
    setVisDate(newDateStr);
  }

  // if (noTrades == true){
  //   return (
  //     <Welcome name={userData[0]}/>
  //   )
  // } else {
  //   if ((trades.length==0) ||(allDays.length==0)){
  //     return <Spinner />
  //   }else {
    if ((loadingA == false)&&(loadingT==false)){
      if (noTrades == true){
        return <Welcome name={userData[0]}/>
      } else {
        return (
          <div>
            <div className="row pt-3" id="topPage">
              <div className="col-12 col-md-8 col-xxl-8" id="intradayLeftTop">
                <div className="row" >
                  <div className="col mb-3" id="dateSelect">
                    <button className="btn btn-primary btn-sm" onClick={clickBack}>&#8592;</button>
                    <input type="date" ref={dateInputRef} onChange={handleChange} value={visDateForm}/>
                    <button className="btn btn-primary btn-sm" onClick={clickForward}>&#8594;</button>
                  </div>
                </div>
                <div className="row" id="intradayChartContainer">
                  <div className="col-12 chartArea" id="intradayChart">
                    <IntradayChart date={visDate} trades={trades} />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xxl-4" id="intradayStats">
                <div className="row">
                  <div className="col-6 col-md-12" id="IntradayTotals">
                    <TotalsHeader date={visDate} trades={trades}/>
                    <IntradayTotals date={visDate} trades={trades}/>
                    <TotalDayPnL date={visDate} trades={trades}/>
                  </div>
                  <div className="col-6 col-md-12">
                    <IntradayStats date={visDate} allDays={allDays} trades={trades}/>
                  </div>
                </div>
              </div>
            </div>
            <div className='row pb-2' id="journalDiv">
                  <JournalEntry date={visDate} key={visDate}/>
                </div>
            <div className="row" id="bottomPage">
              <div className="col intradayList">
                <TradeList date={visDate} trades={trades} tags={tradeTags} tagOptions={tagOptions}/>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return <Spinner />
    }
  //   }
  // }
}

export default Intraday;
