import { CrosshairMode, createChart, ColorType } from "lightweight-charts";
import React, { useEffect, useRef } from 'react';
//import { restClient } from "@polygon.io/client-js";

var csData = {};
//const rest = restClient("L66LbQA96zxy9U4wfgnaioDqmebQkObd", "https://api.polygon.io");
var loaded = false;
//const reqURL = "https://api.polygon.io/v2/aggs/ticker/AAPL/range/1/minute/2023-05-24/2023-05-24?adjusted=true&sort=asc&limit=720&apiKey=L66LbQA96zxy9U4wfgnaioDqmebQkObd";

export default function CandleStickChart (props) {
    
    const {
		colors: {
			backgroundColor = '#111',
			lineColor = '#2962FF',
			textColor = '#DDD',
			areaTopColor = '#2962FF',
			areaBottomColor = 'rgba(255, 98, 255, 0.28)',
		} = {},
	} = props;

	const chartContainerRef = useRef();

	const currentLocale = window.navigator.languages[0];
	// Create a number format using Intl.NumberFormat
	const myPriceFormatter = Intl.NumberFormat(currentLocale, {
    	style: 'currency',
    	currency: 'USD', // Currency for data points
	}).format;

	useEffect(
		() => {

            //console.log("Length: ", props.candleData.length, "Type: ", typeof(props.candleData))
			//console.log(props.vwapData)
			const handleResize = () => {
				chart.applyOptions({ 
					width: chartContainerRef.current.clientWidth,
					
					localization: {
						priceFormatter: myPriceFormatter,
					},

				});
			};

			const chart = createChart(chartContainerRef.current, {
				layout: {
					background: { type: ColorType.Solid, color: backgroundColor },
					textColor,
				},
				grid: {
					vertLines: { color: '#222' },
					horzLines: { color: '#444', lineStyle: 2 },
				},
				width: chartContainerRef.current.clientWidth-20,
				height: 400,
                timeScale: {
                    timeVisible: true,
                secondsVisible: false,
                },
				priceScale: {
					borderColor: '#71649C',
				},
				crosshair: {
					// Change mode from default 'magnet' to 'normal'.
					// Allows the crosshair to move freely without snapping to datapoints
					mode: 0,
			
				// 	// Vertical crosshair line (showing Date in Label)
					vertLine: {
						// width: 1,
						color: '#C3BCDB44',
						labelBackgroundColor: '#9B7DFF',
					},
			
				// 	// Horizontal crosshair line (showing Price in Label)
					horzLine: {
						color: '#C3BCDB44',
						labelBackgroundColor: '#9B7DFF',
					},
				}, 
			});
			chart.timeScale().fitContent();
			chart.timeScale().applyOptions({
				borderColor: '#71649C',
			});
			
			//Candlesticks
            const candleSeries = chart.addCandlestickSeries({
				lastValueVisible: false,
    			priceLineVisible: false,
			});
            candleSeries.setData(props.candleData);
			candleSeries.priceScale().applyOptions({
				autoScale: true, // disables auto scaling based on visible content
				scaleMargins: {
					top: 0.1,
					bottom: 0.3,
				},
			});

			// Markers
            candleSeries.setMarkers(props.markers);


			// Trades
			const barSeries = chart.addBarSeries({ 
				upColor: '#26a69a', 
				downColor: '#ef5350',
				//priceScaleId: '', 
			});

			//VWAP
			const lineSeries = chart.addLineSeries({ 
				color: '#2962FF',
				lineStyle: 2,
				lineWidth: 1,
				lastValueVisible: false,
    			priceLineVisible: false,
			 });
			const vwapData = props.vwapData
			lineSeries.setData(vwapData);

			// Volume
			const volumeSeries = chart.addHistogramSeries({
				priceFormat: {
					type: 'volume',
				},
				priceScaleId: '', // set as an overlay by setting a blank priceScaleId
			});
			volumeSeries.priceScale().applyOptions({
				// set the positioning of the volume series
				scaleMargins: {
					top: 0.7, // highest point of the series will be 70% away from the top
					bottom: 0,
				},
			});
			volumeSeries.setData(props.volumeData);

			// candleSeries.attachPrimitive()
			
			///////

			window.addEventListener('resize', handleResize);

			return () => {
				window.removeEventListener('resize', handleResize);

				chart.remove();
			};
		},
		[csData, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]
	);


    return (
		<div id="candleStickChart">
			<p>{props.title}: 1 min / 1 Day</p>
			<div ref={chartContainerRef}>
			</div>
		</div>
    )
}
