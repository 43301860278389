import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useState, useRef, useEffect } from 'react';
import { CLIENT_ID } from '../config/paypalConfig';
import serverURL from '../config/serverConfig';
import React from 'react';

export function PayPalBtn(props) {

  const user = props.user;
  const newSub = props.newSub
  //const planID = props.planID;

  const [planID, setPlanID] = useState("")
  useEffect(()=>{
    //console.log(props.planID)
    setPlanID(props.planID);
  },[props.planID])

  const [orderID, setOrderID] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [subscription, setSubscription] = useState()

const options={
  clientId: CLIENT_ID,
  vault: true,
  intent: "subscription",
}

  const paypalSubscribe = (data, actions) => {
    console.log(planID)
    return actions.subscription.create({
    // plan_id: 'P-1VH35195B70443343MVNZWUI',
    plan_id: planID,
    }).then((orderID) => {
      setOrderID(orderID);
      return orderID;
    });
  };

  let basicSubscribe = (data, actions) => {
    console.log(planID)
    return actions.subscription.create({
    plan_id: 'P-1VH35195B70443343MVNZWUI',
    //plan_id: "P-5D182562JV1098238MVN2LCI",
    }).then((orderID) => {
      setOrderID(orderID);
      return orderID;
    });
  };

  let freeSubscribe = (data, actions) => {
    console.log(planID)
    return actions.subscription.create({
    // plan_id: 'P-1VH35195B70443343MVNZWUI',
    plan_id: "P-5D182562JV1098238MVN2LCI",
    }).then((orderID) => {
      setOrderID(orderID);
      return orderID;
    });
  };

  let basicRenewedSubscribe = (data, actions) => {
    console.log(planID)
    return actions.subscription.create({
    // plan_id: 'P-1VH35195B70443343MVNZWUI',
    plan_id: "P-282623381F7821901MVOPMFQ",
    }).then((orderID) => {
      setOrderID(orderID);
      return orderID;
    });
  };

  const paypalOnError = (err) => {
    console.log("Error")
  }

  const paypalOnApprove = (data, details) => {
    // call the backend api to store transaction details
    console.log("Payapl approved")
    console.log(data)

    fetch(serverURL+"subscribe", {
      // mode: 'cors',
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
          },
      body: JSON.stringify({
          name: user,
          content: data
      }),
      //body: allDays,
    })
    .then((response) => response.json())
    // .then((response) => response)
    .then((data) => {
        //console.log(data);
        // setUploaded(true)
        // setFileStats([tradeList.length, newTradeCount])
    });
    console.log(newSub)
    newSub(true)
  };

      const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details;
            setSuccess(true);
            console.log(newSub)
            newSub(true)
        });
    };

    const basicButton = (
      <PayPalScriptProvider  key="basic" options={options}>
        <PayPalButtons
          amount = "6.99"
          currency = "USD"
          //createSubscription={createOrder}
          createSubscription={basicSubscribe}
          onApprove={paypalOnApprove}
          catchError={paypalOnError}
          onError={paypalOnError}
          onCancel={paypalOnError}
          style={{
                shape: 'pill',
                color: 'gold',
                layout: 'vertical',
                label: 'subscribe',
            }}
          /> 
      </PayPalScriptProvider>
    )

    const freeButton = (
      <>
      <PayPalScriptProvider  key="free" options={options}>
        <PayPalButtons
          amount = "Free"
          currency = "USD"
          //createSubscription={createOrder}
          createSubscription={freeSubscribe}
          onApprove={paypalOnApprove}
          catchError={paypalOnError}
          onError={paypalOnError}
          onCancel={paypalOnError}
          style={{
                shape: 'pill',
                color: 'blue',
                layout: 'vertical',
                label: 'subscribe',
            }}
          />
      </PayPalScriptProvider>
      </>
    )

    const basicRenewedButton = (
      <>
      <PayPalScriptProvider key="renew" options={options}>
        <PayPalButtons
          amount = "6.99"
          currency = "USD"
          //createSubscription={createOrder}
          createSubscription={basicRenewedSubscribe}
          onApprove={paypalOnApprove}
          catchError={paypalOnError}
          onError={paypalOnError}
          onCancel={paypalOnError}
          style={{
                shape: 'pill',
                color: 'silver',
                layout: 'vertical',
                label: 'subscribe',
            }}
          />
      </PayPalScriptProvider>
      </>
    )

    let paypalButton = "";
            switch (planID){
              case "P-1VH35195B70443343MVNZWUI":
                paypalButton = basicButton;
                break;
            case "P-5D182562JV1098238MVN2LCI":
              paypalButton = freeButton;
              break;
            case "P-282623381F7821901MVOPMFQ":
              paypalButton = basicRenewedButton;
              break;
            default:
                break;
            }
    useEffect(()=>{
      paypalButton="";
      console.log(planID)
      switch (planID){
        case "P-1VH35195B70443343MVNZWUI":
          paypalButton = basicButton;
          break;
      case "P-5D182562JV1098238MVN2LCI":
        paypalButton = freeButton;
        break;
      case "P-282623381F7821901MVOPMFQ":
        paypalButton = basicRenewedButton;
        break;
      default:
          break;
      }
    },[props.planID])
            
    return (
      <>
      {paypalButton}
      </>
    );

            }




// <div id="paypal-button-container-P-1VH35195B70443343MVNZWUI"></div>
// <script src="https://www.paypal.com/sdk/js?client-id=Ady5P6yaIfTamsaPTf_qJKUpytLuSOFtX0PaKYBgSYHJRzbgUDtmwH4YknG2JETZ2Tc_50tQtRoDykLO&vault=true&intent=subscription" data-sdk-integration-source="button-factory"></script>
// <script>
//   paypal.Buttons({
//       style: {
//           shape: 'pill',
//           color: 'blue',
//           layout: 'vertical',
//           label: 'subscribe'
//       },
//       createSubscription: function(data, actions) {
//         return actions.subscription.create({
//           /* Creates the subscription */
//           plan_id: 'P-1VH35195B70443343MVNZWUI'
//         });
//       },
//       onApprove: function(data, actions) {
//         alert(data.subscriptionID); // You can add optional success message for the subscriber here
//       }
//   }).render('#paypal-button-container-P-1VH35195B70443343MVNZWUI'); // Renders the PayPal button
// </script>

export default PayPalBtn;




