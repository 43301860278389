//accepts daily trade data and all trades and returns lifetime stats

import { Link } from "react-router-dom";

export default function LifetimeStats (props) {
    var lifetimeSum = 0;
    var totalGain = 0;
    var greenDayCount = 0;
    var greenDayGain = 0;
    var redDayCount = 0;
    var redDayLoss = 0;
    var streak = 0;
    var greenStreak = false;
    var avgWinRate = 0;
    var avgDailyPnL = 0;
    var avgGreenDay = 0;
    var avgRedDay = 0;
    var greenDayRatio = 0;
    var avgDailyVol = 0;

    var loadedDays = props.loadedDays;
    var loadedTrades = props.loadedTrades

    for (let i = 0; i<loadedDays.length; i++){
        lifetimeSum += loadedDays[i].totalShares
        totalGain += loadedDays[i].PnL;
        loadedDays[i].totalGain = totalGain; //sets running PnL for filtered date range

        if (loadedDays[i].PnL>0){
            greenDayCount++;
            greenDayGain += loadedDays[i].PnL;
        } else {
            redDayCount++;
            redDayLoss += loadedDays[i].PnL;
        }

        if ((loadedDays[i].PnL>0)==greenStreak){
            streak++;
        } else {
            streak = 1;
            greenStreak = (loadedDays[i].PnL>0);
        }
    }

    avgDailyVol = lifetimeSum/loadedDays.length;
        avgDailyVol = Math.round((avgDailyVol + Number.EPSILON) * 1) / 1;

    // calculate average daily win rate
    var winRateSum = 0;
    for (let i = 0; i<loadedDays.length; i++){
        winRateSum += loadedDays[i].winRate
    }
    avgWinRate = winRateSum/loadedDays.length;
        avgWinRate = Math.round((avgWinRate + Number.EPSILON) * 100) / 100;

    // Calcaulte total P/L and average daily P/L
    avgDailyPnL = totalGain/loadedDays.length;
        avgDailyPnL = Math.round((avgDailyPnL + Number.EPSILON) * 100) / 100;
    totalGain = Math.round((totalGain + Number.EPSILON) * 100) / 100;

    // calculate average green day, red day, ratios
    avgGreenDay = greenDayGain/greenDayCount;
        avgGreenDay = Math.round((avgGreenDay + Number.EPSILON) * 100) / 100;
        avgGreenDay = (isNaN(avgGreenDay)) ? " -" : avgGreenDay;
    avgRedDay = redDayLoss/redDayCount;
        avgRedDay = Math.round((avgRedDay + Number.EPSILON) * 100) / 100;
        avgRedDay = (isNaN(avgRedDay)) ? " -" : avgRedDay;
    greenDayRatio = greenDayCount/(greenDayCount+redDayCount);
        greenDayRatio = Math.round((greenDayRatio + Number.EPSILON) * 100);


    let perShareSum = 0;
    let winDuration = 0;
    let lossDuration = 0;
    let winCount = 0;
    let lossCount = 0;
    let largestWinner = {"PnL": 0};
    let largestLoser = {"PnL": 0};
    let winTotal = 0;
    let lossTotal = 0;
    let totalFnC = 0;

    for (let i=0; i<loadedTrades.length; i++){
        perShareSum += (loadedTrades[i].exit - loadedTrades[i].entry)
        totalFnC += (loadedTrades[i].FEE + loadedTrades[i].COMM)

        if (loadedTrades[i].PnL>0){
            winCount++;
            largestWinner = (loadedTrades[i].PnL >= largestWinner.PnL  ? loadedTrades[i] : largestWinner);
            winDuration += loadedTrades[i].duration;
            winTotal += loadedTrades[i].PnL;
        } else {
            lossCount++;
            largestLoser = (loadedTrades[i].PnL <= largestLoser.PnL  ? loadedTrades[i] : largestLoser)
            lossDuration += loadedTrades[i].duration;
            lossTotal += loadedTrades[i].PnL;
        }
    }
    let perShareGain = perShareSum/loadedTrades.length
    perShareGain =  Math.round((perShareGain + Number.EPSILON) * 1000) / 1000;
    let winRate = winCount/(winCount+lossCount)
    winRate = Math.round((winRate + Number.EPSILON) * 1000) / 10;

    let pnlClass = totalGain > 0 ? "statField win col-5" : "statField loss col-5";
    let winRateClass = winRate > 50 ? "statField win col-5" : "statField loss col-5";

    let profitMargin = (winTotal+lossTotal)/winTotal
    profitMargin = profitMargin*100
    let profMarginClass = ((profitMargin > 35) ? ("col-5 statField win") : ("col-5 statField loss"))

    let avgWinDuration = winDuration/winCount;
    let avgWinDurationStr = msToTime(avgWinDuration)
    let avgLossDuration = lossDuration/lossCount;
    let avgLossDurationStr = msToTime(avgLossDuration)
    let avgWinner = winTotal/winCount;
    avgWinner = Math.round((avgWinner + Number.EPSILON) * 100) / 100;
    let avgLoser = lossTotal/lossCount;
    avgLoser = Math.round((avgLoser + Number.EPSILON) * 100) / 100;
    let profitFactor = avgWinner/avgLoser*-1;
    profitFactor = Math.round((profitFactor + Number.EPSILON) * 100) / 100;

    let profFactClass = profitFactor > 1 ? "statField win col-5" : "statField loss col-5";

    return (
        <div className="row pt-0 statsArea">
            <div className="col-6 col-md-12 statField pe-0 ps-0">
                <div className="row"><div className="col-7">Total Profit / Loss:</div><div className={pnlClass}>${(totalGain).toLocaleString()}</div></div>
                <div className="row"><div className="col-7">Total Fees / Commmissions:</div><div className="col-5">${(totalFnC).toLocaleString()}</div></div>
                <div className="row"><div className="col-7">Avg Daily Profit / Loss:</div><div className="col-5">${(avgDailyPnL).toLocaleString()}</div></div>
                {/* <div className="row"><div className="col-7">Avg Daily Win Rate:</div><div className="col-5">{avgWinRate}%</div></div> */}
                <div className="row"><div className="col-7">Green / Red Day Ratio:</div><div className="col-5">{greenDayRatio}%</div></div>
               

            </div>
            <div className="col-6 col-md-12 statField pe-0 ps-0">
                <div className="row"><div className="col-7">Win / Loss Ratio:</div><div className={winRateClass}>{winRate}%</div></div>
                <div className="row"><div className="col-7">Profit / Loss Ratio:</div><div className={profFactClass}>{profitFactor} : 1</div></div>
                <div className="row"><div className="col-7">Profit Margin:</div><div className={profMarginClass}>{profitMargin.toFixed(0)}%</div></div>
                <div className="row"><div className="col-7">Avg Green Day:</div><div className="col-5"><p className="avgwin statField">${(avgGreenDay).toLocaleString()}</p></div></div>
                <div className="row"><div className="col-7">Avg Red Day:</div><div className="col-5"><p className="avgloss statField">${(avgRedDay).toLocaleString()}</p></div></div>
            </div>
            <div className="col-6 col-md-12 statField pe-0 ps-0">
                <div className="row"><div className="col-7">Avg Daily Volume:</div><div className="col-5">{(avgDailyVol).toLocaleString()} shares</div></div>
                {/* <div className="row"><div className="col-7">Trade Count:</div><div className="col-5">{loadedTrades.length}</div></div> */}
                <div className="row"><div className="col-7">Avg Trade Gain/Share:</div><div className="col-5">${perShareGain}</div></div>
                <div className="row"><div className="col-7">Average Winner:</div><div className="col-5 avgwin">${(avgWinner).toLocaleString()}</div></div>
                <div className="row"><div className="col-7">Average Loser:</div><div className="col-5 avgloss">${(avgLoser).toLocaleString()}</div></div>
                <div className="row"><div className="col-7">Trade Count:</div><div className="col-5">{(winCount + lossCount).toLocaleString()}</div></div>
            </div>
            <div className="col-6 col-md-12 statField pe-0 ps-0">
                <div className="row"><div className="col-7">Avg Win Duration:</div><div className="col-5">{avgWinDurationStr}</div></div>
                <div className="row"><div className="col-7">Avg Loss Duration:</div><div className="col-5">{avgLossDurationStr}</div></div>
                <div className="row"><div className="col-7">Largest Win:</div>
                    <div className="col-5">
                        <Link 
                            to={"/SingleStock"} 
                            state={{ 
                                stock: largestWinner.SYMBOL,
                                day: largestWinner.DATE
                            }}
                            className="calLink">
                            <p className="avgwin statField">
                                ${(largestWinner.PnL).toLocaleString()} <span className="largestLink">({largestWinner.SYMBOL}-{largestWinner.DATE})</span>
                            </p>
                        </Link>
                    </div>
                </div>
                <div className="row statsBottom"><div className="col-7">Largest Loss:</div>
                    <div className="col-5">
                        <Link 
                            to={"/SingleStock"} 
                            state={{
                                stock: largestLoser.SYMBOL,
                                day: largestLoser.DATE
                            }}
                            className="calLink">
                            <p className="avgloss statField ">
                                ${(largestLoser.PnL).toLocaleString()} <span className="largestLink">({largestLoser.SYMBOL}-{largestLoser.DATE})</span>
                            </p>
                        </Link>
                    </div>
                </div>
                <div className="row"><div className="col-7">Current Streak:</div><div className="col-5">{streak} day(s)</div></div>
            </div>
        </div>
    )


}

function msToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    hrs = (hrs == 0 ? "" : (hrs + "hours"))
    //mins = mins.toString().padStart(2, '0')
    mins = (mins == 0 ? "" : (mins + " minutes"))
    // secs = secs.toString().padStart(2, '0') + " seconds"
    secs = (secs == 0 ? "" : (secs + " seconds"))
  
    return hrs + ' ' + mins + ' ' + secs;
  }