import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom'
import { userContext } from './contexts/userContext';
import serverURL from './config/serverConfig';
import Spinner from './components/Spinner';
import Welcome from './components/Welcome';
import { TotalsHeader, IntradayTotals, TotalDayPnL } from './components/IntradayTotals';
import LifetimeChart from './components/LifetimeChart';
import TradeList from './components/TradeList';
import { all } from 'axios';
import { Link } from 'react-router-dom';

export default function Dashboard (props) {

    const location = useLocation()
    const userData = useContext(userContext);
    const username = userData[0];
    const userID = userData[1];

    const [trades, setTrades] = useState([]);
    const [allDays, setAllDays] = useState([]);

    const [loadedA, setLoadedA] = useState(false);
    const [loadedT, setLoadedT] = useState(false);

    const [noTrades, setNoTrades] = useState();

    const today = new Date();
    const dd = String(today.getDate()); //.padStart(2, '0')
    const mm = String(today.getMonth() + 1); //January is 0!
    let yyyy = today.getFullYear();
    yyyy = yyyy.toString().substr(-2);

    const visDate = mm + '/' + dd + '/' + yyyy;
    const [latestDate, setLatestDate] = useState([]);

    useEffect(()=>{

        fetch(serverURL + "json/" + userID + "/allDays.json")
        .then(response => response.json())
        .then((jsonData) => {
          //console.log(jsonData)
          // allDays = jsonData;
          jsonData.sort((a,b) => Date.parse(a.date) - Date.parse(b.date));
          setAllDays(jsonData)
          setLoadedA(true)
        })
        .catch((error) => {
          console.error("No allDays data found - " + error)
          // setNoTrades(true)
          setLoadedA(true)
        })

        fetch(serverURL + "json/" + userID + "/trades.json")
          .then(response => response.json())
          .then((jsonData) => {
            //console.log(jsonData)
            // trades = jsonData;
            setTrades(jsonData)
            setLoadedT(true)
    
            //set max date
            let maxIndex = 0;
            let maxVal = 0;
            for (let i = 1; i < jsonData.length; i++){
              let dateVal = Date.parse(jsonData[i].DATE);
              if (dateVal > maxVal){
                maxVal =dateVal;
                maxIndex = i;
              }
            }
            let maxDate = (location.state==null) ? jsonData[maxIndex].DATE : location.state.date;  //trades.length-1
            setLatestDate(maxDate)
            // console.log(maxDate, visDate, maxDate == visDate)
            // console.log(typeof(maxDate))
            // console.log(typeof(visDate))
            if (maxDate!=visDate){
                setNoTrades(true)
            }
          })
          .catch((error) => {
            console.error("No trade data found - " + error)
            console.error(error)
            setLoadedT(true)
          })
    },[])
    
    // console.log(loadedA + " " + loadedT)
    // console.log(trades.length)
    if (loadedA && loadedT == true){
        if (trades.length==0){
            return <Welcome name={username}/>
        } else {
            return (
                <div id="dashboardContainer" >
                    {/* <div className="row" id="dashUsername"> <h5>{username}</h5></div> */}
                    {/* <div className="row" id="dashMain">
                        <div id="dashLeft" className='col-12 col-md-6'>
                            <DashboardPnL username={username} date={visDate} allDays={allDays} />
                            <DashboardStats allDays={allDays} trades={trades}/>
                        </div>
                        <div id="dashRight" className='col-12 col-md-6'>
                            
                            {noTrades ==true ?
                                <>
                                    <p>No trades today</p>
                                </>
                                :
                                <div className='row'>
                                    <div id="IntradayTotals" className='col-12 col-lg-5'>
                                    <h5>{visDate}</h5>
                                        <TotalsHeader date={visDate} trades={trades}/>
                                        <IntradayTotals date={visDate} trades={trades}/>
                                        <TotalDayPnL date={visDate} trades={trades}/>
                                    </div>
                                    <div className='col-12 col-lg-7'>
                                        <h5>Latest Trades</h5>
                                        <LatestTrades date={latestDate} trades={trades}/>
                                    </div>
                                </div>
                            }
                        </div> 

                    </div> */}
                    <div className="col" id="dashMain">
                        <div id="dashTop" className='row'>
                            <div className='col-12 col-md-5 col-lg-6'>
                                <DashboardPnL username={username} date={visDate} allDays={allDays} />
                            </div>
                            {noTrades ==true ?
                                <div className='col-12 col-md-7 col-lg-6' id="dashNoTrades">
                                    <p>No trades today</p>
                                </div>
                                :
                                <div className='col-12 col-md-7 col-lg-6 ' id="dashPnLSection">
                                    <div className='row'>
                                    <h5>
                                        <Link 
                                            to={"/Intraday"} 
                                            state={{ date: visDate}}
                                            className="calLink"
                                            >
                                                TODAY'S P/L ({visDate})
                                        </Link></h5>
                                    </div>
                                    <div className='row' id="IntradayTotals" >
                                        <TotalsHeader date={visDate} trades={trades}/>
                                        <IntradayTotals date={visDate} trades={trades}/>
                                        <TotalDayPnL date={visDate} trades={trades}/>
                                    </div>
                                </div>
                            }
                        </div>
                        <div id="dashBottom" className='row'>
                            <div className='col-12 col-lg-6 pb-3'>
                                <DashboardStats allDays={allDays} trades={trades} date={visDate}/>
                            </div>
                            <div className='col-12 col-lg-6 pb-3' id="dashLatest">
                                <Link 
                                    to={"/Intraday"} 
                                    state={{ date: latestDate}}
                                    className="calLink">
                                        <h5>LATEST </h5>
                                        <LatestTrades date={latestDate} trades={trades}/>
                                </Link>
                            </div>
                        </div> 
                    </div>
                </div>
            )
        }
    } else {
        return <Spinner />
    }

}

function DashboardPnL(props){

    let totalGain = 0;
    let monthGain = 0;
    const monthArray = (props.date).split("/")

    for (let i = 0; i<props.allDays.length; i++){
        totalGain += props.allDays[i].PnL;

        let currDateArray = (props.allDays[i].date).split("/");
        if ((monthArray[0]==currDateArray[0])&&(monthArray[2]==currDateArray[2])){
            monthGain += props.allDays[i].PnL;
        }
    }

    totalGain = Math.round((totalGain + Number.EPSILON) * 100) / 100;
    let totalClass = (totalGain>0)?"green":"red"
    totalGain = totalGain.toLocaleString()

    monthGain = Math.round((monthGain + Number.EPSILON) * 100) / 100;
    let monthClass = (monthGain>0)?"green":"red"
    let monthArrow = (monthGain>0)? "\u2197" :"\u2198"
    monthGain = monthGain.toLocaleString();






    // console.log(totalClass + " " + totalGain)

    return (
        <div id="dashPnL">
            <p id="dashUsername">{props.username}</p>
            <p id="dashMonthPnL">{getMonthName(monthArray[0])} '{monthArray[2]} : <span className={monthClass}>{monthArrow}${monthGain}</span></p>
            <p id="dashTotalPnL">Account Total P/L : <span className={totalClass}>${totalGain}</span></p>
        </div>
    )
}

function DashboardStats(props){

    let allDays = [...props.allDays]

        // calculate running PnL
        var lifetimeSum = 0;
        var totalGain = 0;
        var greenDayCount = 0;
        var greenDayGain = 0;
        var redDayCount = 0;
        var redDayLoss = 0;
        var streak = 0;
        var greenStreak = false;
        // var avgWinRate = 0;
        var avgDailyPnL = 0;
        // var avgGreenDay = 0;
        // var avgRedDay = 0;
        // var greenDayRatio = 0;
        // var avgDailyVol = 0;

        //console.log(allDays)
    

    for (let i = 0; i<allDays.length; i++){

        //console.log(allDays[i])

        lifetimeSum += allDays[i].totalShares
        totalGain += allDays[i].PnL;
        allDays[i].totalGain = totalGain; //sets running PnL for filtered date range
        
        if (allDays[i].PnL>0){
            greenDayCount++;
            greenDayGain += allDays[i].PnL;
        } else {
            redDayCount++;
            redDayLoss += allDays[i].PnL;
        }

        if ((allDays[i].PnL>0)==greenStreak){
            streak++;
        } else {
            streak = 1;
            greenStreak = (allDays[i].PnL>0);
        }
    }

    totalGain = Math.round((totalGain + Number.EPSILON) * 100) / 100;
    // avgDailyPnL = totalGain/allDays.length;
    //     avgDailyPnL = Math.round((avgDailyPnL + Number.EPSILON) * 100) / 100;

    let winCount = 0;
    let lossCount = 0;
    let winTotal = 0;
    let lossTotal = 0;
    let perShareSum = 0;
    let largestWinner = 0;
    let largestLoser = 0;
    let monthFnC = 0;
    let monthArray = (props.date).split("/")

    for (let i=0; i<props.trades.length; i++){

        let currDateArray = (props.trades[i].DATE).split("/");
        if ((monthArray[0]==currDateArray[0])&&(monthArray[2]==currDateArray[2])){
            perShareSum += (props.trades[i].exit - props.trades[i].entry)

            monthFnC += (props.trades[i].FEE + props.trades[i].COMM)


            if (props.trades[i].PnL>0){
                winCount++;
                largestWinner = (props.trades[i].PnL >= largestWinner.PnL  ? props.trades[i] : largestWinner);
                // winDuration += loadedTrades[i].duration;
                winTotal += props.trades[i].PnL;
            } else {
                lossCount++;
                largestLoser = (props.trades[i].PnL <= largestLoser.PnL  ? props.trades[i] : largestLoser)
                //lossDuration += props.trades[i].duration;
                lossTotal += props.trades[i].PnL;
            }
        }
    }

    let monthGain = 0;
    let dayCount = 0;
    for (let i = 0; i<allDays.length; i++){
        let currDateArray = (allDays[i].date).split("/");
        if ((monthArray[0]==currDateArray[0])&&(monthArray[2]==currDateArray[2])){
            monthGain += allDays[i].PnL;
            dayCount++;
        }
    }
    avgDailyPnL = monthGain/dayCount;
    avgDailyPnL = Math.round((avgDailyPnL + Number.EPSILON) * 100) / 100;

    let winRate = winCount/(winCount+lossCount)
        winRate = Math.round((winRate + Number.EPSILON) * 1000) / 10;
    let avgWinner = winTotal/winCount;
        avgWinner = Math.round((avgWinner + Number.EPSILON) * 100) / 100;
    let avgLoser = lossTotal/lossCount;
        avgLoser = Math.round((avgLoser + Number.EPSILON) * 100) / 100;
    let profitFactor = avgWinner/avgLoser*-1;
        profitFactor = Math.round((profitFactor + Number.EPSILON) * 100) / 100;

    let avgPnLClass = avgDailyPnL > 0 ? "dashStatField win" : "dashStatField loss";
    let monthPnLClass = monthGain > 0 ? "dashStatField win" : "dashStatField loss";
    let winRateClass = winRate > 50 ? "dashStatField win" : "dashStatField loss";
    let profFactClass = ((profitFactor > 1)||((isNaN(profitFactor))&&(avgDailyPnL>0))) ? "dashStatField win" : "dashStatField loss";

    let profitMargin = (winTotal+lossTotal)/winTotal
    profitMargin = profitMargin*100
    let profMarginClass = ((profitMargin > 35) ? ("dashStatField win") : ("dashStatField loss"))

    avgDailyPnL = (isNaN(avgDailyPnL)) ? 0 : avgDailyPnL
    winRate = (isNaN(winRate)) ? "-" : winRate

    profitFactor = (isNaN(profitFactor)) ? "-" : profitFactor



    return (
        <div id="dashLifetime">
            {/* <p>Total Profit/Loss: ${totalGain.toLocaleString()}</p> */}
            <LifetimeChart allDays={allDays} chartTitle={""}/>
            <section id="dashboardStats">
                <p  id="statsHeader">{getMonthName(monthArray[0])} '{monthArray[2]} Stats :</p>
                <div className="col" id="statArea">
                    <div className="row"><span>Total Profit / Loss: </span><span className={monthPnLClass}>${(monthGain).toFixed(2)}</span></div>
                    <div className="row"><span>Total Fees & Commmissions: </span><span>${(monthFnC).toFixed(2)}</span></div>
                    <div className="row"><span>Avg Daily Profit / Loss: </span><span className={avgPnLClass}>${(avgDailyPnL).toFixed(2)}</span></div>
                    <div className="row"><span>Win / Loss Ratio: </span><span className={winRateClass}>{winRate}{(winRate=="-"?"":"%")}</span></div>
                    <div className="row"><span>Profit / Loss Ratio: </span><span className={profFactClass}>{profitFactor}{(profitFactor=="-"?"":" : 1")}</span></div>
                    <div className="row"><span>Profit Margin: </span><span className={profMarginClass}>{profitMargin.toFixed(0)}%</span></div>
                    <div className="row"><span>Current Streak: </span><span>{streak} day{(streak>1)?"s":""} {(greenStreak==true)?"green":"red"}</span></div>
                    <div className="row"><span></span><span></span></div>
                </div>
            </section>
        </div>
    )
}

function LatestTrades(props){
    let trades = props.trades
    // console.log(props.date)

    return (
        <>
            <TradeList date={props.date} trades={trades} tags={[]}/>
        </>
    )
}

function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'long' });
  }