import Chart from "chart.js/auto";
import { LineChartDaily } from "./LineChart";
import { BarChartDaily } from "./BarChart";
import 'chartjs-adapter-date-fns';
import { useState } from "react";

export default function LifetimeChart (props) {

  let trades = [...props.allDays];

  ///// sort trades by date here
  // trades.sort((a,b) => Date.parse(a.date) - Date.parse(b.date));

  //console.log(trades)

  const [chartType, setChartType] = useState("cuml");


  const lineChartData= {
      labels: trades.map((data) => data.date), 
      datasets: [
        {
          data: trades.map((data) => data.totalGain),
          // data: trades.map((data) => data.PnL),
              backgroundColor: [
                  "rgba(60, 179, 113, 0.6)",
                  // "#ecf0f1",
                  // "#50AF95",
                  // "#f3ba2f",
                  // "#2a71d0"
            ],
          borderColor: "black",
          borderWidth: 1,
          fill: {
                  target: 'origin',
                  above: "rgba(60, 179, 113, 0.6)",   // Area will be red above the origin
                  below: "rgba(255, 99, 71, 0.6)"    // And blue below the origin
                },
          label: "Cumulative P/L"
        }
      ]
    };

    const barChartData= {
      labels: trades.map((data) => data.date), 
      datasets: [
        {
          // data: trades.map((data) => data.totalGain),
          data: trades.map((data) => data.PnL),
          // backgroundColor: [
          //     "rgba(75,192,192,1)",
          //     // "#ecf0f1",
          //     // "#50AF95",
          //     // "#f3ba2f",
          //     // "#2a71d0"
          // ],
          backgroundColor: trades.map((data) => data.PnL>0 ? "rgba(60, 179, 113, 0.6)" : "rgba(255, 99, 71, 0.6)"),
          // borderColor: "black",
          // borderWidth: 1,
          // fill: {
          //         target: 'origin',
          //         above: "rgba(60, 179, 113, 0.6)",   // Area will be red above the origin
          //         below: "rgba(255, 99, 71, 0.6)"    // And blue below the origin
          //       },
          // yAxisID: 'y',
          label: "Daily P/L",
        },
        {
          type: 'line',
          data: trades.map((data) => data.movAvg),
          // yAxisID: 'y1',
          borderColor:"rgba(255, 255, 255, .5)",
          backgroundColor:"rgba(255, 255, 255, .5)",
          label: "20 Day Mov Avg",
          showLine: "true",
          borderWidth: 1,
          stepped: false,
          tension: .5,
          pointRadius: 0


        }

      ]
    };

      const viewBarChart = <BarChartDaily chartData={barChartData} title={props.chartTitle}/>
      const viewLineChart = <LineChartDaily chartData={lineChartData} title={props.chartTitle}/>
      const viewChart = (chartType == "cuml") ? viewLineChart : viewBarChart;
    // }
    function toggleView(){
      if (chartType=="cuml"){
        setChartType("daily")
      } else {
        setChartType("cuml")
      }
    }

    let buttonText = chartType == "cuml" ? <><span>&#x1F4CA;</span> VIEW</> : <><span>&#128200;</span> VIEW</> //"DAILY VIEW" : "CUMULATIVE VIEW"
  
    return (
        // <LineChartDaily chartData={chartData} title={props.chartTitle}/>
        // <BarChartDaily chartData={chartData} title={props.chartTitle}/>
        <>
          <h5 style={{ textAlign: "center" }} className="mt-3 mb-0">{props.chartTitle}</h5>
          {viewChart}
          <div id="viewButtonRow">
            <button id="chartToggle" className="btn btn-dark btn-sm p-1" onClick={toggleView}>{buttonText}</button>
          </div>
        </>
    )
}