/* Received date and trades list will return totals of each symbol and total PnL of day selected */

import {Routes, Route, Link} from 'react-router-dom';

/* Header Row */
function TotalsHeader(props) {

    const singleDayTrades = props.trades.filter(i => (i.DATE.includes(props.date))); //filter out trade list for selected Date

    if (singleDayTrades.length == 0) {
        return <></>
    } else {

    return(
        // <div className="row ps-1 totalsRow" id="totalsHeader">
        //     <div className="col-2">Symbol</div>
        //     <div className="col-3 ps-3">P/L</div>
        //     <div className="col-3">Shares</div>
        //     <div className="col-4">Gain/Share</div>
        // </div>
        <div className="totalsRow" id="totalsHeader">
        <div className="">Symbol</div>
        <div className="">P/L</div>
        <div className="totalsShares">Shares</div>
        <div className="totalsGainShare">Gain/Share</div>
    </div>
    )
    }
}

/* print table row for each symbol traded */
function IntradayTotals(props){

    /* get total PnL for each symbol traded on the day */
    const key = "SYMBOL";
    var symbolsPnL = [];
    const singleDayTrades = props.trades.filter(i => (i.DATE.includes(props.date))); //filter out trade list for selected Date
    const symbolsTraded = [...new Map(singleDayTrades.map(item => [item[key], item])).values()].map(({ SYMBOL }) => SYMBOL); //returns list of unique symbols
    //console.log(singleDayTrades)
    
    ////find pnl for each symbol 
    for (var i = 0; i<symbolsTraded.length; i++){

        var symbol = {};
        symbol.SYMBOL = symbolsTraded[i];
        symbol.PnL = 0;
        symbol.shares = 0;

        for (var n = 0; n < singleDayTrades.length; n++){               //start at end of list
            if (symbolsTraded[i]==singleDayTrades[n].SYMBOL){            //if current symbol key matches in the list
                symbol.PnL += singleDayTrades[n].PnL;
                symbol.shares += singleDayTrades[n].totalSize;
            }else{}
        }

        symbol.PnL = (symbol.PnL).toFixed(2);
        if (symbol.PnL>0) {
            //symbol.winStatus = "col-3 win pnl";
            symbol.winStatus = "win pnl";
        } else {
            //ymbol.winStatus = "col-3 loss pnl";
            symbol.winStatus = "loss pnl";
        }

        symbol.perShareGain = symbol.PnL/symbol.shares;
        symbol.perShareGain = Math.round((symbol.perShareGain + Number.EPSILON) * 1000) / 1000;
        symbolsPnL.push(symbol);
    }

    /* print total pnl for each symbol */
    if (props.links == false){
        return (
            symbolsPnL.map((symbol, index) => {
                return (
                    <div key={index} className="row totalsEntry">
                        <div className="col-2 symbol ps-2">
                            {symbol.SYMBOL}
                        </div>
                        <div className={symbol.winStatus}>
                            ${symbol.PnL}
                        </div>
                        <div className="col-3 col-lg-3 shareSize">
                            {(symbol.shares).toLocaleString()}
                        </div>
                        <div className="col-4 col-sm-4  text-start shareGain totalsGainShare">
                            ${symbol.perShareGain}
                        </div>
                    </div>
                )
            })
        )
    } else {
        return (
            symbolsPnL.map((symbol, index) => {
                return (
                    // <div key={index} className="row totalsEntry ps-1">
                    //     <div className="col-2 symbol">
                    //         <Link 
                    //         to={"SingleStock"}
                    //         state= {{
                    //             stock: symbol.SYMBOL,
                    //             day: props.date,
                    //         }} 
                    //         className="symbolLink">
                    //             {symbol.SYMBOL}
                    //         </Link>
                    //     </div>
                    //     <div className={symbol.winStatus}>
                    //         ${symbol.PnL}
                    //     </div>
                    //     <div className="col-3 col-lg-3 shareSize">
                    //         {(symbol.shares).toLocaleString()}
                    //     </div>
                    //     <div className="col-4 col-sm-4 symbol">
                    //         ${symbol.perShareGain}
                    //     </div>
                    // </div>
                    <div key={index} className="totalsRow totalsEntry">
                    <div className="symbol">
                        <Link 
                        to={"/SingleStock"}
                        state= {{
                            stock: symbol.SYMBOL,
                            day: props.date,
                        }} 
                        className="symbolLink">
                            {symbol.SYMBOL}
                        </Link>
                    </div>
                    <div className={symbol.winStatus}>
                        ${symbol.PnL}
                    </div>
                    <div className="shareSize">
                        {(symbol.shares).toLocaleString()}
                    </div>
                    <div className="symbol totalsGainShare">
                        ${symbol.perShareGain}
                    </div>
                </div>
                )
            })
        )
    }
}

/* print total PnL for the day */
function TotalDayPnL(props){
    /* get total PnL for each symbol traded on the day */
    const key = "SYMBOL";
    var symbolsPnL = [];
    const singleDayTrades = props.trades.filter(i => (i.DATE.includes(props.date))); //filter out trade list for selected Date
    // console.log(singleDayTrades)
    const symbolsTraded = [...new Map(singleDayTrades.map(item => [item[key], item])).values()].map(({ SYMBOL }) => SYMBOL); //returns list of unique symbols
   
    ////find pnl for each symbol - by finding last entry for each symbol//////
    for (var i = 0; i<symbolsTraded.length; i++){
        for (var n = singleDayTrades.length-1; n>=0; n--){               //start at end of list
            if (singleDayTrades[n].SYMBOL==symbolsTraded[i]){            //if current symbol key matches in the list
                var symbol = {};                                //create a new object to be pushed to array
                symbol.SYMBOL = singleDayTrades[n].SYMBOL;
                symbol.PnL = singleDayTrades[n].runPnL;
                symbolsPnL.push(symbol);                        //push symbol to totals by symbol list array
                break;                                          //jumping out of loop if found and search for next symbol
            }else{}
        }
    }

    let totalFnC = 0;
    for (let x in singleDayTrades){
        totalFnC += (singleDayTrades[x].totalFees + singleDayTrades[x].totalComms)
    }

    /* print total PnL for the day */
    let dayTotalPnL = 0;

    for (let i=0; i<symbolsPnL.length; i++){
        dayTotalPnL += symbolsPnL[i].PnL;
    }
    dayTotalPnL = (Math.round((dayTotalPnL + Number.EPSILON) * 100) / 100).toFixed(2);
    let winStatus = "";
    if (dayTotalPnL>0) {
        //winStatus = "col-3 win symbol";
        winStatus = "win symbol";
    } else {
        //winStatus = "col-3 loss symbol";
        winStatus = "loss symbol";
    }
 
    if (symbolsTraded.length == 0) {
        return <></>
    } else {

    return (
        <>
        <div className="mt-1" id="totalRow">
            <div className="symbol psGain smallSymb">TOTAL</div>
            <div className={winStatus}>
                ${dayTotalPnL}&nbsp;
            </div>
            {/* <div className="col-5 symbol psGain"></div> */}
        </div>
        <div id="feeAndCommRow">Fees & Commissions: {(isNaN(totalFnC))? "-":"$"+(totalFnC.toFixed(2))}</div>
        </>
    )
}
}

export { TotalsHeader, IntradayTotals, TotalDayPnL };