import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom'

import IntradayChart from './components/IntradayChart';
import IntradayStats from './components/IntradayStats';
import TradeList from './components/TradeList';
import { TotalsHeader, IntradayTotals, TotalDayPnL } from './components/IntradayTotals';
import { userContext } from './contexts/userContext';
import serverURL from './config/serverConfig';
import Welcome from './components/Welcome';
import Spinner from './components/Spinner';
import UpgradePrompt from './components/Upgrade';
import { TagBarChart, TagPieChart, TagHiLowBarChart } from './components/StatsCharts';


export default function Stats (props) {

    const [selectedTag, setSelectedTag] = useState();
    const [tagsLoaded, setTagsLoaded] = useState(false);
    const [tradesLoaded, setTradesLoaded] = useState(false)
    const [trades, setTrades] = useState()
    const [tags, setTags] = useState()

    const location = useLocation()
    const userData = useContext(userContext);
    const user = userData[1];
    const subscription = userData[4]

    const defaultTags = require('./config/tagList.json')
    //console.log(defaultTags)


    useEffect(()=>{
        if (location.state!=null){
            setSelectedTag(location.state.tag)
        }
    },[])

    useEffect(()=>{
        //// fetch trade tags
        fetch(serverURL + "json/" + user + "/tradeTags.json")
          .then(response => response.json())
          .then((jsonData) => {
            //console.log(jsonData)
            setTags(jsonData);
            setTagsLoaded(true)
          })
          .catch((error) => {
            console.error("No tag file found - " + error)
            setTagsLoaded(true)
          })
    },[])

    useEffect(()=>{
        //// fetch trade tags
        fetch(serverURL + "json/" + user + "/trades.json")
          .then(response => response.json())
          .then((jsonData) => {
            //console.log(jsonData)
            setTrades(jsonData);
            setTradesLoaded(true)
          })
          .catch((error) => {
            console.error(error)
            setTrades("none")
            setTradesLoaded(true)
          })
    },[])

    //////////// upon successful loading ////////////
    if ((tradesLoaded==true)&&(tagsLoaded==true)&&(trades!="none")&&(tags!=undefined)){
        
        //console.log(tags)
        //console.log(trades)

        var selectedTagList = [];
        for (let i=0; i<trades.length; i++){
            if (tags[trades[i].REF]==selectedTag){
                selectedTagList.push(trades[i])
            }
        }

        let allTagList = Object.values(tags);
        const filtList = (i) => {
            return i.filter((value, index) => i.indexOf(value) === index);
        }
        var tagList = filtList(allTagList)
        //console.log(tagList)

        var taggedTrades = {};
        for (let i=0; i<tagList.length; i++){
            taggedTrades[tagList[i]]=[];
        }
        for (let i=0; i<trades.length; i++){
            if ((tags[trades[i].REF]!=undefined)&&(tags[trades[i].REF]!="")){
                // filteredTrades[i].push(trades[x])
                taggedTrades[tags[trades[i].REF]].push(trades[i])
            }
        }
        //console.log(taggedTrades)

    }
    
    if (subscription == "essentials"){
        return <UpgradePrompt />
    } else {

        if (tradesLoaded==true && tagsLoaded==true){
            if (trades=="none"){
                return <Welcome name={userData[0]}/>
            } else if (tags==undefined ||(tags.length == 0)) {
                return <div id="welcomePage"><p id="noTags">&#9751;&nbsp;Tag trades to see tag specific statistics</p></div>        
            } else {
                return (
                    <div id="statsPage">
                        {/* {selectedTag}
                        <p> {selectedTagList.length} trade(s)</p> */}
                        <h4>TAG STATS</h4>
                        {/* <TradeList date={visDate} trades={trades} tags={tradeTags}/> */}
                        <TagStats taggedTrades={taggedTrades} tagList={tagList}/>
                    </div>
                )
            }

        } else {
            return <Spinner />
        }
    }

}



/////////////////////////////////////////////////////////////////////

function TagStats (props) {
    let taggedTrades = props.taggedTrades
    let tagList = props.tagList
    // console.log(tagList)
    let tagStats = [];

    for (let i=0; i<tagList.length; i++){    ///for each unique tag

        let singleTag = tagList[i]
        let singleTagList = taggedTrades[singleTag]
        let wins = 0;
        let losses = 0;
        let vol = 0;
        let winVol = 0;
        let lossVol = 0;
        let winPnL = 0;
        let lossPnL = 0;
        let largestWin = 0;
        let largestLoss = 0;

        for (let x=0; x<singleTagList.length; x++){
            //console.log(taggedTrades[tagList[i]][x])
            vol += singleTagList[x].tradeSize;
            if((singleTagList[x].Win)==true){
                wins++;
                winPnL += singleTagList[x].PnL;
                winVol += singleTagList[x].totalSize;
                if (singleTagList[x].PnL>largestWin){
                    largestWin=singleTagList[x].PnL;
                }
            } else {
                losses++;
                lossPnL += singleTagList[x].PnL;
                lossVol += singleTagList[x].totalSize;
                if (singleTagList[x].PnL<largestLoss){
                    largestLoss=singleTagList[x].PnL;
                }
            }
        }
        let winRate = (wins/(wins+losses))*100;
        winRate=winRate.toFixed(2);

        //console.log(winPnL + " " + lossPnL)

        let entry = {
            tag: singleTag,
            winRate: winRate,
            PnL: winPnL+lossPnL,
            count: wins+losses,
            vol: winVol + lossVol,
            avgWinner: winPnL/wins,
            avgLoser: lossPnL/losses,
            avgVol: (winVol + lossVol)/(wins+losses),
            largestLoss: largestLoss,
            largestWin: largestWin,
            profitFactor: winPnL/(lossPnL*-1)
            
        }
        if (singleTag!=""){
            tagStats.push(entry)
        }
        // tagStats.push(entry)
        // console.log(entry.profitFactor)

    }

    // const winRatesTable = tagWinRates.map((tag) => 
    //     <li>
    //         {tag.tag}: {tag.winRate}%
    //     </li>
    // )
    return (
        <div id="statCharts">
            <TagPieChart stat={tagStats} statName={"PnL"} label={"P/L"}/>
            <TagPieChart stat={tagStats} statName={"count"} label={"Trade Count"}/>
            <TagBarChart stat={tagStats} statName={"winRate"} label={"Win Rates (%)"}/>
            <TagBarChart stat={tagStats} statName={"profitFactor"} label={"Profit Factor"}/>
            <TagHiLowBarChart stat={tagStats} high={"avgWinner"} low={"avgLoser"} labelH={"Average Winner"} labelL={"Average Loser"} />
            <TagHiLowBarChart stat={tagStats} high={"largestWin"} low={"largestLoss"} labelH={"Largest Winner"} labelL={"Largest Loser"} />
            <TagBarChart stat={tagStats} statName={"avgVol"} label={"Average Trade Size (shares)"}/>
        </div>
    )
}