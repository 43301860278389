import { useContext } from 'react';
import { useState } from 'react';
import {Routes, Route, Link, NavLink} from 'react-router-dom';
import { userContext } from '../contexts/userContext';
import UserMenu from './UserMenu';
import { Dropdown } from 'rsuite';



export default function NavBar () {

    const user = useContext(userContext);
    const userName = user[0];
    const [ show, setShow ] = useState(false);

    const loggingOut = () => {
        console.log("Logging Out!")
        user[2]();
    }

    return (
        <>
        <ul className="navItems" id="desktopMenu">
            <li className="logoText">
                <span>SNIPER LOG</span><span id="beta">beta</span>
                <img src={require('../assets/logoWhite.png')} />
            </li>
            <li>
                <ul id="navBarLinks">
                    <li>
                        <NavLink
                            to="Dashboard"
                            className={({ isActive, isPending }) =>
                                isActive? "navLink active" : "navLink pending"
                            }
                            >
                            Dashboard
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="Intraday"
                            className={({ isActive, isPending }) =>
                                isActive? "navLink active" : "navLink pending"
                            }
                            >
                            Intraday
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="Lifetime"
                            className={({ isActive, isPending }) =>
                            isActive? "navLink active" : "navLink pending"
                            }
                            >
                            Lifetime
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="Stats"
                            className={({ isActive, isPending }) =>
                                isActive? "navLink active" : "navLink pending"
                            }
                            >
                            Tags
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="Symbols"
                            className={({ isActive, isPending }) =>
                                isActive? "navLink active" : "navLink pending"
                            }
                            >
                            Symbols
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="Upload"
                            className={({ isActive, isPending }) =>
                                isActive? "navLink active" : "navLink pending"
                            }
                            >
                            Upload
                        </NavLink>
                    </li>
                </ul>
            </li>
            {/* <li id="userLabel" role="button" onClick={loggingOut}>{userName}: Logout</li> */}
            <li><UserMenu user={user} logOut={loggingOut} /></li>
        </ul>
        <ul id="mobileMenu" className='navItems'>
            <li className="logoText">
                <span>SNIPER LOG</span><span id="beta">beta</span>
                <img src={require('../assets/logoWhite.png')} />
            </li>
            <li>
                <Dropdown 
                    title={"\u2630\u2630"}
                    size="xs"
                    appearance="primary"
                    // autoClose="inside"
                    className = "mobileDropdown"
                    noCaret
                    >
                        <Dropdown.Item onClick = {() => setShow(!show)} className="dropLink"><NavLink to="Dashboard" className="dropdown">Dashboard</NavLink></Dropdown.Item>
                        <Dropdown.Item><NavLink to="Intraday" className="dropdown">Intraday</NavLink></Dropdown.Item>
                        <Dropdown.Item><NavLink to="Lifetime" className="dropdown">Lifetime</NavLink></Dropdown.Item>
                        <Dropdown.Item><NavLink to="Stats" className="dropdown">Tags</NavLink></Dropdown.Item>
                        <Dropdown.Item><NavLink to="Symbols" className="dropdown">Symbols</NavLink></Dropdown.Item>
                        <Dropdown.Item><NavLink to="Upload" className="dropdown">Upload</NavLink></Dropdown.Item>
                        {(userName=="SniperLog Demo")?"":
                            <Dropdown.Item><NavLink to="Settings" className="dropdown">Settings</NavLink></Dropdown.Item>
                        }
                        <Dropdown.Item onClick={()=>{user[2]()}}><span className="dropdown">Logout</span></Dropdown.Item>
                </Dropdown>
            </li>
        </ul>
        </>
    )
}