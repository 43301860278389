export const TOS = (
    <article id="floatingText">
        <section id="logoHeader">
            <h2 id="logo">Sniper Log</h2>
            <img id="logoImg" src={require('../assets/logoWhite.png')} />
        </section>
        <h3>Terms of Service</h3>
        <p>PLEASE READ THESE TERMS OF SERVICE CAREFULLY BEFORE USING THIS WEBSITE OR ANY OF THE SERVICES OFFERED THROUGH IT.</p>
        <h4>1. Acceptance of Terms</h4>
        <p>
            By using this website or any of the services offered through it, you agree to be bound by these Terms of Service. If you do not agree to these Terms of Service, you may not use this website or any of the services offered through it.
        </p>
        <h4>2. Description of Services</h4>
        <section>
            This website provides an online day trading journal that allows users to track and analyze their trading activity. The journal includes features such as:
            <ul>
                <li>A trade log</li>
                <li>Performance charts & metrics</li>
                {/* <li>Risk management tools</li>
                <li>Market commentary</li> */}
            </ul>
        </section>
        <h4>3. User Accounts</h4>
        <p>In order to use the journal, you must create a user account. You agree to provide accurate and up-to-date information about yourself when creating your account. You are responsible for maintaining the confidentiality of your account password and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.</p>
        <h4>4. User Conduct</h4>
        <section>
            You agree to use the journal in a responsible and ethical manner. You agree not to use the journal to:
            <ul>
                <li>Transmit any material that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.</li>
                <li>Harm or harass others.</li>
                <li>Impersonate any person or entity.</li>
                <li>Interfere with or disrupt the operation of the journal or the servers or networks connected to the journal.</li>
                <li>Transmit any virus, worm, Trojan horse, or other harmful or disruptive code.</li>
                <li>Use the journal for any commercial purpose.</li>
            </ul>
        </section>
        <h4>5. Intellectual Property</h4>
        <p>The content of this website, including the journal, is protected by copyright and other intellectual property laws. You agree not to reproduce, distribute, or transmit any of the content of this website without the prior written consent of Sniper Log.</p>
        <h4>6. Disclaimer of Warranties</h4>
        <p>The journal is provided "as is" and without any warranties, express or implied. Sniper Log disclaims all warranties of merchantability, fitness for a particular purpose, and non-infringement. Sniper Log does not warrant that the journal will be uninterrupted or error-free. Sniper Log is not responsible for any damages caused by the use of the journal.</p>
        <h4>7. Limitation of Liability</h4>
        <p>Sniper Log shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising out of the use of the journal</p>
    </article>
)