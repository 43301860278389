import Chart from "chart.js/auto";
import 'chartjs-adapter-date-fns';
import { CategoryScale } from "chart.js";
import { useState, useRef } from "react";
import { LineChart } from "./LineChart"


Chart.register(CategoryScale);

export default function IntradayChart(props){
  let trades = props.trades;
  let visDate = props.date;

  const tradeList = [];
  const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  const d = new Date(visDate);
  let dayDOW = weekday[d.getDay()];
  let chartTitle = dayDOW + " "+ props.date


  for (let i = 0; i < trades.length; i++) {
    if (trades[i].DATE == visDate){
      tradeList.push(trades[i])
    } else {}
  }

  let dayPnL = 0;
  for (let i=0; i < tradeList.length; i++) {
    dayPnL += tradeList[i].PnL
    tradeList[i].dayPnL = dayPnL
  }
  //console.log(tradeList)
  let currentCanvas = document.querySelector('.chart-container canvas')
  let currentChart = Chart.getChart(currentCanvas); //where canvas-id is the id of a canvas

  const chartData= {
    labels: tradeList.map((data) => data.unixTime), //data.TIME -> data.unixTime
    datasets: [
      {
        data: tradeList.map((data) => data.dayPnL),
            backgroundColor: [
                "rgba(75,192,192,1)",
         ],
        borderColor: "black",
        borderWidth: 1,
        fill: {
                target: 'origin',
                above: "rgba(60, 179, 113, 0.6)",   // Area will be red above the origin
                below: "rgba(255, 99, 71, 0.6)"    // And blue below the origin
              },
        label: "Intraday P/L",
        // clip: {left: 5, top: true, right: -10, bottom: 100},
      }
    ]
    
  };


  if ((tradeList).length == 0){
    return (<article id="noTrades">No Trades on {visDate}</article>)
  } else {
  return (
      <LineChart chartData={chartData} title={chartTitle}/>
  )}
}

