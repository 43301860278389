import { useState, useEffect, useContext } from "react";
import { useLocation } from 'react-router-dom'
import { userContext } from './contexts/userContext';
import serverURL from './config/serverConfig';
import Welcome from './components/Welcome';
import Spinner from './components/Spinner';
import SingleLifetime from "./SingleLifetime";
import { Link } from "react-router-dom";
import UpgradePrompt from "./components/Upgrade";

export default function Symbols (props) {

    const location = useLocation()
    const userData = useContext(userContext);
    const user = userData[1];
    const subscription = userData[4]

    const [trades, setTrades] = useState()
    const [allDays, setAllDays] = useState()
    const [tradesLoaded, setTradesLoaded] = useState(false)
    const [daysLoaded, setDaysLoaded] = useState(false)
    //const [symbolListArray, setSymbolListArray] = useState([])
    const [sort, setSort] = useState("symbolD")

    useEffect(()=>{
        fetch(serverURL + "json/" + user + "/trades.json")
          .then(response => response.json())
          .then((jsonData) => {
            //console.log(jsonData)
            setTrades(jsonData);
            setTradesLoaded(true)
          })
          .catch((error) => {
            console.error(error)
            setTrades("none")
            setTradesLoaded(true)
          })

          fetch(serverURL + "json/" + user + "/allDays.json")
          .then(response => response.json())
          .then((jsonData) => {
            //console.log(jsonData)
            setAllDays(jsonData);
            setDaysLoaded(true)
          })
          .catch((error) => {
            console.error(error)
            setAllDays("none")
            setDaysLoaded(true)
          })
    },[])

    if (subscription == "essentials"){
        return <UpgradePrompt />
    } else {
        if ((tradesLoaded == true)&& (daysLoaded == true)){
            if (trades=="none"){
                return <Welcome name={userData[0]}/>
            } else {

                /////////////////////////////// Main Logic Here ///////////////////////////////

                let symbolList = {}
                
                //// create symbol List ////
                for (let i in trades){

                    let wins = (trades[i].Win==true)? 1 : 0
                    let losses = (trades[i].Win==true)? 0 : 1
                    let winPnL = (trades[i].Win==true)? trades[i].PnL : 0
                    let lossPnL = (trades[i].Win==true)? 0 : trades[i].PnL
                    let shares = trades[i].totalSize
                    //let daysTraded = 1;
                    let lastDay = trades[i].DATE
                    //console.log(trades[i])
                    if (symbolList[trades[i].SYMBOL]==undefined){
                        let symbol = {
                            symbol: trades[i].SYMBOL,
                            shares: shares,
                            wins: wins,
                            losses: losses,
                            winPnL: winPnL,
                            lossPnL: lossPnL,
                            daysTraded: 1,
                            lastDay: lastDay
                        }
                        symbolList[trades[i].SYMBOL]=symbol
                    } else {
                        wins += symbolList[trades[i].SYMBOL].wins
                        losses += symbolList[trades[i].SYMBOL].losses
                        winPnL += symbolList[trades[i].SYMBOL].winPnL
                        lossPnL += symbolList[trades[i].SYMBOL].lossPnL
                        shares += symbolList[trades[i].SYMBOL].shares
                        let daysTraded = 0

                        if (symbolList[trades[i].SYMBOL].lastDay != trades[i].DATE){
                            daysTraded = (symbolList[trades[i].SYMBOL].daysTraded) + 1
                        } else {
                            daysTraded = (symbolList[trades[i].SYMBOL].daysTraded)
                        }

                        let symbol = {
                            symbol: trades[i].SYMBOL,
                            shares: shares,
                            wins: wins,
                            losses: losses,
                            winPnL: winPnL,
                            lossPnL: lossPnL,
                            daysTraded: daysTraded,
                            lastDay: lastDay
                        }
                        symbolList[trades[i].SYMBOL]=symbol
                        //console.log(symbol)
                    }
                }
                
                let symbolListArray = []
                for (let i in symbolList){
                    symbolListArray.push(symbolList[i])
                }

                ////// sort List //////

                let symbolSort = " "
                let pnlSort = " "
                let sharesSort = " "
                let winRateSort = " "
                let profFactSort = " "
                let tradesSort = " "
                let daysSort = " "
                let lastTradedSort = " "


                if (sort == "symbolD"){
                    symbolListArray.sort((a,b) => a.symbol > b.symbol ? 1 : ((b.symbol > a.symbol) ? -1 : 0))
                    symbolSort = "\u2193";
                } else if (sort == "symbolU") {
                    symbolListArray.sort((a,b) => a.symbol < b.symbol ? 1 : ((b.symbol < a.symbol) ? -1 : 0))
                    symbolSort = "\u2191";
                } else if (sort == "pnlD") {
                    symbolListArray.sort((a,b) => (a.winPnL + a.lossPnL) < (b.winPnL + b.lossPnL) ? 1 : (((b.winPnL + b.lossPnL) < (a.winPnL + a.lossPnL)) ? -1 : 0))
                    pnlSort = "\u2193";
                } else if (sort == "pnlU") {
                    symbolListArray.sort((a,b) => (a.winPnL + a.lossPnL) > (b.winPnL + b.lossPnL) ? 1 : (((b.winPnL + b.lossPnL) > (a.winPnL + a.lossPnL)) ? -1 : 0))
                    pnlSort = "\u2191";
                } else if (sort == "sharesD") {
                    symbolListArray.sort((a,b) => a.shares < b.shares ? 1 : ((b.shares < a.shares) ? -1 : 0))
                    sharesSort = "\u2193";
                } else if (sort == "sharesU") {
                    symbolListArray.sort((a,b) => a.shares > b.shares ? 1 : ((b.shares > a.shares) ? -1 : 0))
                    sharesSort = "\u2191";
                } else if (sort == "daysD") {
                    symbolListArray.sort((a,b) => a.daysTraded < b.daysTraded ? 1 : ((b.daysTraded < a.daysTraded) ? -1 : 0))
                    daysSort = "\u2193";
                } else if (sort == "daysU") {
                    symbolListArray.sort((a,b) => a.daysTraded > b.daysTraded ? 1 : ((b.daysTraded > a.daysTraded) ? -1 : 0))
                    daysSort = "\u2191";
                } else if (sort == "tradesD") {
                    symbolListArray.sort((a,b) => (a.wins+a.losses) < (b.wins+b.losses) ? 1 : (((b.wins+b.losses) < (a.wins+a.losses)) ? -1 : 0))
                    tradesSort = "\u2193";
                } else if (sort == "tradesU") {
                    symbolListArray.sort((a,b) => (a.wins+a.losses) > (b.wins+b.losses) ? 1 : (((b.wins+b.losses) > (a.wins+a.losses)) ? -1 : 0))
                    tradesSort = "\u2191";
                } else if (sort == "winRateD") {
                    symbolListArray.sort((a,b) => ((a.wins)/(a.wins+a.losses)) < ((b.wins)/(b.wins+b.losses)) ? 1 : ((((b.wins)/(b.wins+b.losses)) < ((a.wins)/(a.wins+a.losses))) ? -1 : 0))
                    winRateSort = "\u2193";
                } else if (sort == "winRateU") {
                    symbolListArray.sort((a,b) => ((a.wins)/(a.wins+a.losses)) > ((b.wins)/(b.wins+b.losses)) ? 1 : ((((b.wins)/(b.wins+b.losses)) > ((a.wins)/(a.wins+a.losses))) ? -1 : 0))
                    winRateSort = "\u2191";
                } else if (sort == "profitFactorD") {
                    symbolListArray.sort((a,b) => ((a.winPnL)/(+a.lossPnL*-1)) < ((b.winPnL)/(b.lossPnL)*-1) ? 1 : ((((b.winPnL)/(b.lossPnL)*-1) < ((a.winPnL)/(a.lossPnL))*-1) ? -1 : 0))
                    profFactSort = "\u2193";
                } else if (sort == "profitFactorU") {
                    symbolListArray.sort((a,b) => ((a.winPnL)/(+a.lossPnL*-1)) > ((b.winPnL)/(b.lossPnL)*-1) ? 1 : ((((b.winPnL)/(b.lossPnL)*-1) > ((a.winPnL)/(a.lossPnL))*-1) ? -1 : 0))
                    profFactSort = "\u2191";
                } else if (sort == "lastTradedD") {
                    symbolListArray.sort((a,b) => new Date(a.lastDay) <  new Date(b.lastDay) ? 1 : (( new Date(b.lastDay) <  new Date(a.lastDay)) ? -1 : 0))
                    lastTradedSort = "\u2193";
                } else if (sort == "lastTradedU") {
                    symbolListArray.sort((a,b) =>  new Date(a.lastDay) >  new Date(b.lastDay) ? 1 : (( new Date(b.lastDay) >  new Date(a.lastDay)) ? -1 : 0))
                    lastTradedSort = "\u2191";
                } else {

                }
    
                

                let symbolTable = []
                for (let i in symbolListArray){

                    let winRate = (symbolListArray[i].wins/(symbolListArray[i].wins+symbolListArray[i].losses))*100
                    let profFactor = (symbolListArray[i].winPnL/(symbolListArray[i].lossPnL)*-1)
                    let pnlClass = ((symbolListArray[i].winPnL + symbolListArray[i].lossPnL)>0) ? "win" : "loss"
                    let winRateClass = ((winRate)>49.99) ? "lightGreen" : "lightRed"
                    let profFactorClass = ((profFactor)>1) ? "lightGreen" : "lightRed"

                    //let profitMargin = 


                    symbolTable.push(
                        //symbolList[i]
                        <li key={symbolListArray[i].symbol}>
                            <div className = "symbolName">
                                <Link 
                                to={"/SingleLifetime"}
                                state= {{
                                    stock: symbolListArray[i].symbol,
                                }} 
                                className="symbolLink">
                                    {symbolListArray[i].symbol}
                                </Link>
                            </div>
                            <div className={pnlClass}>${(symbolListArray[i].winPnL + symbolListArray[i].lossPnL).toFixed(2)}</div>
                            <div>{(symbolListArray[i].daysTraded).toLocaleString()}</div>
                            <div className="symbolTradeCount">{symbolListArray[i].wins+symbolListArray[i].losses}</div>
                            <div className="symbolShares">{(symbolListArray[i].shares).toLocaleString()}</div>
                            <div className={winRateClass}>{(winRate).toFixed(2)}%</div>
                            <div className={profFactorClass}>{(profFactor).toFixed(2)}</div>
                            <div>{(symbolListArray[i].lastDay)}</div>
                        </li>

                        )
                }


                ///////////////////////////////////////////////////////////////////////////////
                return (
                    <>
                    
                    <ul className="symbolsList">
                    <h4>Profit / Loss by Symbol</h4>
                        <li id="tableHeader">
                            <div
                                className = "symbolName"
                                onClick={()=>
                                    {
                                        (sort=="symbolD") ? setSort("symbolU") : setSort("symbolD")
                                    }}
                                >SYMBOL <span>{symbolSort}</span>
                            </div>
                            <div
                                onClick={()=>
                                    {
                                        (sort=="pnlD") ? setSort("pnlU") : setSort("pnlD")
                                    }}
                                >P/L <span>{pnlSort}</span>
                            </div>
                            <div 
                                onClick={()=>
                                {
                                    (sort=="daysD") ? setSort("daysU") : setSort("daysD")
                                }}
                                >Days Traded <span>{daysSort}</span>
                            </div>
                            <div 
                                className="symbolTradeCount"
                                onClick={()=>
                                {
                                    (sort=="tradesD") ? setSort("tradesU") : setSort("tradesD")
                                }}
                                >Trade Count <span>{tradesSort}</span>
                            </div>
                            <div
                                className="symbolShares"  
                                onClick={()=>
                                {
                                    (sort=="sharesD") ? setSort("sharesU") : setSort("sharesD")
                                }}
                                >Shares <span>{sharesSort}</span>
                            </div>
                            <div
                                onClick={()=>
                                {
                                    (sort=="winRateD") ? setSort("winRateU") : setSort("winRateD")
                                }}
                                >Win Rate <span>{winRateSort}</span>
                            </div>
                            <div 
                                onClick={()=>
                                {
                                    (sort=="profitFactorD") ? setSort("profitFactorU") : setSort("profitFactorD")
                                }}
                                >P/L Ratio <span>{profFactSort}</span>
                            </div>
                            <div 
                                onClick={()=>
                                {
                                    (sort=="lastTradedD") ? setSort("lastTradedU") : setSort("lastTradedD")
                                }}
                                >Last Traded <span>{lastTradedSort}</span>
                            </div>
                        </li>
                        </ul>
                        <ul className="symbolsList" id="listBody">
                        {symbolTable}
                    </ul>
                    </>
                )
            }

        } else {
            return <Spinner />
        }
    }
}