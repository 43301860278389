

export default function SingleStats (props) {

    let dayTotals = {
        winCount: 0,
        lossCount: 0,
        totalGain: 0,
        totalLoss: 0,
        sharesWin: 0,
        sharesLoss: 0,
        cumPnL: 0
    };

    let largestWinner = {"PnL": 0};
    let largestLoser = {"PnL": 0};
    let winDuration = 0;
    let lossDuration = 0;
    let winCount = 0;
    let lossCount = 0;

    for(var n = 0; n<props.trades.length; n++){
        if (props.trades[n].DATE === props.date){
            if (props.trades[n].Win){
                dayTotals.winCount++;
                dayTotals.totalGain += props.trades[n].PnL;
                dayTotals.sharesWin += props.trades[n].totalSize;
                winCount++;
                largestWinner = (props.trades[n].PnL >= largestWinner.PnL  ? props.trades[n] : largestWinner);
                winDuration += props.trades[n].duration;
            } else {
                dayTotals.lossCount++;
                dayTotals.totalLoss += props.trades[n].PnL;
                dayTotals.sharesLoss += props.trades[n].totalSize;
                lossCount++;
                largestLoser = (props.trades[n].PnL <= largestLoser.PnL  ? props.trades[n] : largestLoser)
                lossDuration += props.trades[n].duration;
            }
        }
    }
    let avgWinDuration = winDuration/winCount;
    let avgWinDurationStr = isNaN(avgWinDuration) ? "-" : msToTime(avgWinDuration)
    let avgLossDuration = lossDuration/lossCount;
    let avgLossDurationStr = isNaN(avgLossDuration) ? "-" : msToTime(avgLossDuration)

    dayTotals.totalShares = dayTotals.sharesWin + dayTotals.sharesLoss;
    dayTotals.PnL = Math.round(((dayTotals.totalGain + dayTotals.totalLoss) + Number.EPSILON) * 100) / 100;;
    
    // if (i>0){
    //     dayTotals.cumPnL = dayTotals.PnL + allDays[i-1].cumPnL;
    // } else {
    //     dayTotals.cumPnL = dayTotals.PnL;
    // }
    
    dayTotals.winRate = (dayTotals.winCount/(dayTotals.winCount+dayTotals.lossCount)*100);
    dayTotals.winRate = Math.round((dayTotals.winRate + Number.EPSILON) * 1) / 1;
    dayTotals.avgWinner = Math.round((dayTotals.totalGain/dayTotals.winCount + Number.EPSILON) * 100) / 100;
    dayTotals.avgLoser = Math.round((dayTotals.totalLoss/dayTotals.lossCount + Number.EPSILON) * 100) / 100;
    dayTotals.profitFactor = dayTotals.avgWinner/dayTotals.avgLoser*-1;
    dayTotals.profitFactor = Math.round((dayTotals.profitFactor + Number.EPSILON) * 100) / 100;
    dayTotals.profitFactor = isNaN(dayTotals.profitFactor) ? "\u221e" : (dayTotals.profitFactor);
    dayTotals.totalGain = Math.round((dayTotals.totalGain + Number.EPSILON) * 100) / 100;
    dayTotals.totalLoss = Math.round((dayTotals.totalLoss + Number.EPSILON) * 100) / 100;

    var posWinRate = dayTotals.winRate > 50 ? "statField ps-1 win me-1" : "statField ps-1 loss me-1";
    var posProfFact = ((dayTotals.profitFactor > 1)||(isNaN(dayTotals.profitFactor)&&dayTotals.PnL>0)) ? "statField ps-1 win me-1" : "statField ps-1 loss me-1";

    //console.log(dayTotals.sharesWin)
 
    let avgSize = Math.round(dayTotals.totalShares/(dayTotals.winCount+dayTotals.lossCount + Number.EPSILON) * 1) / 1;
    let totalPnL = Math.round((dayTotals.totalGain + dayTotals.totalLoss + Number.EPSILON) * 100) / 100;
    let perShareGain = Math.round(totalPnL/(dayTotals.sharesWin + dayTotals.sharesLoss) * 10000) / 10000;

    let profitMargin = (dayTotals.totalGain+dayTotals.totalLoss)/dayTotals.totalGain
    profitMargin = profitMargin*100
    let profMarginClass = ((profitMargin > 35) ? ("dashStatField win") : ("dashStatField loss"))

    let winStatus=""
    if (totalPnL>0) {
        winStatus = "win pnl statField ps-1";
    } else {
        winStatus = "loss pnl statField ps-1";
    }

    //console.log(dayTotals)

    return (
        <div className="row" id="singleStatArea">
            <div className="row rounded1" id="statsHeader">
                <div className="col">
                    <span>Statistics</span>
                </div>
            </div>
            <div className="row">
                <div className="col-12" id="statArea">
                    <div className="row">
                        <div className="col-6">Profit / Loss:</div>
                        <div className="col-6" >
                            <p className={winStatus}>${totalPnL}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">Shares Traded:</div>
                        <div className="col-6" >
                            <p className="statField ps-1">{(dayTotals.sharesWin + dayTotals.sharesLoss).toLocaleString()}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">Per Share Gain:</div>
                        <div className="col-6" >
                            <p className="statField ps-1">${perShareGain}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">Trade Count:</div>
                        <div className="col-6" >
                            <p className="statField ps-1">{dayTotals.winCount + dayTotals.lossCount}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">Avg Trade Size:</div>
                        <div className="col-6" >
                            <p className="statField ps-1">{(avgSize).toLocaleString()} shares</p>
                        </div>
                    </div>
                        <div className="row">
                            <div className="col-6">Win Rate:</div>
                            <div className="col-6" >
                                <p className={posWinRate}>{dayTotals.winRate}%</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">Profit Factor:</div>
                            <div className="col-6" >
                                <p className={posProfFact}>{dayTotals.profitFactor} : 1</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">Profit Margin:</div>
                            <div className="col-6" >
                                <p className={profMarginClass}>{profitMargin.toFixed(0)}%</p>
                            </div>
                        </div>
                    <div className="row">
                        <div className="col-6">Average Winner:</div>
                        <div className="col-6" >
                            <p className="statField ps-1 avgwin">${(dayTotals.avgWinner).toFixed(2)}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">Average Loser:</div>
                        <div className="col-6" >
                            <p className="statField ps-1 avgloss">${(dayTotals.avgLoser).toFixed(2)}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">Avg Win Duration:</div>
                        <div className="col-6">
                            <p className="statField ps-1">{avgWinDurationStr}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">Avg Loss Duration:</div>
                        <div className="col-6">
                            <p className="statField ps-1">{avgLossDurationStr}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">Largest Win:</div>
                        <div className="col-6" >
                                <p className="avgwin statField ps-1">
                                    ${(largestWinner.PnL).toFixed(2)} <span className="largestLink">({largestWinner.TIME})</span>
                                </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">Largest Loss:</div>
                            <div className="col-6" >
                            <p className="avgloss statField ps-1">
                                ${(largestLoser.PnL).toFixed(2)} <span className="largestLink">({largestLoser.TIME})</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
function msToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    hrs = (hrs === 0 ? "" : (hrs + " hours "))
    //mins = mins.toString().padStart(2, '0')
    mins = (mins === 0 ? "" : (mins + " minutes "))
    // secs = secs.toString().padStart(2, '0') + " seconds"
    secs = (secs === 0 ? "" : (secs + " seconds"))
  
    return hrs + '' + mins + '' + secs;
  }