import { Dropdown } from 'rsuite';
import { Link, NavLink } from 'react-router-dom';

export default function UserMenu (props) {
    const logOut = props.logOut;


    const user = props.user;
    //console.log(user)
    if (user[0]=="SniperLog Demo"){
        return(
            <section id="demoNav">
                <span>DEMO</span>
                <button className="btn btn-sm btn-primary" onClick={user[2]}>
                    Exit
                </button>
            </section>
        )
    } else {
 return (
    <div className="dropdown">
        <Dropdown 
            title={user[0]}
            size="xs"
            appearance="primary"
            // autoClose={true}
            >
            <NavLink to="Settings" className="dropdown">
                <Dropdown.Item><span>Settings</span></Dropdown.Item>
            </NavLink>
            <Dropdown.Item onClick={()=>{user[2]()}}><span>Logout</span></Dropdown.Item>
            {/* <Dropdown.Item>Download As...</Dropdown.Item>
            <Dropdown.Item>Export PDF</Dropdown.Item>
            <Dropdown.Item>Export HTML</Dropdown.Item>
            <Dropdown.Item>Settings</Dropdown.Item>
            <Dropdown.Item>About</Dropdown.Item> */}
        </Dropdown>
    </div>
    )
        }
}