import React, { useContext, useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom'
import { useAsync } from "react-async"
import { restClient } from "@polygon.io/client-js";
// import CandlestickChart from "./components/CandlestickChart"
import CandleStickChart from './components/CandlestickChart';
import Async from "react-async";
//import trades from './json/trades.json'
import TradeList from './components/TradeList'
import IntradayChart from './components/IntradayChart';
import { IntradayTotals } from './components/IntradayTotals';
import SingleStats from './components/SingleStats';
import serverURL from './config/serverConfig';
import { userContext } from './contexts/userContext';
import Spinner from './components/Spinner';
import { Link } from 'react-router-dom';
import JournalEntry from './components/JournalEntry';


const rest = restClient("L66LbQA96zxy9U4wfgnaioDqmebQkObd", "https://api.polygon.io");
var loaded = false;
//var trades = [];


var reqURL = "";
var loadCandles = (props) => 
  fetch(reqURL)
    .then(res => (res.ok ? res : Promise.reject(res)))
    .then(res => res.json());

const SingleStock = (props) => {

  const [trades, loadTrades] = useState([])
  const [tradeTags, setTradeTags] = useState([])

  let loadError = false;
  var csData = [];
  var volData = [];
  var vwapData = [];

  const userData = useContext(userContext);
  const user = userData[1];
  const subscription = userData[4]
  console.log(subscription)

  const [timezone, setTimezone] = useState(-5);
  const [daylightSavings, setDaylightSavings] = useState(false);

  useEffect(()=>{
    fetch(serverURL + "users/" + user + "/settings.json")
      .then(response => response.json())
      .then((jsonData) => {
        if (jsonData.timezone!=undefined){
          setTimezone(jsonData.timezone);
          setDaylightSavings(jsonData.daylightSavings)
        }
        //console.log(trades.length + " fetched")
      })
      .catch((error) => {
        console.error("No settings file found - " + error)
      })
  },[])

  useEffect(()=>{
    fetch(serverURL + "json/" + user + "/tradeTags.json")
    .then(response => response.json())
    .then((jsonData) => {
      //console.log(jsonData)
      tradeTags = jsonData;
    })
    .catch((error) => {
      //console.error("No tag file found - " + error)
    })
  },[])

  useEffect(()=>{
    fetch(serverURL + "json/" + user + "/trades.json")
      .then(response => response.json())
      .then((jsonData) => {
        loadTrades(jsonData);
        //console.log(trades.length + " fetched")
      })
      .catch((error) => {
        console.error(error)
      })
  },[])



  var stock = "CNSP"
  var day = "2023-06-02"
  var dayF = "6/2/23"

  let backLoc = ""

  const location = useLocation()

  if (location.state!=null){
    stock = location.state.stock;
    dayF = location.state.day;
    backLoc = location.state.back;
    let dayParse = dayF.split("/");
    let dayYear = "20" + dayParse[2];
    let dayMonth = (dayParse[0].length==1) ? "0"+dayParse[0] : dayParse[0];
    let dayDay = (dayParse[1].length==1) ? "0"+dayParse[1] : dayParse[1];
    day = dayYear+"-"+dayMonth+"-"+dayDay
  }

  reqURL = "https://api.polygon.io/v2/aggs/ticker/"+stock+"/range/1/minute/"+day+"/"+day+"?adjusted=true&sort=asc&limit=720&apiKey=L66LbQA96zxy9U4wfgnaioDqmebQkObd";
  //console.log(reqURL)
  let loaderString = `<span class="loader"></span>`
  const { data, error, isPending } = useAsync({ promiseFn: loadCandles, userId: 1 })
      if (isPending) return (<Spinner />)
      if (error) loadError = true;
      if (data)

  var filtMarkers = []

  // for (var i=0;i<trades.length;i++){
  //   if (trades[i].DATE==dayF && trades[i].SYMBOL==stock){
  //       var dateParse = (trades[i].DATE).split("/");
  //       var dateYear = "20" + dateParse[2];
  //       var dateMonth = dateParse[0]-1;
  //       var timeParse = (trades[i].TIME).split(":")
  //       var markTime = new Date(dateYear, dateMonth, dateParse[1], timeParse[0], timeParse[1], timeParse[2] );
  //           markTime = ((markTime.getTime())/1000)-25260; //an additional minute behind for proper marking
  //       if (trades[i].PnL>0){
  //               var markPos = "aboveBar";
  //               var markColor = "green";
  //               var markShape = "arrowUp"
  //               var markText = "B";
  //           } else {
  //               var markPos = "belowBar";
  //               var markColor = "red";
  //               var markShape = "arrowDown"
  //               var markText = "S";
  //           }
  //       let newTrade = {
  //           time: markTime,
  //           position: "belowBar",
  //           color: markColor,
  //           shape: markShape,
  //       }
  //       filtMarkers.push(newTrade);
  //   }
  // }
  //console.log(filtMarkers)

  //console.log(data)
  var tradeData = []
  var timezoneOffset = (3600*timezone) + ((daylightSavings==true)?(3600):(0));
  //console.log(timezoneOffset)
  for (var i=0;i<trades.length;i++){
    if (trades[i].DATE==dayF && trades[i].SYMBOL==stock) {
      var dateParse = (trades[i].DATE).split("/");
      var dateYear = "20" + dateParse[2];
      var dateMonth = dateParse[0]-1;
      var timeParse = (trades[i].TIME).split(":")
      var markTime = new Date(dateYear, dateMonth, dateParse[1], timeParse[0], timeParse[1], timeParse[2] );
          markTime = ((markTime.getTime())/1000)+timezoneOffset

      let newTrade = {
        time: markTime,
        open: trades[i].avgCost,
        low: trades[i].avgCost,
        close: trades[i].avgSell,
        high: trades[i].avgSell,
      }
      tradeData.push(newTrade);
    }
  }

  if ((loadError == false)&&(data.resultsCount != 0)){
    for (var i = 0; i<data.results.length; i++) { 
      let curTime = (data.results[i].t/1000)+timezoneOffset
      let newCandle = {
        time: curTime,
        open: data.results[i].o,
        high: data.results[i].h,
        low: data.results[i].l,
        close: data.results[i].c,
        volume: data.results[i].v,
      }

      let varColor = "rgba(239, 83, 80, 0.5)";
      if (data.results[i].o<data.results[i].c){
        varColor = "rgba(38, 166, 154, 0.5)"
      }
      let newVolBar = {
        time: curTime,
        value: data.results[i].v,
        color: varColor,
      }

      let newVWAPplot = {
        time: curTime,
        value: data.results[i].a,
      }
      csData.push(newCandle);
      volData.push(newVolBar);
      vwapData.push(newVWAPplot)
    }
  }

  const filtTradeList = [];
  for (let i = 0; i < trades.length; i++) {
    if (trades[i].SYMBOL == stock){
      trades[i].dayPnL = trades[i].runPnL;
      filtTradeList.push(trades[i]);
    } else {}
  }

  //console.log(csData);
  //console.log(filtTradeList)
  //console.log(loadError)
  if (trades.length == 0){
    return <Spinner />
  } else {

    console.log(backLoc)

    return (
      <div className="row" id="singleStock">
        <div className="col-12 col-lg-6" id="singleLeft">
          <div className="row">
            <div className="chartArea" id="intradayChart">
              <div id="singleStockHeader">
              { (backLoc==undefined)?
              <div id='buttonBar'>
                <Link
                  to={"/Intraday"}
                  state={{ date: dayF}}
                  className="calLink"
                  id="symbolsBack">
                  <button className="">&#10094; {dayF}</button>
                </Link>
                {(subscription!="essentials"&&subscription!="none")?
                <Link
                  to={"/SingleLifetime"}
                  state={{ stock: stock}}
                  className="calLink"
                  id="symbolsBack">
                  <button className="">&#10094; {stock} ALL DAYS</button>
                </Link> :""
                }
                </div>
                :
                <div id="buttonBar">
                  <Link
                    to={"/Intraday"}
                    state={{ date: dayF}}
                    className="calLink"
                    id="symbolsBack">
                    <button className="">&#10094; {dayF}</button>
                  </Link>
                  {(subscription!="essentials"&&subscription!="none")?
                  <Link
                    to={"/SingleLifetime"}
                    state={{ stock: backLoc}}
                    className="calLink"
                    id="symbolsBack">
                    <button className="">&#10094; {backLoc} ALL DAYS</button>
                  </Link> : ""
                  }
                </div>
              }
                <span>{stock}</span>
                {/* <button className='invisible'>Back</button> */}
              </div>
              <IntradayChart date={dayF} trades={filtTradeList}/>
            </div>
          </div>
          <div className='row'>
            {
              (
                ((loadError==true) || (csData.length == 0))?
                  <p id="candlestickChartError">Candlestick data not available</p>
                  :
                  (
                    (vwapData.length==0)?
                      <Spinner />
                      :
                      <CandleStickChart candleData={csData} volumeData={volData}  markers={filtMarkers} vwapData={vwapData} tradeData={tradeData} title={stock+" - "+dayF}/>
                  )
              )
            }
          </div>
          <div className='row'>
            <JournalEntry date={dayF} symbol={stock}/>
          </div>
        </div>
        <div className="col-12 col-lg-6" id="singleRight">
          <div className="row">
            <SingleStats trades={filtTradeList} date={dayF} />
          </div>
          <div className="row singleStockList">
            <TradeList trades={filtTradeList} date={dayF} tags={tradeTags}/>
          </div>
        </div>
      </div>
    )
  }
}

export { SingleStock }

