import './App.css';
import React from 'react'//, { useRef, useState, useEffect } from 'react';
import {Routes, Route, Link} from 'react-router-dom';
import NavBar from "./components/NavBar"
import Intraday from "./Intraday"
import Lifetime from './Lifetime';
import Dashboard from './Dashboard';
import Upload from './Upload';
import { SingleStock } from './SingleStock';
import { useState, useRef, useEffect } from 'react';
import { userContext } from './contexts/userContext';
import { CookiesProvider, useCookies } from 'react-cookie';
import serverURL from './config/serverConfig';
import { GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import Checkout from './components/Checkout';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { APP_SECRET, CLIENT_ID } from './config/paypalConfig';
import PayPalBtn from './components/PayPalBtn';
import base64 from 'base-64'
import Settings from './Settings';
import Spinner from './components/Spinner';
import PlanSelect from './components/PlanSelect';
import Footer from './components/Footer';
import FooterLinks from './components/FooterLinks';
import Stats from './Stats';
import Symbols from './Symbols';
import background from './assets/Hexagon_.svg'
import SingleLifetime from './SingleLifetime';


function App(props) {

  const [selectedUser, selectUser] = useState('');

  const [cookies, setCookie, removeCookie] = useCookies(["user", "userID", "userEmail"]);
  const [gUser, setGUser] = useState('none');
  const [ profile, setProfile ] = useState(null);
  const [subscription, setSubscription] = useState();
  const [subStatus, setSubStatus] = useState();
  const [subDetails, setSubDetails] = useState();
  const [planID, setPlanID] = useState("P-1VH35195B70443343MVNZWUI")
  let subID = "";

  const handleUser = (e) => {
    selectUser(e.target.value);
    e.preventDefault();
  }

  /////**** GOOGLE LOGIN *****////

  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
      console.log(error);
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setGUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });
  const logOut = () => {
    googleLogout();
    setProfile(null);
    // setCookie("user", 'none');
    removeCookie('userID');
    removeCookie('user');
    removeCookie('userEmail');
  };

  useEffect(() => {
    if (gUser && (gUser != 'none')) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${gUser.access_token}`, {
          headers: {
            Authorization: `Bearer ${gUser.access_token}`,
            Accept: 'application/json'
          }
        })
        .then((res) => {
          setProfile(res.data);
          console.log(res.data)
          setCookie("userID", res.data.id)
          setCookie("user", res.data.name)
          setCookie("userEmail", res.data.email)
        })
        .catch((err) => console.log("Failed to Login - " + err));
    }
  },[ gUser ]);
  //console.log(profile)
  //console.log(cookies)

  useEffect (()=> {
    //console.log("User: "+cookies.user + " - ID: "+cookies.userID + " - Email: "+ cookies.userEmail)
    console.log("User: "+cookies.user + " - Email: "+ cookies.userEmail)
    if (cookies.userID != undefined){
      //console.log(serverURL + "users/" + cookies.userID + "/subscription.json")
      fetch(serverURL + "users/" + cookies.userID + "/subscription.json")
      .then(response => response.json())
      .then((jsonData) => {
        // jsonData is parsed json object received from url
        //console.log(jsonData)
        setSubscription(jsonData);
        //console.log(subscription)
      })
      .catch((error) => {
        // handle your errors here
        console.error(error)
        console.log(subscription)
        setSubStatus(false)
      })
    }
  },[cookies.userID])

  // useEffect(() => {
  //   if (subscription != undefined){
  //     //subID = (subscription != undefined) ? subscription.subscriptionID : "";
  //     subID = subscription.subscriptionID;
  //     //console.log(subID)
  //     let authString = CLIENT_ID + ":" + APP_SECRET
  //     let auth = base64.encode(authString)
  //     //console.log("looking up subscription status")

  //     fetch('https://api.paypal.com/v1/billing/subscriptions/' + subID, {
  //         headers: {
  //             'Content-Type': 'application/json',
  //             'Authorization': "Basic " + auth,
  //             'Accept': 'application/json'
  //         }
  //       })
  //       .then(response => response.json())
  //           .then((jsonData) => {
  //             // jsonData is parsed json object received from url
  //             let results = jsonData;
              
  //             setSubDetails(results)
  //             if (results.status == 'ACTIVE'){
  //               console.log("active subscription found")
  //               setSubStatus(true)
  //             } else {
  //             console.log("previous subscription found " + results.status)
  //             if (results.status == 'CANCELLED'||'EXPIRED'){
  //               setPlanID("P-282623381F7821901MVOPMFQ")
  //             }
  //             setSubStatus(false)
  //             }
  //           })
  //           .catch((error) => {
  //             // handle your errors here
  //             //console.error(error)
  //           });
  //   } else if (subscription == undefined){
  //     //setSubStatus(false)
  //   }
  // },[subscription])


  //console.log(subDetails)

  useEffect(() => {
    if (subscription != undefined){
      //subID = (subscription != undefined) ? subscription.subscriptionID : "";
      //subID = subscription.subscriptionID;
      //console.log(subID)
      // let authString = CLIENT_ID + ":" + APP_SECRET
      // let auth = base64.encode(authString)
      //console.log("looking up subscription status")

      // if (subscription.status == "active"){
      //   setSubStatus(true)
      // }

      fetch(serverURL+"getSub", {
        // mode: 'cors',
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            },
        body: JSON.stringify({
            content: subscription.subID,
            name: "userID"
        }),
            //body: jsonTradeList,
            //body: "",
        })
        .then((response) => response.json())
        .then((data) => {
          console.log("Subscription status: "+ data.status)
          //console.log(data)
            switch (data.status){
              case "active" :
                setSubStatus(true)
                break;
              case "trialing" :
                setSubStatus(true)
                break;
              default:
                setSubStatus(false)
            }
            //console.log(data.plan.id)
            switch (data.plan.id){
              case "price_1OhfX7BTRowfEvpL5FAPVgJI":
              case "price_1OhfX7BTRowfEvpLtvJyMPEa":
                setSubDetails("professional")
                console.log("Subscription plan: Professional")
                break;
              case "price_1ODY7KBTRowfEvpLxluB6t2R":
              case "price_1OUx8MBTRowfEvpL3dyTofqV":
              case "price_1OhiYcBTRowfEvpLyXSG3iRZ":
                setSubDetails("essentials")
                console.log("Subscription plan: Essentials")
                break;
              default:
                setSubDetails("none")
            }
        });
    } else if (subscription == undefined){
      //setSubStatus(false)
    }
  },[subscription])

  const loadDemo = () => {
    console.log("loading demo account")
    setCookie("userID", "110194700664376100000")
    setCookie("user", "SniperLog Demo")
  }

  let paypal = <PayPalBtn planID={planID} user={cookies.userID} newSub={setSubStatus}/>
  //console.log(subStatus)

  //console.log(profile)
  return (
    <div>
        {
          (cookies.user=="none"||cookies.user==undefined) ? 
            (
              <div className="preApp2">
                    <div id="preAppHeader">
                      <section id="headerLogo">
                        <p>
                          <span className="logoSplash">SNIPER LOG</span>
                          <img className="logo" src={require('./assets/logoWhite.png')} />
                        </p>
                        <p id="caption">
                          a scalper's trading journal
                        </p>
                      </section>
                      <section id="preAppNav">
                          <a href="#pricing">PRICING</a>
                          <a onClick={loadDemo}>PREVIEW</a>
                          <a href = "mailto: hello@sniperlog.net">CONTACT</a>

                        <button onClick={() => login()}>
                          <img src={require("./assets/google.png")}/>
                          Sign in with Google
                        </button>
                      </section>
                    </div>
                    <div id="landingAbout">
                      <h4>Unlock Your Trading Potential with Sniper Log - A Scalper's Trading Journal</h4>
                      <img src={require("./assets/sniperLook4.png")}/>
                    </div>
                    <div id="preAppDetails">
                      <section id="left">
                        <div><h3>&#x270E;</h3><p>Track and analyze every trade with precision</p></div>
                        <div><h3>&#x2617;</h3><p>Uncover hidden patterns and trends</p></div>
                        <div><h3>&#x27A5;</h3><p>Optimize your scalp trading strategy</p></div>
                        <div><h3>&#x2708;</h3><p>Embrace the Journey of Scalp Based Trading</p></div>
                        <div><h3>&#x2605;</h3><p>Achieve consistent profitability</p></div>
                        <div><h3></h3><p><i>All without the fluff and fuss</i></p></div>
                      </section>
                      <section id="right">
                        <h3>Why Sniper Log?</h3>
                        <p>
                          In the fast-paced world of day trading, success hinges on making informed decisions based on real-time market dynamics. 
                          Welcome to Sniper Log, the essential companion for scalpers who demand precision and thrive in the fast-paced world of quick, accurate trades.
                        </p>
                      </section>
                    </div>
                    <div id="pricing">
                      <h3>Pricing</h3>
                      <p><span onClick={() => login()}>Get started</span> today with a 2-week free trial!</p>
                      <div id="plans">
                        <div className="singlePlan" onClick={() => login()}>
                          <h5>Essentials Plan</h5>
                          <h5><span>as low as </span>$5.85<span> / month*</span></h5>
                          <caption>*with a yearly subscription</caption>
                          <ul>
                            <li>Unlimited trades</li>
                            <li>Intraday statistics for account and individual symbols</li>
                            <li>Lifetime statistics</li>
                            <li>Date Filtering</li>
                            <li>Monthly & yearly calendar views</li>
                            <li>Stocks, ETFs, Indices</li>
                            <li>Trade tagging</li>
                            <li><i>Supported Platforms: ThinkOrSwim</i></li>
                          </ul>
                        </div>

                        <div className="singlePlan" onClick={() => login()}>
                          <h5>Professional Plan</h5>
                          <h5><span>as low as </span>$8.35<span> / month*</span></h5>
                          <caption>*with a yearly subscription</caption>
                          <ul>
                            <li>Unlimited trades</li>
                            <li>Intraday statistics for account and individual symbols</li>
                            <li>Lifetime statistics</li>
                            <li>Date Filtering</li>
                            <li>Monthly & yearly calendar views</li>
                            <li>Stocks, ETFs, Indices</li>
                            <li>Trade tagging </li>
                            <li>Tag statistics</li>
                            <li>Trade List Exporting</li>
                            <li>P/L by Symbols Page</li>
                            <li><i>Supported Platforms: ThinkOrSwim</i></li>
                          </ul>
                        </div>
                        
                      </div>
                    </div>
                    <div id="preAppBottom">
                      <h3>
                        Experience the power of a dedicated scalping journal and elevate your scalping game with Sniper Log.
                      </h3>
                    </div>
              </div>
            ) : (
              (subStatus != false) ? 
                (
                  (subStatus == true) ? 
                    (
                      <userContext.Provider value={[cookies.user, cookies.userID, logOut, cookies.userEmail, subDetails]}>
                        <div className="App">
                          <div className="mainContainer">
                            <header>
                              <div className="row">
                                <div className="navBar">
                                  <NavBar page={"lifetime"} />
                                </div>
                              </div>
                            </header>
                            <main>
                              <Routes>
                                {/* <Route path="/" element={ <Intraday /> } /> */}
                                <Route path="/" element={ <Dashboard /> } />
                                <Route path="Intraday" element={ <Intraday /> } /> 
                                <Route path="Dashboard" element={ <Dashboard /> } />
                                <Route path="lifetime" element={ <Lifetime /> } />
                                <Route path="SingleStock" element={ <SingleStock /> } />
                                <Route path="Stats" element={ <Stats /> } />
                                <Route path="Symbols" element={ <Symbols /> } />
                                <Route path="SingleLifetime" element={ <SingleLifetime /> } />
                                <Route path="Upload" element={ <Upload /> } />
                                <Route path="Settings" element={ <Settings /> } />
                                {/* <Route exact path='checkout/' index element={<Checkout />} /> */}
                              </Routes>
                            </main>
                              <Footer />
                            </div>
                          </div>
                      </userContext.Provider>
                    ) : (
                      <userContext.Provider value={[cookies.user, cookies.userID, logOut, cookies.userEmail, subDetails]}>
                    <div className="App">
                      <div className="mainContainer">
                        <header>
                          <div className="row">
                            <div className="navBar">
                              <NavBar page={"lifetime"} />
                            </div>
                          </div>
                        </header>
                        <main>
                          <Spinner />
                        </main>
                        <Footer />
                        </div>
                      </div>
                  </userContext.Provider>
                  )
                ) : (
                  <userContext.Provider value={[cookies.user, cookies.userID, logOut, cookies.userEmail]}>
                    <div className="App">
                      <div className="mainContainer">
                        <header>
                          <div className="row">
                            <div className="navBar">
                              <NavBar page={"lifetime"} />
                            </div>
                          </div>
                        </header>
                        <main>
                        {/* <div id="paypalButtons"> */}
                          {/* <PlanSelect planID={planID} setPlanID={setPlanID} freeSub={setSubStatus}/>
                          <section>
                            <PayPalBtn planID={planID} user={cookies.userID} newSub={setSubStatus}/>
                          </section> */}
                          <div id="stripeTable">
                          <p>Hi {cookies.user}, select a subscription plan to get started.</p>
                            <stripe-pricing-table 
                                pricing-table-id="prctbl_1OUxEEBTRowfEvpLCrmqXmZK"
                                publishable-key="pk_live_51ODXHyBTRowfEvpLtzXOSxPG5nPdDlZ1O7IVdDzCSVt7V1XBW8EHPMiYQ2OjxiE4ES3qbnEexp8K2pHy2Te3OwBg00xMgn4wMI"
                                //pricing-table-id="prctbl_1OVOMhBTRowfEvpLntjqpdia"
                                //publishable-key="pk_test_51ODXHyBTRowfEvpLjsDBdg1LL1aWVFup8fctzMfE0d9H5J4MVbxvR0qbRBEDnxdzsh8PBiow353MdNtLpQA96p0O00r8wkHuH0"
                                client-reference-id={cookies.userID}
                                customer-email={cookies.userEmail}
                              >
                            </stripe-pricing-table>
                          </div>
                        {/* </div> */}
                        </main>
                        <Footer />
                        </div>
                      </div>
                  </userContext.Provider>
                )
        )}
    </div>
  )
}



export default App;
