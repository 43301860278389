import { useEffect, useState } from "react";
import serverURL from '../config/serverConfig';
import base64 from 'base-64';
import { APP_SECRET, CLIENT_ID } from '../config/paypalConfig';
import Spinner from "./Spinner";
import { Link } from "react-router-dom";


export default function Subscription (props) {

    let user = props.user;
    let userIDFull = user[1];
    let userID = (userIDFull.toString()).slice(0, 8);
    const [subscription, setSubscription] = useState();
    const [subDetails, setSubDetails] = useState();
    const [cancelClicked, setCancelClicked] = useState(false)

    useEffect (()=> {
        //console.log(serverURL + "users/" + cookies.userID + "/subscription.json")
        fetch(serverURL + "users/" + user[1] + "/subscription.json")
        .then(response => response.json())
        .then((jsonData) => {
          // jsonData is parsed json object received from url
          //console.log(jsonData)
          setSubscription(jsonData);
          //console.log(subscription)
        })
        .catch((error) => {
          // handle your errors here
          console.error(error)
          console.log(subscription)
        })
    },[user])

    useEffect(() => {
        if (subscription != undefined){
          //subID = (subscription != undefined) ? subscription.subscriptionID : "";
          let subID = subscription.subscriptionID;
          //console.log(subID)
          let authString = CLIENT_ID + ":" + APP_SECRET
          let auth = base64.encode(authString)
          //console.log(auth)
    
          // fetch('https://api.paypal.com/v1/billing/subscriptions/' + subID, {
          //     headers: {
          //         'Content-Type': 'application/json',
          //         'Authorization': "Basic " + auth,
          //         'Accept': 'application/json'
          //     }
          //   })
          //   .then(response => response.json())
          //       .then((jsonData) => {
          //         // jsonData is parsed json object received from url
          //         let results = jsonData;
          //         //setTrades(jsonData);
          //         setSubDetails(results)
          //       //   if (results.status == 'ACTIVE'){
          //       //     setSubStatus(true)
          //       //   }
          //         //console.log(results)
          //       })
          //       .catch((error) => {
          //         // handle your errors here
          //         //console.error(error)
          //       });
          fetch(serverURL+"getSub", {
            // mode: 'cors',
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                },
            body: JSON.stringify({
                content: subscription.subID,
                name: "userID"
            }),
                //body: jsonTradeList,
                //body: "",
            })
            .then((response) => response.json())
            .then((data) => {
              //console.log(data)
              setSubDetails(data)
                // switch (data.status){
                //   case "active" :
                //     setSubStatus(true)
                //     break;
                //   case "trialing" :
                //     setSubStatus(true)
                //     break;
                //   default:
                //     setSubStatus(false)
                // }
            });
    
        }
      },[subscription])

      //console.log(subDetails)

      //let renewalDate = subDetails.billing_info.next_billing_time;
      

      const cancel = async () =>  {
        let subID = subscription.subscriptionID;
        let authString = CLIENT_ID + ":" + APP_SECRET
        let auth = base64.encode(authString)
        

        fetch('https://api.paypal.com/v1/billing/subscriptions/' + subID + "/cancel", {
          method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Basic " + auth,
                'Accept': 'application/json'
            }
          })
          .then(response => response.json())
            .then((jsonData) => {
              // jsonData is parsed json object received from url
              let results = jsonData;
              //setTrades(jsonData);
            //   setSubDetails(results)
            //   if (results.status == 'ACTIVE'){
            //     setSubStatus(true)
            //   }
              console.log(results)

            })
            .catch((error) => {
              // handle your errors here
              //console.error(error)
            });
            user[2]();
      }

      const cancelFree = async () =>  {
        let subID = subscription.subscriptionID;
        let authString = CLIENT_ID + ":" + APP_SECRET
        let auth = base64.encode(authString)

        let subDetails = {
          "orderID": "0L731398V6603330J",
          "subscriptionID": "I-HT320V6S8DGJ",
          "facilitatorAccessToken": "A21AAPdLUk0nGWBJdFPziR0eFZVBCxzapVIXIkJoKxeRpn1se2G0RRiNQrqG8M0MDDVT03qt8267LVMO6xu_IBDLnD7y1WfpA",
          "paymentSource": "paypal"
        }

        fetch(serverURL+"subscribe", {
          // mode: 'cors',
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
              },
          body: JSON.stringify({
              name: user[1],
              content: subDetails
          }),
          //body: allDays,
          })
          .then((response) => response.json())
          // .then((response) => response)
          .then((data) => {
              //console.log(data);
              // setUploaded(true)
              // setFileStats([tradeList.length, newTradeCount])
          });
          user[2]();
      }

      let subPlan = ""
      let renewalDate = "";
      let createDate = "";
      let subStatus = "";
      let cancelFunc = cancel;

      if (subDetails != undefined) {
        switch (subDetails.plan.id){
            case "price_1OUx8MBTRowfEvpL3dyTofqV":
            case "price_1OhiYcBTRowfEvpLyXSG3iRZ":
                subPlan = "Essentials (Monthly)"
                break;
            case "price_1ODY7KBTRowfEvpLxluB6t2R":
              subPlan = "Essentials (Yearly)"
              break;
            case "price_1OhfX7BTRowfEvpL5FAPVgJI":
                subPlan = "Professional (Monthly)"
                break;
            case "price_1OhfX7BTRowfEvpLtvJyMPEa":
              subPlan = "Professional (Yearly)"
              break;
            case "P-5D182562JV1098238MVN2LCI":
              cancelFunc = cancelFree
              console.log("FREE")
              subPlan = "Essentials (Free)"
              break;
            case "P-282623381F7821901MVOPMFQ":
              subPlan = "Essentials (Monthly) - renewed"
              break;
            default:
                subPlan = "none";
                break;
        }
        // renewalDate = subDetails.billing_info.next_billing_time;
        renewalDate = subDetails.current_period_end;
        let rDate = new Date(renewalDate * 1000)
        renewalDate = rDate.toDateString();
        //renewalDate = renewalDate.split("T")

        // createDate = subDetails.create_time;
        createDate = subDetails.created;
        let cDate = new Date(createDate * 1000)
        createDate = cDate.toDateString();
        // createDate = createDate.split("T")

        subStatus = (subDetails.status=="trialing")?"Trial":subDetails.status;

    }

    

      let initCancel = (<button className="btn btn-danger btn-sm"onClick={()=>{setCancelClicked(true)}}>Cancel Subscription</button>)
      let confirmCancel = (
        <>
          <h4>You want to cancel your subscription?</h4>
          <button className="btn btn-secondary btn-sm"onClick={()=>{setCancelClicked(false)}}>Go Back</button>
          <button className="btn btn-danger btn-sm"onClick={cancelFunc}>Cancel Subscription</button>
        </>
      )
      let goodbye = (<p>We're sad to see you go!</p>)

    return (
        (subDetails== undefined) ? (<Spinner />) : (
        <div id="subDetails">
          {/* <h3>{user[0]}</h3> */}
            <ol>
                <li><span>Subscription: </span>{subPlan}</li>
                <li><span>Status: </span>{subStatus}</li>
                <li><span>User ID: </span>{userID}</li>
                {(subPlan=="Essentials (Free)")?"":<li><span>Subscribed Since: </span>{createDate}</li>}
                {(subPlan=="Essentials (Free)")?"":<li><span>Renewal Date: </span>{renewalDate}</li>}
                {/* {(subPlan=="Essentials (Free)")?"":<li><span>Subscription Email: </span>{subDetails.subscriber.email_address}</li>} */}
                {(subPlan=="Essentials (Free)")?"":<li><span>Email: </span>{user[3]}</li>}
                {/* <li><button className="btn btn-danger btn-sm"onClick={cancel}>Cancel Subscription</button></li> */}
                {/* {(user[0]=="SniperLog Demo")?"":(<li>{(cancelClicked ? confirmCancel : initCancel)}</li>)} */}
                {(user[0]=="SniperLog Demo")?"":(<Link to="https://billing.stripe.com/p/login/6oEaHXdRx3KAcYU5kk" target="_blank"><button className="btn btn-sm btn-primary">Manage Subscription</button></Link>)}
            </ol>
        </div>
        )
    )
}