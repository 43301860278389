import { Link } from "react-router-dom"

export default function YearEntry (props) {

    if (props.date === undefined){
        return (
        <li className={props.classStr}></li>
        )
    } else {
    return (

            <li className={props.classStr}>
                <Link className="yearLink calLink"
                to={"/Intraday"} 
                state={{ date: props.date}}>
                <span>&nbsp;</span>
                </Link>
            </li>

    )
    }
}