//Accepts trade and daily data and returns trade stats formatted in yearly view calendar
// 

import DayEntry from './DayEntry'
import YearEntry from './YearEntry';
import React, { useRef, useEffect, useState } from 'react';

export default function TradeCalendarYearly (props){

    const setView = props.setView;
    const setMonth = props.setMonth;
    var allDays = props.allDays;
    var lastTradeDate = (allDays[(allDays.length)-1].date).split("/");

    // const [currMonthNum, setCurrMonthNum] = useState(Number(lastTradeDate[0])) //use this for lastTradeDate
    const [currYear, setYear] = useState(Number(lastTradeDate[2])+2000)

    useEffect(()=>{
        //console.log(lastTradeDate)
        setYear(Number(lastTradeDate[2])+2000);
    },[props.allDays])


    const navBack = () => {
        setYear(currYear-1)
    }

    const navFwd = () => {
        setYear(currYear+1)
    }
    // const daysList = [];
    let yearGain = 0;

    // while(dayOneMonth == calMonth){
    const getDayOfWeek = (dayNum) => {
    let dayOfWeek = "";
        switch(dayNum){
            case 0:
                dayOfWeek = "sunday";
                break;
            case 1:
                dayOfWeek = "monday";
                break;
            case 2:
                dayOfWeek = "tuesday";
                break;
            case 3:
                dayOfWeek = "wednesday"; 
                break;
            case 4:
                dayOfWeek = "thursday";  
                break;
            case 5:
                dayOfWeek = "friday";
                break;
            case 6:
                dayOfWeek = "saturday";
                break;
            default:
        }
        return dayOfWeek;
    }

    //parse daily data into monthly chunks
    let yearData = []
    for (let i=1; i <= 12; i++){

        let n = 30;
        if (i === 1||i === 3||i === 5||i === 7||i === 8||i === 10||i === 12){
            n = 31;
        } else if (i === 2){
            //check for leap year here
            n = 28;
        } else {
        }

        let monthData = [];
        for (let x = 1; x <= n; x++){

            let currDate = new Date(i + "/" + x + "/" + currYear);
            let currDOWnum = currDate.getDay();
            let dayOfWeekStr = getDayOfWeek(currDOWnum)

            let day = <YearEntry classStr={dayOfWeekStr} key={currDate} />

            for (let z=0; z<allDays.length; z++){
                let dateParse = (allDays[z].date).split("/")
                if ((Number(dateParse[0])==i) && (Number(dateParse[1])==x) && (Number(dateParse[2])==(currYear-2000))){
                    //day = allDays[z]
                    yearGain += allDays[z].PnL;
                    let winStatus = ((allDays[z].PnL>0) ? "win" : "loss")
                    let classStr = winStatus + " " + allDays[z].dateDOW
                    //day = <div className={classStr}></div>
                    day = <YearEntry classStr={classStr} date={allDays[z].date} key={allDays[z].date}/>
                    break;
                }
            }

            monthData.push(day)
        }
        yearData.push(monthData)
    }
    //console.log(yearData);

    return (
        <div className="calendarYearly">
            <div className="row">
                <div className="col" id="calNav">
                    <span id="monthBack">
                        <button className="btn btn-primary btn-sm" onClick={navBack}>&larr;</button>
                    </span>
                    <span id="monthLabel">{currYear}</span>
                    <span id="monthFwd">
                        <button className="btn btn-primary btn-sm" onClick={navFwd}>&rarr;</button>
                    </span>
                </div>
            </div>
            <div className="row" id="monthTotals">
                <p><span>Total {currYear} P/L: ${(yearGain).toLocaleString()}</span></p>
            </div>
            <ol className="calendarYear">
                {/* <li className="day-name">Sun</li><li className="day-name">Mon</li><li className="day-name">Tue</li><li className="day-name">Wed</li><li className="day-name">Thu</li><li className="day-name">Fri</li><li className="day-name">Sat</li> */}
                {/* {daysList} */}
                <div className='month'>
                    <p onClick={()=>{
                        setView(false)
                        setMonth([1,currYear])
                    }}>January</p>
                    <div className='monthData'>
                        {yearData[0]}
                    </div>
                </div>
                <div className='month'>
                <p onClick={()=>{
                        setView(false)
                        setMonth([2,currYear])
                    }}>February</p>
                    <div className='monthData'>
                        {yearData[1]}
                    </div>
                </div>
                <div className='month'>
                <p onClick={()=>{
                        setView(false)
                        setMonth([3,currYear])
                    }}>March</p>
                    <div className='monthData'>
                        {yearData[2]}
                    </div>
                </div>
                <div className='month'>
                <p onClick={()=>{
                        setView(false)
                        setMonth([4,currYear])
                    }}>April</p>
                    <div className='monthData'>
                        {yearData[3]}
                    </div>
                </div>
                <div className='month'>
                <p onClick={()=>{
                        setView(false)
                        setMonth([5,currYear])
                    }}>May</p>
                    <div className='monthData'>
                        {yearData[4]}
                    </div>
                </div>
                <div className='month'>
                <p onClick={()=>{
                        setView(false)
                        setMonth([6,currYear])
                    }}>June</p>
                    <div className='monthData'>
                    {yearData[5]}
                    </div>
                </div>
                <div className='month'>
                <p onClick={()=>{
                        setView(false)
                        setMonth([7,currYear])
                    }}>July</p>
                    <div className='monthData'>
                    {yearData[6]}
                    </div>
                </div>
                <div className='month'>
                <p onClick={()=>{
                        setView(false)
                        setMonth([8,currYear])
                    }}>August</p>
                    <div className='monthData'>
                    {yearData[7]}
                    </div>
                </div>
                <div className='month'>
                <p onClick={()=>{
                        setView(false)
                        setMonth([9,currYear])
                    }}>September</p>
                    <div className='monthData'>
                    {yearData[8]}
                    </div>
                </div>
                <div className='month'>
                <p onClick={()=>{
                        setView(false)
                        setMonth([10,currYear])
                    }}>October</p>
                    <div className='monthData'>
                    {yearData[9]}
                    </div>
                </div>
                <div className='month'>
                <p onClick={()=>{
                        setView(false)
                        setMonth([11,currYear])
                    }}>November</p>
                    <div className='monthData'>
                    {yearData[10]}
                    </div>
                </div>
                <div className='month'>
                <p onClick={()=>{
                        setView(false)
                        setMonth([12,currYear])
                    }}>December</p>
                    <div className='monthData'>
                        {yearData[11]}
                    </div>
                </div>
            </ol>
        </div>
    )
}
