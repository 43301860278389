/* Accepts trade object with Win, TIME, SYMBOL, tradeSize, PnL properties. Return DIV of trade entry*/
import {Routes, Route, Link} from 'react-router-dom';
export default function TradeEntry (props) {

    //console.log(props.tag)
    let classTag = (props.tag==undefined)?"":"tagBubble"

    let tagString = props.tag
    // if (tagString.id!=undefined){
    //     // console.log(tagString.id)
    // }
    try{
        tagString.props.children.props.selected = true
    console.log(tagString.props.children.props.selected)
    } catch {}

    if (props.trade.Win==true) {
        var winStatus = "win";
    } else {
        var winStatus = "loss";
    }

    if (props.trade.QTY < 0){
        var direction = "L"
        var entry = props.trade.entry;
        var exit = props.trade.exit;
    } else {
        var direction = "S"
        var entry = props.trade.exit;
        var exit = props.trade.entry;
    }

    let duration = msToTime(props.trade.duration);
    let fnc = props.trade.totalFees + props.trade.totalComms;
    fnc = ((fnc==0)||isNaN(fnc)) ? ("-") : ("$"+fnc.toFixed(2))

    return (
        <div className="tradeEntry tradeAreaRow">
            <div id="tradeTime">{props.trade.TIME}</div>
            <div id="tradeDir">{direction}</div>
            <div id="tradeSymbol" > {props.trade.SYMBOL}</div>
            <div id="tradeShares">{props.trade.totalSize}</div>
            <div id="tradeEE">
                <div className="entryExit">
                    <div className="col-5">
                        ${entry} 
                    </div>
                    <div className="col-2">&#8594;</div>
                    <div className="col-5">
                        ${exit}
                    </div>
                </div>
            </div>
            <div  className="duration" id="duration">{duration}</div>
            <div id="tradePnL" className={winStatus}>${props.trade.PnL}</div>
            <div className='fncList'>{fnc}</div>
            {((props.tag==undefined || props.tag=="")||(props.editing==true))?
                <div id="tag">{props.tag}</div>
                    :
                <div id="tag" className="tagBubble">

                    <Link 
                        to={"/Stats"}
                        state= {{
                            tag: props.tag,
                        }} 
                        className="symbolLink">
                                              <span>&#9751;</span>{props.tag}
                        </Link>
                </div>}
        </div>
    )
}

function msToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    hrs = hrs.toString().padStart(2, '0')
    mins = mins.toString().padStart(2, '0')
    secs = secs.toString().padStart(2, '0')
  
    return hrs + ':' + mins + ':' + secs;
  }