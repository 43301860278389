import { useState, useEffect, useContext } from "react";
import { Link, useLocation } from 'react-router-dom'
import { userContext } from './contexts/userContext';
import serverURL from './config/serverConfig';
import Welcome from './components/Welcome';
import Spinner from './components/Spinner';
import TradeCalendarYearly from "./components/TradeCalendarYearly";
import UpgradePrompt from "./components/Upgrade";

export default function SingleLifetime (props) {


    const userData = useContext(userContext);
    const user = userData[1];
    const subscription = userData[4]

    const [trades, setTrades] = useState()
    const [allDays, setAllDays] = useState()
    const [tradesLoaded, setTradesLoaded] = useState(false)
    const [daysLoaded, setDaysLoaded] = useState(false)
    
    const location = useLocation()
    let stock = ""
    if (location.state!=null){
      stock = location.state.stock;
      // dayF = location.state.day;
      // let dayParse = dayF.split("/");
      // let dayYear = "20" + dayParse[2];
      // let dayMonth = (dayParse[0].length==1) ? "0"+dayParse[0] : dayParse[0];
      // let dayDay = (dayParse[1].length==1) ? "0"+dayParse[1] : dayParse[1];
      // day = dayYear+"-"+dayMonth+"-"+dayDay
    }

    useEffect(()=>{
        fetch(serverURL + "json/" + user + "/trades.json")
          .then(response => response.json())
          .then((jsonData) => {
            //console.log(jsonData)
            setTrades(jsonData);
            setTradesLoaded(true)
          })
          .catch((error) => {
            console.error(error)
            setTrades("none")
            setTradesLoaded(true)
          })

          fetch(serverURL + "json/" + user + "/allDays.json")
          .then(response => response.json())
          .then((jsonData) => {
            //console.log(jsonData)
            setAllDays(jsonData);
            setDaysLoaded(true)
          })
          .catch((error) => {
            console.error(error)
            setAllDays("none")
            setDaysLoaded(true)
          })
    },[])

    if (subscription == "essentials"){
        return <UpgradePrompt />
    } else {
      if ((tradesLoaded == true)&& (daysLoaded == true)){
          if (trades=="none"){
              return <Welcome name={userData[0]}/>
          } else {
              /////////////////////////////// Main Logic Here ///////////////////////////////

            let filtList = []
            for (let i in trades){
              if (trades[i].SYMBOL == stock){
                filtList.push(trades[i])
              }
            }


              ///////////////////////////////////////////////////////////////////////////////
              return (
                <>
                  <Link to={"/Symbols"} className="calLink" id="symbolsBack">
                      <button className="singlePage">&#10094; ALL SYMBOLS</button>
                  </Link>

                  {/* {stock} {filtList.length} trades */}
                  <div id="singleLifetimePage">
                    <div id="top">
                      <div>
                        <DaysList trades={filtList} />
                      </div>
                      <div>
                        <Stats trades={filtList} />
                      </div>
                    </div>
                    <div id="bottom">
                      <SingleCalendar trades={filtList} />
                    </div>
                  </div>
                </>
              )
          }

      } else {
          return <Spinner />
      }
    }
}

function DaysList (props) {
  let trades = props.trades
  const daysTraded = [...new Set(trades.map((item) => item.DATE))]

  let daysEntries = [];
  let symbolGain = 0;

  for (let i in daysTraded){
    let date = daysTraded[i];
    let shares = 0;
    let wins = 0;
    let losses = 0;
    let totalGain = 0;
    let totalLoss = 0;

    for (let x in trades){
      if (trades[x].DATE == date){
        shares += trades[x].totalSize
        if (trades[x].PnL>0){
          wins++
          totalGain += trades[x].PnL
        } else {
          losses++
          totalLoss += trades[x].PnL
        }
      }
    }

    let pnlClass = ((totalGain+totalLoss)>0) ? "win" : "loss"
    let winRateClass = (((wins/(wins+losses)*100))>49.99) ? "lightGreen" : "lightRed"
    let profFactorClass = (((totalGain/totalLoss)*-1)>1) ? "lightGreen" : "lightRed"

    symbolGain += (totalGain+totalLoss)
    daysEntries.push(
      <li key={date}>
        <div>
        <Link 
            to={"/SingleStock"}
            state= {{
                stock: trades[0].SYMBOL,
                day: date,
                back: trades[0].SYMBOL,
            }} 
            className="symbolLink">
              {date}
          </Link>
        </div>
        <div className={pnlClass}>${(totalGain+totalLoss).toFixed(2)}</div>
        <div>{wins+losses}</div>
        <div>{shares.toLocaleString()}</div>
        <div className={winRateClass}>{(wins/(wins+losses)*100).toFixed(2)}%</div>
        <div className={profFactorClass}>{(totalGain/totalLoss).toFixed(2)*-1}</div>
      </li>
    )
  }

  let gainClass = (symbolGain>0)?"green":"red"
  let gainArrow = (symbolGain>0)? "\u2197" :"\u2198"

  return (
    <>
    <h4 id="singleLifetimeHeader">{trades[0].SYMBOL}:<span className={gainClass}> {gainArrow}${symbolGain.toLocaleString()}</span></h4>
      <ul id="singleLifetimeList">
        <li id="listHeader">
          <div>Date</div>
          <div>P/L</div>
          <div>Trade Count</div>
          <div>Shares</div>
          <div>Win Rate</div>
          <div>P/L Ratio</div>
        </li>
        {daysEntries}
      </ul>
    </>
  )
}

function Stats (props) {
  let trades = props.trades

  const daysTraded = [...new Set(trades.map((item) => item.DATE))]

  let dayCount = daysTraded.length
  let totalPnL = 0;
  let totalGain = 0;
  let totalLoss = 0;
  let winCount = 0;
  let lossCount = 0;
  let shares = 0;
  let totalWinDuration = 0;
  let totalLossDuration = 0;
  let totalFnC = 0
  let largestWin = 0;
  let largestLoss = 0;
  let largestWinTrade = {}
  let largestLossTrade = {}

  for (let i=0; i<trades.length;i++){
    let trade = trades[i]
    //console.log(trade)
    totalFnC += (trade.totalComms + trade.totalFees)
    shares += trade.totalSize

    if (trade.PnL > 0){ 
      totalGain += trade.PnL
      winCount++
      largestWinTrade = (trade.PnL>largestWin)? trade : largestWinTrade
      largestWin = (trade.PnL>largestWin)? trade.PnL : largestWin
      
      totalWinDuration += trade.duration

    } else {
      totalLoss += trade.PnL
      lossCount++
      largestLossTrade = (trade.PnL<largestLoss)? trade : largestLossTrade
      largestLoss = (trade.PnL<largestLoss)? trade.PnL : largestLoss
      
      totalLossDuration += trade.duration

    }
  }
  let avgWinDuration = msToTime(totalWinDuration/winCount)
  let avgLossDuration = msToTime(totalLossDuration/lossCount)

  let pnlClass = (totalGain+totalLoss) > 0 ? "statField win" : "statField loss";
  let winRateClass = (winCount*100/(winCount+lossCount)) > 50 ? "statField win" : "statField loss";
  let profFactClass = (totalGain/totalLoss*-1) > 1 ? "statField win" : "statField loss";

  let profitMargin = (totalGain+totalLoss)/totalGain
  profitMargin = profitMargin*100
  let profMarginClass = ((profitMargin > 35) ? ("statField win") : ("statField loss"))

  console.log(largestWinTrade)

  return (
    <>
  
      <div id="symbolStats">
        <h5>Statistics</h5>
        <div><span>Profit / Loss: </span><span className={pnlClass}>${(totalGain+totalLoss).toFixed(2)}</span></div>
        <div><span>Total Fees / Commissions:</span><span>${(totalFnC).toFixed(2)}</span></div>
        <div><span>Trade Count: </span><span> {trades.length}</span></div>
        <div><span>Win Rate: </span><span className={winRateClass}>{(winCount*100/(winCount+lossCount)).toFixed(2)}%</span></div>
        <div><span>P/L Ratio: </span><span className={profFactClass}>{(totalGain/totalLoss*-1).toFixed(2)} : 1</span></div>
        <div><span>Profit Margin: </span><span className={profMarginClass}>{profitMargin.toFixed(0)}%</span></div>
        <div><span>Average Daily P/L: </span><span>${((totalGain+totalLoss)/dayCount).toFixed(2)}</span></div>
        <div><span>Average Daily Volume: </span><span>{(shares/dayCount).toLocaleString()} shares</span></div>
        <div><span>Average Winner: </span><span className="avgwin">${(totalGain/winCount).toFixed(2)}</span></div>
        <div><span>Average Loser: </span><span className="avgloss">${(totalLoss/lossCount).toFixed(2)}</span></div>
        <div>
          <span>Largest Win: </span>
          <span className="green">
          <Link 
            to={"/SingleStock"}
            state= {{
                stock: trades[0].SYMBOL,
                day: largestWinTrade.DATE,
            }} 
            className="avgwin statField">
            ${largestWin} <span className="largestLink">({largestWinTrade.DATE}-{largestWinTrade.TIME})</span>
            </Link>
          </span>
        </div>
        <div>
          <span>Largest Loss: </span>
          <span className="red">
          <Link 
            to={"/SingleStock"}
            state= {{
                stock: trades[0].SYMBOL,
                day: largestLossTrade.DATE,
            }} 
            className="avgloss statField">
            ${largestLoss} <span className="largestLink">({largestLossTrade.DATE}-{largestLossTrade.TIME})</span>
            </Link>
          </span>
        </div>
        <div><span>Avg Win Duration: </span><span>{avgWinDuration}</span></div>
        <div><span>Avg Loss Duration: </span><span>{avgLossDuration}</span></div>
      </div>
    </>
  )

}

function SingleCalendar (props) {
  let trades = props.trades
  const daysTraded = [...new Set(trades.map((item) => item.DATE))]

  let singleDaySet = {}
  for (let i=0; i<daysTraded.length; i++){
    let newDay = {
      date: daysTraded[i],
      PnL: 0
    }
    singleDaySet[daysTraded[i]] = newDay
  }

  for (let i = 0; i<trades.length; i++){
    singleDaySet[trades[i].DATE].PnL += trades[i].PnL
  }

  //console.log(singleDaySet)
  let singleDayList = []
  for (let i in singleDaySet){
   singleDayList.push(singleDaySet[i]) 
  }
  singleDayList.sort((a,b) => new Date(a.date) >  new Date(b.date) ? 1 : (( new Date(b.date) >  new Date(a.date)) ? -1 : 0))

  //console.log(singleDayList)
  return (
    <TradeCalendarYearly allDays={singleDayList} />
  )
}

function msToTime(s) {
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;

  hrs = (hrs == 0 ? "" : (hrs + "hours"))
  //mins = mins.toString().padStart(2, '0')
  mins = (mins == 0 ? "" : (mins + " minutes"))
  // secs = secs.toString().padStart(2, '0') + " seconds"
  secs = (secs == 0 ? "" : (secs + " seconds"))

  return hrs + ' ' + mins + ' ' + secs;
}