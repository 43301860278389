export const About = (
    <article id="floatingText">
        <section id="logoHeader">
            <h2 id="logo">Sniper Log</h2>
            <img id="logoImg" src={require('../assets/logoWhite.png')} />
        </section>
        <h4>Unlock Your Trading Potential with Sniper Log - A Scalper's Trading Journal</h4>
        <p>
            In the fast-paced world of day trading, success hinges on making informed decisions based on real-time market dynamics. 
            Welcome to Sniper Log, the essential companion for scalpers who demand precision and thrive in the fast-paced world of quick, accurate trades. 
            </p>
            <p>Our journal empowers you to capture and analyze every trade you execute, providing a comprehensive overview of your trading performance. By tracking key metrics such as entry and exit points, profit/loss, and time spent in trades, you gain valuable insights into your strengths, weaknesses, and areas for improvement.
        </p>
        <section className="imgRow">
        <span className="imgContainer"><img id="chartImg" src={require('../assets/chart.png')} /></span>
        <span className="imgContainerR"><img id="chartImgCalMon" src={require('../assets/monthlyCal.png')} /></span>
        </section>
        {/* <h4>
            Tailored for Scalping Success
        </h4>
        <p>
            Our scalp trading-specific features provide a tailored experience that aligns with the unique demands of this short-term trading strategy. Track your performance on a tick-by-tick basis, identify optimal entry and exit points, and gain a deeper understanding of market trends and patterns.
        </p> */}
        <h4>
            Transform Your Trading Habits
        </h4>
        <p>
            Sniper Log not only serves as a record of your trading history but also acts as a catalyst for positive change. By reviewing your past performance, you can identify recurring patterns and make informed decisions that align with your trading goals.
        </p>
            <section className="imgRow">
                <span className="imgContainerR"><img id="chartImgStats" src={require('../assets/stats.png')} /></span>
                <span className="imgContainerR"><img id="chartImgCal" src={require('../assets/yearlyCal.png')} /></span>
            </section>
        <h4>
            Why Sniper Log?
        </h4>
        <section>
            Our comprehensive journal empowers you to:
        <ul>
            <li>Track and analyze every trade with precision</li>
            {/* <li>Identify optimal entry and exit points</li> */}
            {/* <li>Uncover hidden patterns and trends</li> */}
            <li>Optimize your scalp trading strategy</li>
            <li>Achieve consistent profitability</li>
            <li>Embrace the Journey of Scalp Based Trading</li>
            <li><i>All without the fluff and fuss</i></li>
        </ul>
        </section>
        <p>
            Experience the power of a dedicated scalping journal. Elevate your scalping game with Sniper Log—where speed meets profitability.
        </p>
        <h4>Contact Us</h4>
        <p className="centered"><em>hello@sniperlog.net</em></p>
    </article>
)