import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "./Spinner";
export default function IntradayStats(props){

    let date = props.date
    const singleDay = props.allDays.filter(i => (i.date.includes(date))); //filter out trade list for selected Date
    // console.log(props)
    // console.log(date)
    // console.log(singleDay)
    // console.log(singleDay[0])


    let largestWinner = {"PnL": 0};
    let largestLoser = {"PnL": 0};
    let winDuration = 0;
    let lossDuration = 0;
    let winCount = 0;
    let lossCount = 0;
    let totalGain = 0;
    let totalLoss = 0;

    for (let i=0; i<props.trades.length; i++){
        if (date==props.trades[i].DATE){
            //console.log(props.trades[i])
            if (props.trades[i].PnL>0){
                winCount++;
                largestWinner = (props.trades[i].PnL >= largestWinner.PnL  ? props.trades[i] : largestWinner);
                winDuration += props.trades[i].duration;
                totalGain += props.trades[i].PnL
            } else {
                lossCount++;
                largestLoser = (props.trades[i].PnL <= largestLoser.PnL  ? props.trades[i] : largestLoser)
                lossDuration += props.trades[i].duration;
                totalLoss += props.trades[i].PnL
            }

            // totalFees += props.trades[i].totalFees
            //console.log(totalFees + typeof(totalFees))
            // totalComms += props.trades[i].totalComms
        }
    }
    let avgWinDuration = winDuration/winCount;
    let avgWinDurationStr = isNaN(avgWinDuration) ? "-" : msToTime(avgWinDuration)
    let avgLossDuration = lossDuration/lossCount;
    let avgLossDurationStr = isNaN(avgLossDuration) ? "-" : msToTime(avgLossDuration)







    // if (singleDay[0]==undefined){
    if (singleDay[0]==undefined){
        //console.log("Not available");
        return (<p></p>)
    } else {
        if (singleDay[0]==undefined){ //singleDay[0]==undefined
            return <Spinner />
        }
        else {
        var avgSize = singleDay[0].totalShares/(singleDay[0].winCount+singleDay[0].lossCount)
        avgSize = (Math.round((avgSize + Number.EPSILON) * 1) / 1).toLocaleString();
        var tradeCount = singleDay[0].winCount+singleDay[0].lossCount;
        var posWinRate = singleDay[0].winRate > 50 ? "statField ps-1 win me-1" : "statField ps-1 loss me-1";
        var posProfFact = ((singleDay[0].profitFactor > 1)||((singleDay[0].profitFactor)==null)&&singleDay[0].PnL>0) ? "statField ps-1 win me-1" : "statField ps-1 loss me-1";

        let avgWinner = ((singleDay[0].avgWinner)==null)? "-" : (singleDay[0].avgWinner).toFixed(2);
        let avgLoser = ((singleDay[0].avgLoser)==null) ? "-" : (singleDay[0].avgLoser).toFixed(2);
        let profitFactor = ((singleDay[0].profitFactor)==null) ? "\u221e" : (singleDay[0].profitFactor); // this is actually P/L Ratio

        let profitMargin = (totalGain+totalLoss)/(totalGain)
        profitMargin = profitMargin*100
        let profMarginClass = ((profitMargin > 35) ? ("ps-1 me-1 statField win") : ("ps-1 me-1 statField loss"))

        return (
            <>
                <div className="row" id="statsHeader"><span>Statistics</span></div>
                <div className="row">
                    <div className="col" id="statArea">
                        <div className="row"><div className="col-6">Trade Count:</div><div className="col-6" ><p className="statField ps-1">{tradeCount}</p></div></div>
                        <div className="row"><div className="col-6">Avg Trade Size:</div><div className="col-6" ><p className="statField ps-1">{avgSize} shares</p></div></div>
                        <div className="row"><div className="col-6">Win Rate:</div><div className="col-6" ><p className={posWinRate}>{singleDay[0].winRate}%</p></div></div>
                        <div className="row"><div className="col-6">Profit / Loss Ratio:</div><div className="col-6" ><p className={posProfFact}>{profitFactor} : 1</p></div></div>
                        <div className="row"><div className="col-6">Profit Margin:</div><div className="col-6" ><p className={profMarginClass}>{profitMargin.toFixed(0)}%</p></div></div>
                        {/* <div className="row"><div className="col-6">Average Winner:</div><div className="col-6" ><p className="avgwin statField ps-1">${(singleDay[0].avgWinner).toFixed(2)}</p></div></div> */}
                        <div className="row"><div className="col-6">Average Winner:</div><div className="col-6" ><p className="avgwin statField ps-1">${avgWinner}</p></div></div>
                        {/* <div className="row"><div className="col-6">Average Loser:</div><div className="col-6" ><p className="avgloss statField ps-1">${(singleDay[0].avgLoser).toFixed(2)}</p></div></div> */}
                        <div className="row"><div className="col-6">Average Loser:</div><div className="col-6" ><p className="avgloss statField ps-1">${avgLoser}</p></div></div>
                        <div className="row"><div className="col-6">Avg Win Duration:</div><div className="col-6"><p className="statField ps-1">{avgWinDurationStr}</p></div></div>
                        <div className="row"><div className="col-6">Avg Loss Duration:</div><div className="col-6"><p className="statField ps-1">{avgLossDurationStr}</p></div></div>
                        <div className="row">
                            <div className="col-6">Largest Win:</div>
                            <div className="col-6" >
                                <Link 
                                to={"/SingleStock"} 
                                state={{ 
                                    stock: largestWinner.SYMBOL,
                                    day: largestWinner.DATE
                                }}
                                className="calLink">
                                    <p className="avgwin statField ps-1">
                                        ${largestWinner.PnL} <span className="largestLink">({largestWinner.SYMBOL} - {largestWinner.TIME})</span>
                                    </p>
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">Largest Loss:</div>
                                <div className="col-6" >
                                <Link 
                            to={"/SingleStock"} 
                            state={{
                                stock: largestLoser.SYMBOL,
                                day: largestLoser.DATE
                            }}
                            className="calLink">
                                <p className="avgloss statField ps-1 pb-2">
                                    ${largestLoser.PnL} <span className="largestLink">({largestLoser.SYMBOL} - {largestLoser.TIME})</span>
                                </p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </> 
            
        )
    }
}
}

function msToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    hrs = (hrs == 0 ? "" : (hrs + " hours "))
    //mins = mins.toString().padStart(2, '0')
    mins = (mins == 0 ? "" : (mins + " minutes "))
    // secs = secs.toString().padStart(2, '0') + " seconds"
    secs = (secs == 0 ? "" : (secs + " seconds"))
  
    return hrs + '' + mins + '' + secs;
  }