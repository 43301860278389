import { NavLink } from "react-router-dom";


export default function Welcome (props) {
    return (
        <article id="welcomePage">
          <p>Welcome {props.name}! Upload your trade history to get started. </p>
            <NavLink
                to="/Upload"
                className={({ isActive, isPending }) =>
                    isActive? "navLink active" : "navLink pending"
                }
            >
                <h4>Upload Now</h4>
            </NavLink>
            
        </article>
        
      )
}