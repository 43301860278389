import React, { createContext } from "react";

// export const userContext = createContext("none");
export const userContext = createContext({
    name:'',
    id:'',
    logout: () => {},
    email:'',
    plan:'',
});

