import { useEffect, useState, useContext } from "react"
import { userContext } from '../contexts/userContext';
import serverURL from '../config/serverConfig';
import { BtnBold, BtnItalic, Editor, EditorProvider, Toolbar, createButton, BtnBulletList, BtnLink, BtnNumberedList, BtnUnderline, BtnUndo, BtnRedo } from "react-simple-wysiwyg";
import DOMPurify from "dompurify";
import Spinner from "./Spinner";

export default function JournalEntry (props) {
    
    const symbol = props.symbol
    const date = props.date
    const journalType = (props.symbol==undefined)?(["/journalEntries_daily.json","journalDaily"]):(["/journalEntries_single.json","journalSingle"])

    const userData = useContext(userContext);
    const user = userData[1];
    
    const [entryText, setEntryText] = useState()
    const [loadedEntries, loadEntries] = useState([])
    const [entryIndex, setEntryIndex] = useState(0);
    const [editing, setEditing] = useState(false)

    const BtnAlignCenter = createButton('Align center', '≡', 'justifyCenter');
    const BtnAlignRight = createButton('Align right', '>', 'justifyRight');
    const BtnAlignLeft = createButton('Align left', '<', 'justifyLeft');
    const BtnClearFormatting = createButton(
        'Clear formatting',
        'T̲ₓ',
        'removeFormat',
    );

    useEffect(()=>{
        // fetch(serverURL + "json/" + user + "/journalEntries_single.json")
        fetch(serverURL + "json/" + user + journalType[0])
      .then(response => response.json())
      .then((jsonData) => {
        loadEntries(jsonData);
      })
      .catch((error) => {
        // handle your errors here
        console.error("No notes file found - " + error)
        setEntryText("")
      })
    },[])

    useEffect(()=>{
        // console.log(loadedEntries.length + " notes fetched")
        let found = false;
        if (journalType[1]=="journalSingle"){
            for (let i=0; i<loadedEntries.length; i++){
                if (loadedEntries[i].DATE==date && loadedEntries[i].SYMBOL==symbol){
                    // console.log(loadedEntries[i].TEXT)
                    setEntryText(loadedEntries[i].TEXT)
                    setEntryIndex(i)
                    found = true;
                    break;
                }
                setEntryIndex(i+1)
            }
            if (found == false){
                // setEntryIndex(1)
                setEntryText("")
            }
        } else {
            for (let i=0; i<loadedEntries.length; i++){
                if (loadedEntries[i].DATE==date){
                    // console.log(loadedEntries[i].TEXT)
                    setEntryText(loadedEntries[i].TEXT)
                    setEntryIndex(i)
                    found = true;
                    break;
                }
                setEntryIndex(i+1)
            }
            if (found == false){
                // setEntryIndex(1)
                setEntryText("")
            }
        }

    },[loadedEntries])
    // console.log(entryIndex)

    const saveEntry = async (e) => {
        e.preventDefault();
        setEditing(false)
        let entry = {}
        if (journalType[1]=="journalSingle"){
            entry = {
                "DATE": date,
                "SYMBOL": symbol,
                "TEXT": entryText
            }
        } else {
            entry = {
                "DATE": date,
                "TEXT": entryText
            }
        }
        // console.log(entryText)
        // console.log(entryIndex)
        // console.log(loadedEntries.length)
        let newEntries = loadedEntries;
        if (entryIndex>=loadedEntries.length){
            // console.log(entryText)
            newEntries.push(entry)
        } else {
        for (let x=0; x<loadedEntries.length; x++){
            // console.log(loadedEntries.length)
                newEntries[entryIndex]=entry;
        }}
        console.log(newEntries)

        fetch(serverURL+journalType[1], {
            // mode: 'cors',
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                },
            body: JSON.stringify({
                content: newEntries,
                name: user
            }),
            //body: jsonTradeList,
            //body: "",
        })
        .then((response) => response.json())
        // .then((response) => response)
        .then((data) => {
            //console.log(data);
            //trades = jsonTradeList;
        });
    }

    const changeHandler = (e) => {
        setEntryText(e.target.value);
        //console.log(e.target.value)
    }


    let entryCode = <div id="savedEntry" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(entryText)}}></div>
    // console.log(entryText)
    return (
        (entryText==undefined) ? 
        (<Spinner />) : (
            <div id="journalSpace"><div id="entryHeader"><span>Notes: {symbol} - {date}</span>
                    <section className="journalButtons">
                        {(userData[0]=="SniperLog Demo")?
                            <>
                                <button disabled onClick={()=>{setEditing(true)}}>&#x270E;</button>
                                <button disabled onClick={saveEntry}>&#10003;</button>
                            </>
                        :
                            <>
                                <button disabled={editing} onClick={()=>{setEditing(true)}}>&#x270E;</button>
                                <button disabled={!editing} onClick={saveEntry}>&#10003;</button>
                            </>
                        }
                    </section>
            </div>
            <div id="journalEntry">
                {/*  */}
                {/* <label for="entryText">
                    <section>
                        <h5>Notes:</h5>
                        <span>V</span>
                        <span>X</span>
                    </section>
                    <input id="entryText
                        
                    " type="textfield" />
                </label> */}
                {/* {!editing ? (<div id="savedEntry">{`${entryText}`}</div>) : ( */}
                {!editing ? 
                    (entryCode) : 
                    (
                        <EditorProvider>
                            <Editor value={entryText} onChange={changeHandler}>
                                <Toolbar>
                                    <BtnBold />
                                    <BtnItalic />
                                    <BtnAlignLeft />
                                    <BtnAlignCenter />
                                    <BtnAlignRight />
                                    <BtnBulletList />
                                    <BtnClearFormatting />
                                    <BtnNumberedList />
                                    <BtnUnderline />
                                    <BtnLink />
                                    <BtnUndo />
                                    <BtnRedo />
                                </Toolbar>
                            </Editor>
                        </EditorProvider>
                    )
                }

            </div></div>
        )
    )
}