// components/LineChart.js
import React from "react";
import { Line } from "react-chartjs-2";
// import { useRef, useState } from 'react';
function LineChart(props) {

  const plugin ={
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart, args, options) => {
        const {ctx} = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = options.color || '#0d1d2b';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    }
    };

    let xMax = Math.max.apply(null,props.chartData.labels) + 180000
    //console.log(xMax)

    
  return (
    <div className="chart-container">
      <h5 style={{ textAlign: "center" }}>{props.title}</h5>
      <Line
        data={props.chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          parsing: true,
          stepped: "middle",
          lineBorderWidth: .5,
          // layout: {
          //     padding: {
          //         right: 50
          //     }
          //   },
          elements: {
            point:{
              radius:  0,
            },
            line:{
              borderWidth: 0,
              backgroundColor: "red",
              fill: false,
              tension: 0,
              cubicInterpolationMode: 'default'
            }
          },
          plugins: {
            title: {
              display: true,
            //   text: {props.symbol}
            },
            legend: {
              display: true
            },
          },
          scales: {
            x:{
              backgroundColor: "#0d1d2b",
              ticks: {
                color: "#eeeeee",
              },
              grid: {
                color: "#17324a"
              },
              type: 'time',
              time: {
                    unit: 'second'
                },
              suggestedMax: xMax
            },
            y: {
                  backgroundColor: "#0d1d2b",
                  ticks: {
                          // Include a dollar sign in the ticks
                    callback: function(value, index, ticks) {
                        // return '$' + value;
                        return '$' + Math.round((value + Number.EPSILON) * 100) / 100
                    },
                    color: "#eeeeee",    
                  },
                  grid: {
                    color: "#17324a"
                  }
                }

          },
          plugins: [plugin],
        }}
      />
    </div>
  );
}

function LineChartDaily(props) {

  const plugin ={
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart, args, options) => {
        const {ctx} = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = options.color || '#0d1d2b';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    }
    };
    
  return (
    <div className="chart-container">
      {/* <h5 style={{ textAlign: "center" }}>{props.title}</h5> */}
      <Line
        data={props.chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          parsing: true,
          stepped: false,
          lineBorderWidth: 0,
          elements: {
            point:{
              radius: 0,
            },
            line:{
              borderWidth: 0
            }
          },
          plugins: {
            title: {
              display: false,
            //   text: {props.symbol}
            }
          },
          scales: {
            x:{
              backgroundColor: "#0d1d2b",
              ticks: {
                color: "#eeeeee"
              },
              grid: {
                color: "#17324a"
              },
              // type: 'time',
              // time: {
              //       unit: 'day'
              //   },
            },
            y: {
                  backgroundColor: "#0d1d2b",
                  ticks: {
                          // Include a dollar sign in the ticks
                    callback: function(value, index, ticks) {
                        return '$' + value;
                    },
                    color: "#eeeeee",    
                  },
                  grid: {
                    color: "#17324a"
                  }
                }

          },
          plugins: [plugin],
        }}
      />
    </div>
  );
}
export { LineChart, LineChartDaily };