import {Routes, Route, Link} from 'react-router-dom';

export default function DayEntry (props){
    let singleDay = props.singleDay;
    let classStr = props.winStatus + " " + singleDay.dateDOW + " " + "calDay";

    //console.log(typeof(singleDay.date))
    let calDate = singleDay.date.split("/");
    if (singleDay.winCount == undefined) {
        return (
            <li className={classStr}>
                <Link 
                to={"/"} 
                state={{ date: singleDay.date}}
                className="calLink">
                    <div className="row calDate">
                        <div className="col-2">{calDate[1]}</div>
                    </div>
                    <div className="row">
                        {/* remove these */}
                        {/* <div className="col calPnL">${singleDay.PnL}</div> */}
                    </div>
                    <div className="row calStats">
                        {/* <div className="col-12 col-md-6 ml-3">{singleDay.winCount + singleDay.lossCount} trades</div>
                        <div className="col-12 col-md-6">Vol: {singleDay.totalShares} shares</div> */}
                    </div>
                </Link>
            </li>
        )
    } else {
        return (
            <li className={classStr}>
                <Link 
                to={"/Intraday"} 
                state={{ date: singleDay.date}}
                className="calLink">
                    <div className="row calDate">
                        <div className="col-2">{calDate[1]}</div>
                    </div>
                    <div className="row">
                        <div className="col calPnL">${(singleDay.PnL).toFixed(2)}</div>
                    </div>
                    <div className="row calStats">
                        <div className="col-12 col-md-6 ml-3">{singleDay.winCount + singleDay.lossCount} trades</div>
                        <div className="col-12 col-md-6 dayShares">{(singleDay.totalShares).toLocaleString()} shares</div>
                    </div>
                </Link>
            </li>
        )
    }
}