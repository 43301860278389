//Accepts daily trade data and returns trade stats formatted in monthly view calendar

import { all } from 'axios';
import DayEntry from './DayEntry'
import React, { useRef, useEffect, useState } from 'react';

export default function TradeCalendar (props){

    var allDays = props.allDays;
    //var allDays = (allDays == props.allDays) ? allDays : props.allDays;
    var lastTradeDate = (allDays[(allDays.length)-1].date).split("/");
    let clickedMonth = props.clickedMonth;
    const setClickedMonth = props.setClickedMonth;
    //console.log(clickedMonth)
    // const [currMonthNum, setCurrMonthNum] = useState(Number(lastTradeDate[0])) //use this for lastTradeDate
    // const [currYear, setYear] = useState(Number(lastTradeDate[2])+2000)
    let initMonth = (clickedMonth == 0) ? [Number(lastTradeDate[0]),Number(lastTradeDate[2])+2000] : clickedMonth

    const [currMonthArray, setMonthArray] = useState(initMonth);
    // console.log(currMonthArray)

    useEffect(()=>{
        //console.log(clickedMonth)
        if (clickedMonth === 0){
            setMonthArray([Number(lastTradeDate[0]),Number(lastTradeDate[2])+2000]);
        } else {
            setMonthArray(clickedMonth)
        }

    },[props.allDays])

    switch (currMonthArray[0]){
        case 1:
            var currMonth = "January";
            break;
        case 2:
            var currMonth = "February";
            break;
        case 3:
            var currMonth = "March";
            break;
        case 4:
            var currMonth = "April";
            break;
        case 5:
            var currMonth = "May";
            break;
        case 6:
            var currMonth = "June";
            break;
        case 7:
            var currMonth = "July";
            break;
        case 8:
            var currMonth = "August";
            break;
        case 9:
            var currMonth = "September";
            break;
        case 10:
            var currMonth = "October";
            break;
        case 11:
            var currMonth = "November";
            break;
        case 12:
            var currMonth = "December";
            break;
        default:
            var currMonth = "error"
    }

    const navBack = () => {
        if (currMonthArray[0]==1){
            setMonthArray([12,currMonthArray[1]-1])
        } else {
            setMonthArray([currMonthArray[0]-1,currMonthArray[1]])
        }
    }

    const navFwd = () => {
        if (currMonthArray[0]==12){
            setMonthArray([1,currMonthArray[1]+1])
        } else {
            setMonthArray([currMonthArray[0]+1,currMonthArray[1]]);
        }
    }

    const daysList = [];
    var monthGain = 0;

    let calDay = new Date(currMonthArray[1], currMonthArray[0]-1, 1);
    let dayOneMonth = calDay.getMonth();
    let calMonth = dayOneMonth;
    //console.log(dayOneMonth, calMonth)

    while(dayOneMonth == calMonth){
        let dayOfWeek = "";
        switch(calDay.getDay()){
            case 0:
                dayOfWeek = "sunday";
                break;
            case 1:
                dayOfWeek = "monday";
                break;
            case 2:
                dayOfWeek = "tuesday";
                break;
            case 3:
                dayOfWeek = "wednesday";
                break;
            case 4:
                dayOfWeek = "thursday";
                break;
            case 5:
                dayOfWeek = "friday";
                break;
            case 6:
                dayOfWeek = "saturday";
                break;
            default:
        }

        let calEntry = {}
        let match = false;
        let winStatus = ""
        for (let i = 0; i < allDays.length; i++) {
            var currDay = (allDays[i].date).split("/");
            var currDate = new Date (20+currDay[2], currDay[0]-1, currDay[1], 0, 0, 0);
            if (currDate.getTime()==calDay.getTime()){
                calEntry = allDays[i]
                winStatus = ((allDays[i].PnL>0) ? "win" : "loss")
                match = true;
                //console.log("matched!")
                monthGain += allDays[i].PnL;
            }
        }
        monthGain = Math.round((monthGain + Number.EPSILON) * 100) / 100;
        if (match==false){
            let printDate = calDay.getDate().toString();
            let printMonth = (calDay.getMonth()+1).toString();
            let printYear = calDay.getFullYear().toString()
            calEntry = {
                date: printMonth+"/"+printDate+"/"+printYear,
                dateDOW: dayOfWeek
            };
        }
        daysList.push(
            <DayEntry singleDay={calEntry} winStatus={winStatus} key={calDay}/>
        )
        // console.log(dayOne)
        calDay.setDate(calDay.getDate() +1);
        calMonth = calDay.getMonth();
    }
    // console.log(daysList)

    return (
        <div id="tradeCalendar">
            <div className="row">
                <div className="col" id="calNav">
                    <span id="monthBack">
                        <button className="btn btn-primary btn-sm" onClick={navBack}>&larr;</button>
                    </span>
                    <span id="monthLabel">{currMonth} {currMonthArray[1]}</span>
                    <span id="monthFwd">
                        <button className="btn btn-primary btn-sm" onClick={navFwd}>&rarr;</button>
                    </span>
                </div>
            </div>
            <div className="row" id="monthTotals">
                <p><span>Total Month P/L: ${(monthGain).toFixed(2)}</span></p>
            </div>
            <ol className="calendar">
                <li className="day-name">Sun</li><li className="day-name">Mon</li><li className="day-name">Tue</li><li className="day-name">Wed</li><li className="day-name">Thu</li><li className="day-name">Fri</li><li className="day-name">Sat</li>
                {daysList}
            </ol>
        </div>
    )
}
