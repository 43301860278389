import { useState } from "react";
import { TOS } from "./TOS";
import { About } from "./About";

export default function FooterLinks () {


    const [tosVisibility, setTosVisibility] = useState("floating closed");
    const [aboutVisibility, setAboutVisibility] = useState("floating closed");
    
 
    const popupCloseHandler = (e) => {
      setTosVisibility("floating closed");
    }; 
 return (
    <div id="footerLinks">
        <span>
            <p className="footerLink" onClick={()=>{
                setAboutVisibility("floating")
                setTosVisibility("floating closed")
            }}> About</p>
            <div className={aboutVisibility} >
                <section id="floatingTop">
                    <div className="popClose" onClick={()=>{setAboutVisibility("floating closed")}}>X</div>
                </section>
                {About}
            </div>
        </span>
        <span>
            <p className="footerLink" onClick={()=>{
                setTosVisibility("floating")
                setAboutVisibility("floating closed")
            }}> Terms of Service</p>
            <div className={tosVisibility} >
                <section id="floatingTop">
                    <div className="popClose" onClick={popupCloseHandler}>X</div>
                </section>
                {TOS}
            </div>
        </span>
    </div>
 )
}