import React, { useContext, useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js/auto';
import { Bar, Pie, Doughnut } from 'react-chartjs-2';

function TagBarChart (props) {

    // console.log(props.winRates)

    let stat = props.stat;
    let statName = props.statName
    let label = props.label

    const chartData = {
        labels: stat.map((data) => data.tag),
        datasets: [{
            label: label,
            data: stat.map((data) => data[statName])
        }]
    }

    return (
        <div className='tagChartContainer'>
        <h5>{label}</h5>
            <Bar
                data={chartData}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
            x:{
              backgroundColor: "#0d1d2b",
            //   ticks: {
            //     color: "#eeeeee"
            //   },
              grid: {
                color: "#17324a"
              },
            },
            y: {
                  backgroundColor: "#0d1d2b",
                //   ticks: {
                //           // Include a dollar sign in the ticks
                //     callback: function(value, index, ticks) {
                //         return '$' + (value.toFixed(2));
                //     },
                //     // color: "#eeeeee",
                //   },
                //   grid: {
                //     color: "#17324a"
                //   }
                }

          },
                }}
            />
        </div>
    )
}

function TagHiLowBarChart (props) {
    let stat = props.stat;
    let labelH = props.labelH;
    let labelL = props.labelL;
    let high = props.high;
    let low = props.low;

    const chartData = {
        labels: stat.map((data) => data.tag),
        datasets: [{
            label: labelH,
            data: stat.map((data) => data[high])
        },
        {
            label: labelL,
            data: stat.map((data) => data[low])
        }]
    }

    return (
        <div className="tagChartContainer">
        <h5>{labelH} / {labelL}</h5>
            <Bar
                data={chartData}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x:{
                        backgroundColor: "#0d1d2b",
                        //   ticks: {
                        //     color: "#eeeeee"
                        //   },
                        grid: {
                            color: "#17324a"
                        },
                        },
                        y: {
                            backgroundColor: "#0d1d2b",
                            ticks: {
                                    // Include a dollar sign in the ticks
                                callback: function(value, index, ticks) {
                                    return '$' + (value.toFixed(2));
                                },
                                // color: "#eeeeee",
                            },
                            //   grid: {
                            //     color: "#17324a"
                            //   }
                            }

                    },
                    
                }}
            />
        </div>
    )
}

function TagPieChart (props) {

    let PnLs = props.stat;
    let stat = props.statName;
    let label = props.label;
    const chartData = {
        labels: PnLs.map((data) => data.tag),
        datasets: [{
            label: label,
            data: PnLs.map((data) => data[stat])
        }]
    }

    return (
        <div className='tagChartContainer'>
            <h5>{label}</h5>
            <Doughnut
                data={chartData}
                options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    
                }}
            />
        </div>
    )
}

export { TagBarChart, TagPieChart, TagHiLowBarChart }