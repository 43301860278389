import React, { useContext, useRef, useState } from 'react';
import { useEffect } from 'react';
import Papa from "papaparse";
//import trades from './json/trades.json';
import BrowserFS from 'browserfs'
import { saveAs } from 'file-saver';
//import allDays from './json/allDays.json'
import serverURL from './config/serverConfig';
import { userContext } from './contexts/userContext';
import Spinner from './components/Spinner';

//var trades = [];
var positions = [];

export default function Upload () {

    const userData = useContext(userContext);
    const user = userData[1];

    useEffect(()=>{   //load trades
        fetch(serverURL + "json/" + user + "/trades.json")
          .then(response => response.json())
          .then((jsonData) => {
            //trades = jsonData;
            setTrades(jsonData);
            //console.log(trades.length + " fetched")
          })
          .catch((error) => {
            //console.error(error)
          })
        },[])
        //console.log(trades)

    useEffect(()=>{   /// load positions
        fetch(serverURL + "json/" + user + "/positions.json")
            .then(response => response.json())
            .then((jsonData) => {
                positions = jsonData;
                //console.log(positions.length + " open positions fetched")
                //console.log(positions)
            })
            .catch((error) => {
                //console.error(error)
                console.log("no positions loaded")
            })
    },[])
    //console.log(positions)

    const [trades, setTrades] = useState([])

    const [selectedFile, setSelectedFile] = useState();
    const [broker, setBroker] = useState();

    const [isDAS, setDAS] = useState(false);
    const [dasDate, setDASdate] = useState()

    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploaded, setUploaded] = useState(false);

    const [isUploadedT, setUploadedT] = useState(false);
    const [isUploadedP, setUploadedP] = useState(false);
    const [submitted, setSubmitted] = useState(false)

    const [fileStats, setFileStats] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState()

    let remPositions = []
    var tradeList = [];
    var allDays = [];
    var tradeData = []

    const refresh = () => {

        //window.location.reload();

        // trades = tradeList
        // positions = remPositions
        // console.log(tradeList)
        // console.log(positions)
        setSelectedFile()
        setBroker()
        setDAS(false)
        setIsFilePicked(false)
        setUploaded(false)
        setUploadedT(false)
        setUploadedP(false)
        setSubmitted(false)
        setFileStats([])
        document.getElementById("brokerSelect").reset();
        //document.getElementById("fileSelect").reset();
    }

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
        //console.log(selectedFile)
    };

    const brokerSelect = (event) => {
        setBroker(event.target.value);
        //console.log(selectedFile)
    };

    const DASdateSelect = (event) =>{
        let dateLong = new Date(event.target.value+"\n")
        console.log(event.target.value)
        let dateSplit = dateLong.toLocaleDateString().split("/")
        dateSplit[2] = dateSplit[2].substring(2)
        setDASdate(dateSplit[0]+"/"+dateSplit[1]+"/"+dateSplit[2])
        console.log(dateSplit[0]+"/"+dateSplit[1]+"/"+dateSplit[2])
    }

    /// submit button logic
    let submitDisabled = true
    // useEffect(()=>{
        if (userData[0]!="SniperLog Demo") {
            switch (broker) {
                case "ThinkOrSwim":
                    if (selectedFile != undefined){
                        submitDisabled = false
                    }
                    break;
                case "DAS":
                    if ((selectedFile != undefined)&&(dasDate!=undefined)){
                        submitDisabled = false
                    }
                    break;
                default:
                    break;
            }
        }
    // },[broker, selectedFile])


    let brokerGuide = "";
    let dasDateForm = "";
    switch  (broker){
        case "ThinkOrSwim":
            brokerGuide = (<><li>Upload the file titled <i>"YYYY-MM-DD-AccountStatment.csv"</i> from ThinkOrSwim</li>
            <div className="imgContainerR"><img id="accountStatement" src={require('./assets/accountStatement.png')} /></div></>);
            break;
        case "DAS":
            brokerGuide = (<>
            <li>Upload the file titled <i>".csv"</i> from DAS Trader Pro</li>
            <div className="imgContainerR"><img id="accountStatement" src={require('./assets/accountStatement.png')} /></div>
            </>);
            //setDAS(true)
            dasDateForm = (
                <label id="dasDate">Date for trade import: 
                    <input type="date" name="broker" onChange={DASdateSelect} />
                </label>
            )
            break;
        default:
            break;
    }


    

    const handleSubmission = async (e) => {
        e.preventDefault();
        // console.log(selectedFile.data)
        if(!selectedFile){
            return;
        }

        setSubmitted(true)

        Papa.parse(selectedFile, {
            header: false,
            skipEmptyLines: true,
            skipLines: 0,
            complete: (results )=> {
                console.log("results length: "+ results.data.length)

                ///////////////// call broker specific here? //////////////////
                //let parsedTradeData = []
                let parsedInfo = []
                switch (broker){
                    case "ThinkOrSwim":
                        parsedInfo = tosParse(results, positions)
                        break;
                    case "DAS":
                        parsedInfo = dasParse(results, positions, dasDate)
                        break;
                    default:
                        break;
                }
                tradeData = parsedInfo[0]
                positions = parsedInfo[1]

                ////////////////////////////////////////////////   return broker specific here?       /////////////////////////////////////////////////////////////////////

                console.log(tradeData.length + " executions parsed");
                //console.log(tradeData)
                console.log(trades.length + " previous trades loaded")

                for (var i = 0; i<trades.length; i++){    /// copy old trades to new array
                    tradeList.push(trades[i]);
                }

                //////////// look for closing trades in execution list and push to trade list
                let newTradeCount = 0;
                for (var i = 0; i<tradeData.length; i++){
                    if (tradeData[i].cumlSize == 0){
                        let exists = false;
                        for (let n = 0; n<tradeList.length && exists==false; n++){
                            if (tradeData[i].REF==tradeList[n].REF){
                                exists = true;
                                break;
                            }
                        }
                        if (exists==false){
                            tradeList.push(tradeData[i]);
                            console.log(tradeData[i])
                            newTradeCount++;
                        }
                    }
                }
                //console.log(tradeList)
                console.log(newTradeCount + " trades added")

                ////// add individual trade PnL & Outcome  ///////////
                for (var i = 0; i<tradeList.length; i++) {
                    //console.log(tradesList[i])

                    if (i>0){
                        var found = false;
                        for (var n = i-1; (n>=0)&&(found==false); n--){
                            if (tradeList[n].SYMBOL==tradeList[i].SYMBOL && tradeList[n].DATE==tradeList[i].DATE){
                                tradeList[i].PnL = tradeList[i].runPnL - tradeList[n].runPnL;
                                //tradeList[i].tradeSize = (tradeList[i].cumlSize - tradeList[n].cumlSize)/2;
                                found=true;
                            }
                            else if (tradeList[n].SYMBOL!=tradeList[i].SYMBOL && n==0){
                                tradeList[i].PnL = tradeList[i].runPnL;
                                //tradeList[i].tradeSize = tradeList[i].cumlSize/2;
                                found=true;
                            }
                            else{ 
                                tradeList[i].PnL = tradeList[i].runPnL;
                                //tradeList[i].tradeSize = tradeList[i].cumlSize/2;
                            }
                        }
                    }
                    else {
                        tradeList[i].PnL = tradeList[i].runPnL;
                        //tradeList[i].tradeSize = tradeList[i].cumlSize/2;
                    }
                    
                    tradeList[i].PnL = Math.round((tradeList[i].PnL + Number.EPSILON) * 100) / 100;
                    tradeList[i].Win = (tradeList[i].PnL>0); /// add trade outcome
                    //update day's PnL
                    if (i>0 && tradeList[i].DATE==tradeList[i-1].DATE){
                        tradeList[i].dayPnL = tradeList[i].PnL + tradeList[i-1].dayPnL; 
                    } else {
                        tradeList[i].dayPnL = tradeList[i].PnL;
                    }
                    tradeList[i].unixTime = (new Date(tradeList[i].DATE+" "+tradeList[i].TIME).getTime()/1);
                }

                //console.log(tradeList)
                
                //trades = tradeList;

                //let jsonTradeList = JSON.stringify(tradeList);
                fetch(serverURL+"tradeData", {
                    // mode: 'cors',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        },
                    body: JSON.stringify({
                        content: tradeList,
                        name: user
                    }),
                    //body: jsonTradeList,
                    //body: "",
                })
                .then((response) => response.json())
                // .then((response) => response)
                .then((data) => {
                    //console.log(data);
                    //trades = jsonTradeList;
                    setUploadedT(true)
                });

                ///// days function ////
                const key = "DATE";
                const uniqueDays = [...new Map(tradeList.map(item => [item[key], item])).values()].map(({ DATE }) => DATE); //returns list of unique days
                //console.log(uniqueDays)

                /// for loop for each day
                allDays = [];
                for (var i = 0; i<uniqueDays.length; i++){
                    ///// if day matches tally win/loss count, gain/loss, shares 
                    let dayTotals = {
                        date: uniqueDays[i],
                        winCount: 0,
                        lossCount: 0,
                        totalGain: 0,
                        totalLoss: 0,
                        sharesWin: 0,
                        sharesLoss: 0,
                        cumPnL: 0
                    };
            
                    //dayTotals.date = uniqueDays[i];
                    var parseDate = (dayTotals.date).split("/"); 
                    var dateForm = `20${parseDate[2]}/${parseDate[0]}/${parseDate[1]}`;
                    dayTotals.dateDate = new Date(dayTotals.date); //dayTotals.dateDate = new Date(parseDate); 
                    var dateDOWnum =  dayTotals.dateDate.getDay();
            
                    switch(dateDOWnum){
                        case 1:
                            dayTotals.dateDOW = "monday";
                            break;
                        case 2:
                            dayTotals.dateDOW = "tuesday";
                            break;
                        case 3:
                            dayTotals.dateDOW = "wednesday"; 
                            break;
                        case 4:
                            dayTotals.dateDOW = "thursday";  
                            break;
                        case 5:
                            dayTotals.dateDOW = "friday";
                            break;
                        default:
                    }
            
                    //dayTotals.dateDOW =  dayTotals.dateDate.getDay();
            
                    for(var n = 0; n<tradeList.length; n++){
                        if (tradeList[n].DATE == uniqueDays[i]){
                            if (tradeList[n].Win){
                                dayTotals.winCount++;
                                dayTotals.totalGain += tradeList[n].PnL;
                                dayTotals.sharesWin += tradeList[n].totalSize;
                            } else {
                                dayTotals.lossCount++;
                                dayTotals.totalLoss += tradeList[n].PnL;
                                dayTotals.sharesLoss += tradeList[n].totalSize;
                            }
                        }
                    }
                    
                    dayTotals.totalShares = dayTotals.sharesWin + dayTotals.sharesLoss;
                    dayTotals.PnL = Math.round(((dayTotals.totalGain + dayTotals.totalLoss) + Number.EPSILON) * 100) / 100;;
                    
                    if (i>0){
                        dayTotals.cumPnL = dayTotals.PnL + allDays[i-1].cumPnL;
                    } else {
                        dayTotals.cumPnL = dayTotals.PnL;
                    }
                    
                    dayTotals.winRate = (dayTotals.winCount/(dayTotals.winCount+dayTotals.lossCount)*100);
                    dayTotals.winRate = Math.round((dayTotals.winRate + Number.EPSILON) * 1) / 1;
                    dayTotals.avgWinner = Math.round((dayTotals.totalGain/dayTotals.winCount + Number.EPSILON) * 100) / 100;
                    dayTotals.avgLoser = Math.round((dayTotals.totalLoss/dayTotals.lossCount + Number.EPSILON) * 100) / 100;
                    dayTotals.profitFactor = dayTotals.avgWinner/dayTotals.avgLoser*-1;
                    dayTotals.profitFactor = Math.round((dayTotals.profitFactor + Number.EPSILON) * 100) / 100;
                    dayTotals.totalGain = Math.round((dayTotals.totalGain + Number.EPSILON) * 100) / 100;
                    dayTotals.totalLoss = Math.round((dayTotals.totalLoss + Number.EPSILON) * 100) / 100;

                    if (allDays.length<20){
                        var movAvg = null;
                    } else {
                        let fiveDaySum = 0;
                        for (let i = 1; i<=20; i++){
                            let pos = (allDays.length)-i;
                            // console.log(allDays[pos])
                            fiveDaySum += allDays[pos].PnL;
                            
                        }
                        var movAvg = fiveDaySum/20;
                    }
                    dayTotals.movAvg = movAvg;
            
                    //console.log(dayTotals);
                    allDays.push(dayTotals);
            
                }

                console.log("allDays length: " + allDays.length)
                fetch(serverURL+"allData", {
                    // mode: 'cors',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        },
                    body: JSON.stringify({
                        name: user,
                        content: allDays
                    }),
                    //body: allDays,
                })
                .then((response) => response.json())
                // .then((response) => response)
                .then((data) => {
                    //console.log(data);
                    setUploaded(true)
                    setFileStats([tradeList.length, newTradeCount])
                });

                //console.log(positions)
                // clear positions with cumlSize 0 - map to new array
                for (let i = 0; i < positions.length; i++){
                    if (positions[i].cumlSize != 0){
                        remPositions.push(positions[i]);
                    }
                }
                //console.log(remPositions)

                fetch(serverURL+"positionData", {
                    // mode: 'cors',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        },
                    body: JSON.stringify({
                        name: user,
                        content: remPositions
                    }),
                    //body: allDays,
                })
                .then((response) => response.json())
                // .then((response) => response)
                .then((data) => {
                    //console.log(data);
                    setUploadedP(true)
                });

                setTrades(tradeList)
            },
        });
          
    };

    return (
        <div id="uploadPage">

            <section>
                <h4>Upload your trades *.csv file</h4>
                <form id="brokerSelect">
                    <label id="broker">Select your broker: 
                        <select name="broker" onChange={brokerSelect}>
                            <option value=""></option>
                            <option value="ThinkOrSwim">ThinkOrSwim</option>
                            {/* <option value="DAS">DAS Trader Pro</option> */}
                        </select>
                    </label>
                    {dasDateForm}
                    {/* <label for="inputTag">
                        Select File */}
                    <input type="file"  name="file" id="fileInput" onChange={changeHandler} />
                    {/* </label> */}
                </form>

                {isFilePicked ? (
                    <div>
                        <p>Filename: {selectedFile.name}</p>
                        <p>Filetype: {selectedFile.type}</p>
                        <p>Size in bytes: {selectedFile.size}</p>
                    </div>
                ) : (
                    <p></p>
                )}  

                {(submitted == false) ? (
                    <></>
                ) : (
                    (isUploaded && isUploadedT && isUploadedP) ? (
                        <>
                            <p>File Uploaded - {fileStats[1]} trades added</p>
                            <button id="refreshButton" onClick={refresh}>Upload more</button>
                        </>
                    ) : (
                        <>
                            <p>Please wait as we process your trades...</p>
                            <Spinner />
                        </>
                    )
                )}

                <div>
                    <button disabled={submitDisabled || isUploadedT} className="btn btn-primary btn-sm" onClick={handleSubmission}>Submit</button>
                </div>
            </section>

            <section id="guidelines">
                <h5>Guidelines:</h5>
                <ul>
                    {brokerGuide}
                    <li>Options, Futures, Forex & Crypto not yet supported.</li>
                </ul>
            </section>

        </div>
    )
}


/////////////////////////////////////////////////////////////////////////////////////////////
function tosParse (results, positions) {

    let tradeData = []
    for (let i = 2; i < results.data.length; i++) { // Notice that i changed i to 2, so that we skip the line 0 and 1.
        if (results.data[i][2]=="TRD"){

            //console.log(positions)
            let description = results.data[i][4].split(" ");

            let optionSide = description[description.length-2]
            if ((optionSide != "CALL")&&(optionSide != "PUT")){
                    //console.log(description[3][0])

                let tQTY = "";
                let tPrice = "";
                let tSymbol = "";
                if (description[0] !== "KEY:") {
                    tQTY = description[1];
                    tQTY = tQTY.replace('+','');
                    tQTY = tQTY.replace(',','');
                    tQTY = Number(tQTY);
                    // console.log(tQTY)
                    tPrice = description[3];
                    tPrice = tPrice.replace('@','');
                    tPrice = Number(tPrice);
                    tSymbol = description[2]
                } else {
                    tQTY = description[4];
                    tQTY = tQTY.replace('+','');
                    tQTY = Number(tQTY);
                    tPrice = description[6];
                    tPrice = tPrice.replace('@','');
                    tPrice = Number(tPrice);
                    tSymbol = description[5]
                }

                var row = {
                    DATE: results.data[i][0],
                    TIME: results.data[i][1],
                    TYPE: results.data[i][2],
                    REF: results.data[i][3],
                    QTY: tQTY,
                    SYMBOL: tSymbol,
                    PRICE: tPrice,
                    FEE: Number(results.data[i][5]),
                    COMM: Number(results.data[i][6]),
                };

                row.tradeVal = row.PRICE * row.QTY * -1;
                //row.tradeVal = Math.round((row.tradeVal + Number.EPSILON) * 100) / 100;

                let rowSymbol = row.SYMBOL;

                //console.log(row)
                //console.log(positions)

                let symbolIndex = positions.findIndex((i) => i.symbol === rowSymbol);

                let loadedPosition = {};
                if (symbolIndex === -1){
                    loadedPosition = {
                        symbol: rowSymbol,
                        openPos: 0,
                        closedPos: 0,
                        avgCost: 0,
                        avgSell: 0,
                        cumlSize: 0,
                        entryTime: 0,
                        entryDate: 0,
                        totalFees: 0,
                        totalComms: 0,
                    }
                } else {
                    loadedPosition = positions[symbolIndex]
                }

                //console.log(loadedPosition)

                let openPos = loadedPosition.openPos;
                let closedPos = loadedPosition.closedPos;
                let avgCost = loadedPosition.avgCost;
                let avgSell = loadedPosition.avgSell;
                let cumlSize = loadedPosition.cumlSize;
                let entryTime = loadedPosition.entryTime;
                let entryDate = loadedPosition.entryDate;
                let totalFees = loadedPosition.totalFees;
                let totalComms = loadedPosition.totalComms;
                totalFees = ((totalFees==undefined)||(isNaN(totalFees))) ? 0 : totalFees
                totalComms = ((totalComms==undefined)||(isNaN(totalComms))) ? 0 : totalComms


                if (cumlSize >= 0){          //if previosusly long
                    if (row.QTY>0){             //if a buy
                        avgCost = ((row.tradeVal*-1)+(openPos*avgCost))/(openPos+row.QTY); //cost of total position / total size.
                        openPos += row.QTY;
                        entryTime = ((entryTime == 0) ? row.TIME : entryTime);
                        entryDate = ((entryDate == 0) ? row.DATE : entryDate);
                        totalFees += row.FEE;
                        totalComms += row.COMM;
                        //console.log(entryTime)
                        //      value of current row + value of current posistion / current size + new Size
                    } else {
                        //avgSell = 0; //
                        avgSell = ((row.tradeVal)+(closedPos*avgSell))/(closedPos+(row.QTY*-1));
                        entryTime = ((entryTime == 0) ? row.TIME : entryTime);
                        entryDate = ((entryDate == 0) ? row.DATE : entryDate);
                        closedPos -= row.QTY;
                        totalFees += row.FEE;
                        totalComms += row.COMM;
                    }
                } else if (cumlSize < 0) {   //if previously short
                    if (row.QTY<0){
                        avgSell = ((row.tradeVal)+(closedPos*avgSell))/(closedPos+(row.QTY*-1));
                        closedPos -= row.QTY;
                        totalFees += row.FEE;
                        totalComms += row.COMM;
                        //exitTime = (exitTime == 0 ? row.TIME : exitTime);
                    }
                    else {
                        avgCost = ((row.tradeVal*-1)+(openPos*avgCost))/(openPos+row.QTY);
                        openPos += row.QTY;
                        totalFees += row.FEE;
                        totalComms += row.COMM;
                        //entryTime = (entryTime == 0 ? row.TIME : entryTime);
                    }
                } else {                    // if previously flat
                    if (row.QTY>0){
                        avgCost = (row.tradeVal*-1)/row.QTY
                    }
                    else {
                    }
                }

                //cumlSize += row.QTY;
                cumlSize = openPos - closedPos;

                row.avgCost = avgCost;
                row.avgSell = avgSell;
                //row.avgCost = Math.round((row.avgCost + Number.EPSILON) * 1000) / 1000;
                // row.avgSell = Math.round((row.avgSell + Number.EPSILON) * 1000) / 1000;
                row.entryTime = entryTime;
                row.entryDate = entryDate;

                // openPos += row.QTY;

                //console.log(openPositions);

                row.totalSize = (openPos + closedPos)/2;
                row.entry = avgCost;
                row.entry = Math.round((row.entry + Number.EPSILON) * 1000) / 1000;
                row.exit = avgSell;
                row.exit = Math.round((row.exit + Number.EPSILON) * 1000) / 1000;
                row.totalComms = totalComms
                row.totalFees = totalFees

                if (cumlSize==0){
                    closedPos = 0;
                    openPos = 0;
                    avgSell = 0;
                    avgCost = 0;
                    totalComms = 0;
                    totalFees = 0;

                    //row.duration = 
                    let entryDateTime = new Date (entryDate + " " + entryTime)
                    let exitDateTime = new Date (row.DATE + " " + row.TIME);
                    row.duration = exitDateTime - entryDateTime;

                    entryTime = 0;
                    entryDate = 0;
                }
                //closedPos -= row.QTY;
                row.closedPos = closedPos;
                row.openPos = openPos;
                row.avgSell = avgSell;
                row.avgCost = avgCost;
                row.cumlSize = cumlSize;

                let index = positions.findIndex((i) => i.symbol === rowSymbol)

                // console.log(index)

                if (index == -1){
                    positions.push({
                        symbol: rowSymbol,
                        openPos: openPos,
                        closedPos: closedPos,
                        avgCost: avgCost,
                        avgSell: avgSell,
                        cumlSize: cumlSize,
                        entryTime: entryTime,
                        entryDate: entryDate,
                        totalComms: totalComms,
                        totalFees: totalFees,
                    })
                } else {
                    positions[index] = {
                        symbol: rowSymbol,
                        openPos: openPos,
                        closedPos: closedPos,
                        avgCost: avgCost,
                        avgSell: avgSell,
                        cumlSize: cumlSize,
                        entryTime: entryTime,
                        entryDate: entryDate,
                        totalComms: totalComms,
                        totalFees: totalFees,
                    };
                }
                //console.log(positions);

                //set running PnL by ticker
                row.runPnL = 0;
                var found = false;
                var n = tradeData.length;
                while (found == false){ //// look for previous trades on this symbol & same day//////
                    if (n == 0) {
                        let positionValue = (loadedPosition.avgSell*loadedPosition.closedPos)-(loadedPosition.avgCost*loadedPosition.openPos)
                        //positionValue = (loadedPosition.cumlSize >= 0 ? positionValue*-1 : positionValue)
                        row.runPnL = row.tradeVal+positionValue;
                        if (positionValue == 0){
                            row.sharesTraded = Math.abs(row.QTY);
                        } else {
                            row.sharesTraded = Math.abs(row.QTY)/2;
                        }

                        // cumlSize = row.cumlSize;
                        found = true;
                    }
                    else {
                        n--;
                        if (row.SYMBOL==tradeData[n].SYMBOL && row.DATE==tradeData[n].DATE){
                            row.runPnL = tradeData[n].runPnL + row.tradeVal;
                            //row.runPnL = Math.round((row.runPnL + Number.EPSILON) * 100) / 100
                            row.sharesTraded = (tradeData[n].sharesTraded + Math.abs(row.QTY))/2;
                            // row.cumlSize = cumlSize;
                            found = true; 
                        } else {}
                    }
                }
                /////push parsed row to tradeData list
                //console.log(row)
                tradeData.push(row);
            }
        }
    }
    return [tradeData, positions]

}

function dasParse (results, positions, importDate) {
    console.log("DAS")

    let tradeData = []
    let filtRows = []
    //console.log(results.data[0])

    let date = importDate;
    let timeREF = results.data[0].indexOf("Time")
    let qtyREF = results.data[0].indexOf("Qty")
    let priceREF = results.data[0].indexOf("Price")
    let symbREF = results.data[0].indexOf("Symbol")
    let REFREF = results.data[0].indexOf("Cloid")
    let fncREF = results.data[0].indexOf("ECNFee")
    let directionREF = results.data[0].indexOf("Side")
    let typeREF = results.data[0].indexOf("Type")

    // console.log("date" +  ": "+ importDate)
    // console.log("timeREF" + ": " + results.data[0].indexOf("Time"))
    // console.log("qtyREF" + ": "+ results.data[0].indexOf("Qty"))
    // console.log("priceREF" +  ": "+ results.data[0].indexOf("Price"))
    // console.log("symbREF" +  ": "+ results.data[0].indexOf("Symbol"))
    // console.log("REFREF" +  ": "+ results.data[0].indexOf("Cloid"))
    // console.log("fncREF" + ": "+  results.data[0].indexOf("ECNFee"))
    // console.log("directionREF" +  ": "+ results.data[0].indexOf("Side"))
    // console.log("typeREF" +  ": "+ results.data[0].indexOf("Type"))

    for (let i = 1; i < results.data.length; i++) { // Notice that i changed i to 2, so that we skip the line 0 and 1.
        //console.log(typeof(results.data[i][0]))
        if (results.data[i][0]!=""){
 
            //console.log(results.data[i][0])
             
            // let description = results.data[i][4].split(" ");

            // let optionSide = description[description.length-2]
            // if ((optionSide != "CALL")&&(optionSide != "PUT")){

                // let tQTY = "";
                // let tPrice = "";
                // let tSymbol = "";

                    let tQTY = results.data[i][qtyREF];
                    //tQTY = tQTY.replace('+','');
                    tQTY = Number(tQTY);
                    tQTY = ((results.data[i][directionREF])=="S") ? tQTY*-1 : tQTY
                    let tPrice = results.data[i][priceREF];
                    //tPrice = tPrice.replace('@','');
                    tPrice = Number(tPrice);
                    //let tSymbol = results.data[i][symbREF]
                    let tFee = results.data[i][fncREF]
                    tFee = Number(tFee)

                   // let dateTime = results.data[i][14].split(" ")

                let row = {
                    DATE: date,
                    TIME: results.data[i][timeREF],
                    // TYPE: results.data[i][typeREF],
                    TYPE: "TRD",
                    REF: results.data[i][REFREF],
                    QTY: tQTY,
                    SYMBOL: results.data[i][symbREF],
                    PRICE: tPrice,
                    FEE: tFee,
                    COMM: 0,
                };

                row.tradeVal = row.PRICE * row.QTY * -1;

                //console.log(row)
                filtRows.push(row)
            // }
        }
    }

    // sort filtRows

    filtRows.sort((a,b)=>{

        let da = new Date(a.DATE + " " + a.TIME)
        let db = new Date(b.DATE + " " + b.TIME)

        return da - db
    })

    //console.log(filtRows)

    for (let i=0; i< filtRows.length; i++) { // Notice that i changed i to 2, so that we skip the line 0 and 1.
       //console.log(typeof(results.data[i][0]))

        //if (results.data[i][0]!=""){

            //console.log(results.data[i][0])
            
            // let description = results.data[i][4].split(" ");

            // let optionSide = description[description.length-2]
            // //if ((optionSide != "CALL")&&(optionSide != "PUT")){
            //         //console.log(description[3][0])

            //     let tQTY = "";
            //     let tPrice = "";
            //     let tSymbol = "";

            //         tQTY = results.data[i][12];
            //         //tQTY = tQTY.replace('+','');
            //         tQTY = Number(tQTY);
            //         tQTY = ((results.data[i][8])=="S") ? tQTY*-1 : tQTY
            //         tPrice = results.data[i][13];
            //         //tPrice = tPrice.replace('@','');
            //         tPrice = Number(tPrice);
            //         tSymbol = results.data[i][11]
        

            //     var row = {
            //         DATE: results.data[i][12][0],
            //         TIME: results.data[i][12][1],
            //         TYPE: results.data[i][10],
            //         REF: results.data[i][0],
            //         QTY: tQTY,
            //         SYMBOL: tSymbol,
            //         PRICE: tPrice,
            //         //FEE: Number(results.data[i][5]),
            //         //COMM: Number(results.data[i][6]),
            //     };

            //     row.tradeVal = row.PRICE * row.QTY * -1;
            //    //row.tradeVal = Math.round((row.tradeVal + Number.EPSILON) * 100) / 100;


            let row = filtRows[i]
            let rowSymbol = row.SYMBOL;

                //console.log(row)
                //console.log(positions)

            let symbolIndex = positions.findIndex((i) => i.symbol === rowSymbol);

            let loadedPosition = {};
            if (symbolIndex === -1){
                loadedPosition = {
                    symbol: rowSymbol,
                    openPos: 0,
                    closedPos: 0,
                    avgCost: 0,
                    avgSell: 0,
                    cumlSize: 0,
                    entryTime: 0,
                    entryDate: 0,
                    totalFees: 0,
                    totalComms: 0,
                }
            } else {
                loadedPosition = positions[symbolIndex]
            }

                //console.log(loadedPosition)

            let openPos = loadedPosition.openPos;
            let closedPos = loadedPosition.closedPos;
            let avgCost = loadedPosition.avgCost;
            let avgSell = loadedPosition.avgSell;
            let cumlSize = loadedPosition.cumlSize;
            let entryTime = loadedPosition.entryTime;
            let entryDate = loadedPosition.entryDate;
            let totalFees = loadedPosition.totalFees;
            let totalComms = loadedPosition.totalComms;
            totalFees = ((totalFees==undefined)||(isNaN(totalFees))) ? 0 : totalFees
            totalComms = ((totalComms==undefined)||(isNaN(totalComms))) ? 0 : totalComms

            if (cumlSize >= 0){          //if previosusly long
                if (row.QTY>0){             //if a buy
                    avgCost = ((row.tradeVal*-1)+(openPos*avgCost))/(openPos+row.QTY); //cost of total position / total size.
                    openPos += row.QTY;
                    entryTime = ((entryTime == 0) ? row.TIME : entryTime);
                    entryDate = ((entryDate == 0) ? row.DATE : entryDate);
                    totalFees += row.FEE;
                    totalComms += row.COMM;
                    //console.log(entryTime)
                    //      value of current row + value of current posistion / current size + new Size
                } else {
                    //avgSell = 0; //
                    avgSell = ((row.tradeVal)+(closedPos*avgSell))/(closedPos+(row.QTY*-1));
                    entryTime = ((entryTime == 0) ? row.TIME : entryTime);
                    entryDate = ((entryDate == 0) ? row.DATE : entryDate);
                    closedPos -= row.QTY;
                    totalFees += row.FEE;
                    totalComms += row.COMM;
                }
            } else if (cumlSize < 0) {   //if previously short
                if (row.QTY<0){
                    avgSell = ((row.tradeVal)+(closedPos*avgSell))/(closedPos+(row.QTY*-1));
                    closedPos -= row.QTY;
                    totalFees += row.FEE;
                    totalComms += row.COMM;
                    //exitTime = (exitTime == 0 ? row.TIME : exitTime);
                }
                else {
                    avgCost = ((row.tradeVal*-1)+(openPos*avgCost))/(openPos+row.QTY);
                    openPos += row.QTY;
                    totalFees += row.FEE;
                    totalComms += row.COMM;
                    //entryTime = (entryTime == 0 ? row.TIME : entryTime);
                }
            } else {                    // if previously flat
                if (row.QTY>0){
                    avgCost = (row.tradeVal*-1)/row.QTY
                }
                else {
                }
            }

                //cumlSize += row.QTY;
            cumlSize = openPos - closedPos;

            row.avgCost = avgCost;
            row.avgSell = avgSell;
            row.entryTime = entryTime;
            row.entryDate = entryDate;

            row.totalSize = (openPos + closedPos)/2;
            row.entry = avgCost;
            row.entry = Math.round((row.entry + Number.EPSILON) * 1000) / 1000;
            row.exit = avgSell;
            row.exit = Math.round((row.exit + Number.EPSILON) * 1000) / 1000;
            row.totalComms = totalComms
            row.totalFees = totalFees

            if (cumlSize==0){
                closedPos = 0;
                openPos = 0;
                avgSell = 0;
                avgCost = 0;
                totalComms = 0;
                totalFees = 0;

                //row.duration = 
                let entryDateTime = new Date (entryDate + " " + entryTime)
                let exitDateTime = new Date (row.DATE + " " + row.TIME);
                row.duration = exitDateTime - entryDateTime;

                entryTime = 0;
                entryDate = 0;
            }
            //closedPos -= row.QTY;
            row.closedPos = closedPos;
            row.openPos = openPos;
            row.avgSell = avgSell;
            row.avgCost = avgCost;
            row.cumlSize = cumlSize;

            //console.log(row)

            let index = positions.findIndex((i) => i.symbol === rowSymbol)

                // console.log(index)

                if (index == -1){
                    positions.push({
                        symbol: rowSymbol,
                        openPos: openPos,
                        closedPos: closedPos,
                        avgCost: avgCost,
                        avgSell: avgSell,
                        cumlSize: cumlSize,
                        entryTime: entryTime,
                        entryDate: entryDate,
                        totalComms: totalComms,
                        totalFees: totalFees,
                    })
                } else {
                    positions[index] = {
                        symbol: rowSymbol,
                        openPos: openPos,
                        closedPos: closedPos,
                        avgCost: avgCost,
                        avgSell: avgSell,
                        cumlSize: cumlSize,
                        entryTime: entryTime,
                        entryDate: entryDate,
                        totalComms: totalComms,
                        totalFees: totalFees,
                    };
                }
                //console.log(positions);

                //set running PnL by ticker
                row.runPnL = 0;
                var found = false;
                var n = tradeData.length;
                while (found == false){ //// look for previous trades on this symbol & same day//////
                    if (n == 0) {
                        let positionValue = (loadedPosition.avgSell*loadedPosition.closedPos)-(loadedPosition.avgCost*loadedPosition.openPos)
                        row.runPnL = row.tradeVal+positionValue;
                        if (positionValue == 0){
                            row.sharesTraded = Math.abs(row.QTY);
                        } else {
                            row.sharesTraded = Math.abs(row.QTY)/2;
                        }

                        // cumlSize = row.cumlSize;
                        found = true;
                    }
                    else {
                        n--;
                        if (row.SYMBOL==tradeData[n].SYMBOL && row.DATE==tradeData[n].DATE){
                            row.runPnL = tradeData[n].runPnL + row.tradeVal;
                            //row.runPnL = Math.round((row.runPnL + Number.EPSILON) * 100) / 100
                            row.sharesTraded = (tradeData[n].sharesTraded + Math.abs(row.QTY))/2;
                            // row.cumlSize = cumlSize;
                            found = true; 
                        } else {}
                    }
                }
                /////push parsed row to tradeData list
                //console.log(row)
                tradeData.push(row);
           // }
        //}
    }
    console.log(positions)
    //return [[],[]]
    return [tradeData, positions]
}