import React, { useContext, useRef, useState } from 'react';
import { useEffect } from 'react';
import serverURL from './config/serverConfig';
import Welcome from './components/Welcome';

import TradeCalendar from './components/TradeCalendar'
import TradeCalendarYearly from './components/TradeCalendarYearly';
import LifetimeChart from './components/LifetimeChart';
import LifetimeStats from './components/LifetimeStats';

import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { userContext } from './contexts/userContext';
import Spinner from './components/Spinner';

var allDays = [];
var trades = [];

export default function Lifetime (props){


    const userData = useContext(userContext);
    const user = userData[1];
    const [loadedDays, setLoadedDays] = useState(allDays);
    const [chartTitleStr, setChartTitleStr] = useState("All Time")
    const [loadedTrades, setLoadedTrades] = useState(trades)
    const [CalendarYearView, setCalendarYearView] = useState(false)
    const [clickedMonth, setClickedMonth] = useState(0);
    const [noTrades, setNoTrades] = useState();

    useEffect(()=>{
    fetch(serverURL + "json/" + user + "/allDays.json")
    .then(response => response.json())
    .then((jsonData) => {
        //console.log(jsonData)
        allDays = jsonData;
        allDays.sort((a,b) => Date.parse(a.date) - Date.parse(b.date));
        setLoadedDays(allDays);
        setClickedMonth(0)
        if (jsonData.length == 0){
            console.log("HELLO")
            setNoTrades(true)
        }
    })
    .catch((error) => {
        console.error("No trade data found - " + error)
        console.error(error)
    })
    },[])

    useEffect(()=>{
        fetch(serverURL + "json/" + user + "/trades.json")
        .then(response => response.json())
        .then((jsonData) => {
            //console.log(jsonData)
            trades = jsonData;
            setLoadedTrades(trades);
        })
        .catch((error) => {
            console.error("No trade data found - " + error)
            setNoTrades(true)
        })
    },[])

    const dateInputRef1 = useRef(null);
    const dateInputRef2 = useRef(null);

    const handleChange = (dateRange) => {

        let firstDay = dateRange[0];
        let lastDay = dateRange[1];
        firstDay.setHours(0,0,0,0);
        lastDay.setHours(0,0,0,0);
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
        };

        setChartTitleStr(dateRange[0].toLocaleDateString("en-US", options) + " to " + dateRange[1].toLocaleDateString("en-US", options))

        let filtDays = [];
        for (let i = 0; i<allDays.length; i++){
            let date = new Date(allDays[i].date); 
            if (date>=firstDay && date<=lastDay){
                filtDays.push(allDays[i]); //if date falls in range push to filtDays array
            }
        }

        let filtTrades = [];
        for (let i = 0; i<trades.length; i++){
            let date = new Date(trades[i].DATE);
            if (date>=firstDay && date<=lastDay){
                filtTrades.push(trades[i]); //if date falls in range push to filtDays array
            }
        }
        //console.log(filtDays)
        if (filtDays!=0){
            setLoadedDays(filtDays);
            setClickedMonth(0)
        } else {
            setLoadedDays(allDays);
            setClickedMonth(0)
        }

        if (filtTrades!=0){
            setLoadedTrades(filtTrades);
        } else {
            setLoadedTrades(trades);
        }
    }

    const reset = () => {
        setLoadedDays(allDays)
        setLoadedTrades(trades)
        setChartTitleStr("All Time")
    }

    const resetButton = (loadedDays == allDays) ? "" : <button id="resetButton" onClick={reset} className='btn btn-primary btn-sm'>Reset</button>;

    
    /////////////////*********** calculate stats ************/////////////
    //console.log(loadedDays)

    // calculate running PnL
    var lifetimeSum = 0;
    var totalGain = 0;
    var greenDayCount = 0;
    var greenDayGain = 0;
    var redDayCount = 0;
    var redDayLoss = 0;
    var streak = 0;
    var greenStreak = false;
    var avgWinRate = 0;
    var avgDailyPnL = 0;
    var avgGreenDay = 0;
    var avgRedDay = 0;
    var greenDayRatio = 0;
    var avgDailyVol = 0;
    

    for (let i = 0; i<loadedDays.length; i++){
        lifetimeSum += loadedDays[i].totalShares
        totalGain += loadedDays[i].PnL;
        loadedDays[i].totalGain = totalGain; //sets running PnL for filtered date range
        
        if (loadedDays[i].PnL>0){
            greenDayCount++;
            greenDayGain += loadedDays[i].PnL;
        } else {
            redDayCount++;
            redDayLoss += loadedDays[i].PnL;
        }

        if ((loadedDays[i].PnL>0)==greenStreak){
            streak++;
        } else {
            streak = 1;
            greenStreak = (loadedDays[i].PnL>0);
        }
    }

    // calculate daily volume
    avgDailyVol = lifetimeSum/loadedDays.length;
        avgDailyVol = Math.round((avgDailyVol + Number.EPSILON) * 1) / 1;
    
    // calculate average daily win rate
    var winRateSum = 0;
    for (let i = 0; i<loadedDays.length; i++){
        winRateSum += loadedDays[i].winRate
    }
    avgWinRate = winRateSum/loadedDays.length;
        avgWinRate = Math.round((avgWinRate + Number.EPSILON) * 100) / 100;

    // Calcaulte total P/L and average daily P/L
    avgDailyPnL = totalGain/loadedDays.length;
        avgDailyPnL = Math.round((avgDailyPnL + Number.EPSILON) * 100) / 100;
    totalGain = Math.round((totalGain + Number.EPSILON) * 100) / 100;

    // calculate average green day, red day, ratios
    avgGreenDay = greenDayGain/greenDayCount;
        avgGreenDay = Math.round((avgGreenDay + Number.EPSILON) * 100) / 100;
    avgRedDay = redDayLoss/redDayCount;
        avgRedDay = Math.round((avgRedDay + Number.EPSILON) * 100) / 100;
    greenDayRatio = greenDayCount/(greenDayCount+redDayCount);
        greenDayRatio = Math.round((greenDayRatio + Number.EPSILON) * 100);

    //calculate streak 

    /////////////////*********** JSX Return ************/////////////
    if (noTrades == true){
        return (
          <Welcome name={userData[0]}/>
        )
    } else {
        if (loadedDays.length==0){
            return (
                <>
                    <Spinner />
                </>
            )
        } else {
            return(
                <div>
                    <div className="row" id="topPage">
                        <div className="col-12 col-md-7 col-xl-8" id="left">
                            <div className="row" id="dayPnL">
                                <div className="col-12 chart chart-container pt-2" id="lifetimeChart">
                                <span id="rangePicker">
                                    <DateRangePicker size="xs" placeholder="Date Range Filter" cleanable={false}
                                        onOk={(dateRange, date2)=>handleChange(dateRange)}
                                    />
                                    {resetButton}
                                </span>
                                    <LifetimeChart allDays={loadedDays} chartTitle={chartTitleStr}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-xl-4 lifetimeStats pe-4" id="right">
                            <div className="row ps-2" id="statsHeader">
                                Statistics
                            </div>
                            <LifetimeStats loadedDays={loadedDays} loadedTrades={loadedTrades} />
                        </div>
                    </div>
                    <div className="row" id="bottomPage">
                        <div id="calButton">
                            <button className="btn btn-dark btn-sm" onClick={()=>{setCalendarYearView(!CalendarYearView)}}>
                                {CalendarYearView == false ? "Yearly View" : "Monthly View"}
                            </button>
                        </div>
                        {(CalendarYearView == false ? <TradeCalendar allDays={loadedDays} clickedMonth={clickedMonth} setMonth={setClickedMonth}/> : 
                        <div id="yearContainer">
                        <TradeCalendarYearly allDays={loadedDays} setView={setCalendarYearView} setMonth={setClickedMonth}/>
                        </div>)}
                    </div>
                </div>
            )
        }
    }
}
